import React, { useContext } from 'react'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './style.scss'

const NavTrigger = () => {

  const { t } = useTranslation()
  const { setDialogOpen, setPreviouslyFocused } = useContext(AppContext)

  const handleClick = () => {
    setDialogOpen('nav')
    setPreviouslyFocused('nav-trigger')
  }

  return (
    <button id="nav-trigger" className="NavTrigger" onClick={handleClick} data-testid="nav">
      <span className="sr-only">{t('menu')}</span>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </button>
  )
}

export default NavTrigger