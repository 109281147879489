import { useTranslation } from "react-i18next"

const RegisteredOwners = ({ owners, drivers, other = '' }) => {

  const { t } = useTranslation()
  const policyRegOwners = owners.filter(o => o !== '0')

  const getDriver = id => {
    const driver = drivers.find(d => d.driver_id === Number(id))
    return driver ? driver.first_name : ''
  }

  return (
    <li>
      <small>{t('Registered Owner(s)')}</small>
      {policyRegOwners.map(o => <p key={o}>{getDriver(o)}</p>)}
      {other && <p>{other}</p>}
    </li>
  )
}

export default RegisteredOwners