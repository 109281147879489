import React, { useContext, useEffect, useState } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import Tile from 'components/Tile'
import Select from 'forms/fields/Select'
import SlideToggle from 'forms/fields/SlideToggle'
import TypeAhead from 'forms/fields/TypeAhead'
import CompanyIcon from 'utils/icons/Company'
import { purchaseChoices } from 'utils/vars'
import { FormContext } from 'utils/context'
import { additionalInterests } from 'forms/utils/vars'
import { provinceChoices } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const LeasedOrFinanced = () => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)

  const isLeased = data['financing_type'] === 'leased'
  const isFinanced = data['financing_type'] === 'financed'
  
  const [showOtherCompany, setShowOtherCompany] = useState(false)

  const financingCallback = value => {
    if (value === 'neither') {
      clearAllErrors()
      setFieldValue('financing_company', null)
      setFieldValue('financing', false)
      setShowOtherCompany(false)
    } else {
      setFieldValue('financing', true)
    }
  }

  const strings = additionalInterests.map(i => i.Name)
  
  const matched = additionalInterests.find(a => a.Name === data['financing_company'])

  const setTypeAheadValue = value => {
    const match = additionalInterests.find(a => a.Name === value)
    setFieldValue('financing_company', value)
    setFieldValue('financing_address', match.Address)
    setFieldValue('financing_municipality', match.City)
    setFieldValue('financing_province', match.Province)
    setFieldValue('financing_postal_code', match.PostalCode)
  }

  const otherCompanyCallback = () => {
    if (showOtherCompany) {
      clearAllErrors()
      setShowOtherCompany(!showOtherCompany)
      setFieldValue('other_company', null)
      setFieldValue('company_street_number', null)
      setFieldValue('company_unit_number', null)
      setFieldValue('company_street_name', null)
      setFieldValue('company_city', null)
      setFieldValue('company_province', null)
      setFieldValue('company_postal_code', null)
      
    } else {
      clearAllErrors()
      setShowOtherCompany(!showOtherCompany)
      setFieldValue('financing_company', null)
    }
  }
  
  useEffect(() => {
    if (data['other_company']) {
      setShowOtherCompany(true)
    } 
    setFieldValue('has_other_company', showOtherCompany)
  }, [data['other_company'], showOtherCompany])  // eslint-disable-line


  return (
    <fieldset>
      <p className="fieldset-intro">{t("Is this vehicle leased or financed? If so, we'll need those details, including the mailing address.")}</p>
      <SlideToggle name="financing" Hidden />
      <Radio
        name="financing_type"
        choices={purchaseChoices}
        ChangeCallback={financingCallback}
      />
      {(isLeased || isFinanced) && (
        <div>
          {!showOtherCompany && (
          <TypeAhead
            name="financing_company"
            label={isLeased ? t('Leasing Company') : t('Financing Company')}
            stringList={strings}
            setTypeAheadValue={setTypeAheadValue}
          />
          )}
          <span style={{cursor: 'pointer', textDecorationLine: 'underline'}} 
          onClick={otherCompanyCallback}>
            {showOtherCompany? t('(Show search field)') : t("(Can't find what you're looking for?)")}
          </span>
          {showOtherCompany && (
            <div>
              <b>{t('Please enter the name and address of your lienholder below')}</b>
              <Text name="other_company" label={t('Other Company')}/>
              <Text name="company_street_number" label={t('Street number')}/>
              <Text name="company_unit_number" label={t('Unit number')} Optional/>
              <Text name="company_street_name" label={t('Street name')}/>
              <Text name="company_city" label={t('City')}/>
              <Select
                name="company_province"
                label={t('Province')}
                choices={provinceChoices}
              />
              <Text name="company_postal_code" label={t('Postal code')}/>
            </div>
          )}
          {matched && (
            <Tile Icon={CompanyIcon} IconName="CompanyIcon">
              <div className="TileHeader">
              <strong>
                {isLeased ? t('Leasing company address') : t('Financing company address')}
              </strong>
              </div>
              <div className="TileBody">
                <address>
                  {matched.Name}<br />
                  {matched.Address}<br />
                  {matched.City}, {matched.Province}<br />
                  {matched.PostalCode}
                </address>
              </div>
            </Tile>
          )}
        </div>
      )}
      
      
    </fieldset>
  ) 
}

export default LeasedOrFinanced