import { useContext } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { AppContext } from 'utils/context'

import './style.scss'

const FullScreenSpinner = () => {

  const { appLoading } = useContext(AppContext)

  return appLoading ? (
    <div className="FullScreenLoadingSpinner">
      <LoadingSpinner />
    </div>
  ) : null
}

export default FullScreenSpinner