import { useLocation } from 'react-router-dom'
import Tile from 'components/Tile'
import WarningIcon from 'utils/icons/Warning'
import { useTranslation } from 'react-i18next'
import './style.scss'

const RequestDisclaimer = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const show = () => {
    return pathname.includes('change-other') ||
           pathname.includes('address-change') ||
           pathname.includes('payment-date') ||
           pathname.includes('payment-details') ||
           pathname.includes('coverage-change') ||
           pathname.includes('policy-cancel') ||
           pathname.includes('policy-renew') ||
           pathname.includes('vehicle-add') ||
           pathname.includes('vehicle-replace') ||
           pathname.includes('vehicle-remove') ||
           pathname.includes('driver-add') ||
           pathname.includes('driver-remove')
  }

  return show() ? (
    <Tile Warning Icon={WarningIcon} IconName="WarningIcon" SmallIcon>
      <small className="TileHeader Disclaimer">
        {t('It is important to note, that any changes requested to your Policy are not effective until we send you confirmation through email.')}
      </small>
    </Tile>
  ) : null
}

export default RequestDisclaimer