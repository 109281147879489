import React, { useContext, useEffect } from 'react'
import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'

const Logout = () => {
  const { t } = useTranslation()
  const { authed, handleLogout } = useContext(AppContext)

  useEffect(() => {
    if (authed) {
      handleLogout()
    }
  }, []) // eslint-disable-line

  return (
    <div>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Logged out')}</h2>
          <p>
            <Trans
              i18nKey="sessionExpiredMsg"
              components={{ productName: <TenantStr ProductName /> }}
            />
          </p>
          <Link to="/" Pill>{t('Go Home')}</Link>
        </div>
      </div>
    </div>
  )
}

export default Logout
