import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { BrowserRouter } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { isStage, isLocal } from 'utils/helpers'
import App from './App'
import './i18n'

import 'utils/index.scss'

let LogRocketID = ''


if (isStage) {
  Sentry.init({
    dsn: 'https://ea9545e080cc40258c83a043d8961a96@sentry.io/1328940',
    environment: 'Stage',
  })
  LogRocketID = '0zhw2w/colossus-stage'
} else if (isLocal) {
  LogRocketID = null
} else {
  Sentry.init({
    dsn: 'https://f5e46db994034ad88d8bc8c6ad44992e@o204.ingest.sentry.io/1328939',
    environment: 'Production',
  })
  LogRocketID = '0zhw2w/colossus-prod'
}

if (LogRocketID) {
  LogRocket.init(LogRocketID)
  setupLogRocketReact(LogRocket)
}

ReactDOM.render(
  <React.Suspense fallback={LoadingSpinner}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.Suspense>,
  document.getElementById('root'),
)
