import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import { relationshipChoices } from 'forms/utils/vars'

const FrequentDriver = ({ driver }) => {
  let relation = ''
  if(driver.relationship_status !== 'other'){
    relation = relationshipChoices.find(relation => relation[0] === driver.relationship_status)[1]
  } else {
    relation = driver.relationship_status_other
  }
  return (
    <li className="padding-m1-bottom">
      <h5 className="zero-margin">{driver.driver_name}</h5>
      <small>{relation}</small>
      {driver.currently_insured && (
        <small>
          {driver.insurance_company}<br />
          {driver.policy_number}
        </small>
      )}
    </li>
  )
}

const OtherDriversConfirm = () => {
  const { t } = useTranslation()
  let form = useContext(FormContext)
  return (
    <>
      <ConfirmCard title={t("Other Frequent Drivers")} slug="other-drivers" id={form.data.id}>
        {form.data.other_drivers.length < 1 ? (
          <span>{t('No other frequent drivers')}</span>
        ) : (
          <ul className="CountList">
            {form.data.other_drivers.map((d, i) =>
              <FrequentDriver
                key={i}
                driver={d}
              />
            )}
          </ul>
        )}
      </ConfirmCard>
    </>
  )
}

export default OtherDriversConfirm