import { useContext } from 'react'
import LogoutModalBody from './LogoutModalBody'
import useModal from 'utils/useModal'
import useSession from 'utils/useSession'
import { AppContext } from 'utils/context'

const LogoutModal = () => {

  const { dialogOpen } = useContext(AppContext)
  const { cancelLogout, cancelLoading, setCancelLoading } = useSession()

  const { cls } = useModal('bottom', 'firstFocus')

  return dialogOpen === 'logout' ? (
    <div className={cls}>
      <div className="ModalInner container">
        <LogoutModalBody
          cancelLogout={cancelLogout}
          cancelLoading={cancelLoading}
          setCancelLoading={setCancelLoading}
        />
      </div>
    </div>
  ) : null
}

export default LogoutModal