import { useContext, useEffect } from 'react'
import AllPolicies from 'account/AllPolicies'
import AllBilling from 'account/AllBilling'
import GetAQuote from 'components/GetAQuote'
import Link from 'components/Buttons/Link'
import Messaging from 'account/Messaging'
import ErrorBoundary from 'utils/ErrorBoundary'
import Tile from 'components/Tile'
import Divider from 'components/Divider'
import PawIcon from 'utils/icons/Paw'
import { AppContext, AccountContext } from 'utils/context'
import { getTenantChatType } from 'utils/helpers'
import { useTranslation, Trans } from 'react-i18next'
import './style.scss'

const Dashboard = () => {
  
  const { t } = useTranslation()
  const { contact } = useContext(AccountContext)
  const { tenant } = useContext(AppContext)
  

  const unsupported = contact.data_status === 'good_unsupported' || contact.data_status === 'good_unsupported_missing'
  const bad = contact.data_status === 'bad'
  
  const isSmartCov = tenant && tenant.slug === 'smartcov'
  
  const billingDetailsEnabled = false
  
  useEffect(() => {
    if (!contact) return

    if (getTenantChatType(tenant) === 'olark') {
      if (document.getElementById('olark-set-crm')) document.getElementById('olark-set-crm').remove()
      const script = document.createElement('script')
      script.setAttribute('id', 'olark-set-crm')
      script.innerHTML = `
        olark('api.visitor.updateEmailAddress', {
          emailAddress: "${contact.email}"
        });
        olark('api.visitor.updateFullName', {
          fullName: "${contact.first_name} ${contact.last_name}"
        });
        olark('api.visitor.updatePhoneNumber', {
          phoneNumber: "${contact.home_phone}"
        });
        olark('api.visitor.updateCustomFields',{
          pivotalNumber: "${contact.contact_id}"
        });
        `
      document.body.appendChild(script)
    }
  }, [contact, tenant])

  return (
    <ErrorBoundary>
      <main id="main" className="Main">
        <div className="Dashboard">
          {billingDetailsEnabled && <div className="DashboardTop">
            <div className="container">
              <AllBilling />
              {unsupported && (
                <Messaging
                  msg={<span>
                    <Trans i18nKey="unsupportedOnlineMsg" components={{submitLink: <Link to="/account/requests" />}} />
                  </span>}
                />
              )}
              {bad && (
                <Messaging
                  msg={t('We are currently experiencing some issues with your policy details online. You may be viewing out of date information. We are actively fixing this issue, so please try logging in again within 24 hours.')}
                />
              )}
            </div>
          </div>}
          <div className="container">
            <GetAQuote />
            <AllPolicies />
            {isSmartCov && 
            <div>
              <Divider Small />
              <Tile Icon={PawIcon} IconName="PawIcon" LinkUrl={{pathname: "https://www.hubsmartcoverage.ca/other-insurance/"}} LinkTarget="_blank">
                <div className="TileHeader">
                  <Trans i18nKey="intertestInOtherProducts" components={{ s: <small className="uppercase subtle" />, p: <p className="secondary uppercase font-p2 zero-margin" /> }} />
                </div>
                <div className="TileBody">
                  {t('smartcov::Explore other SmartCoverage offers such as Pet Insurance, Life Insurance and other insurance needs.')}
                </div>
              </Tile>
            </div>}
          </div>
        </div>
      </main>
    </ErrorBoundary>
  )
}

export default Dashboard