import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Link from 'components/Buttons/Link'
import TenantUrl from 'components/TenantUrl'
import LoadingSpinner from 'components/LoadingSpinner'
import { postRequest } from 'utils/requests'
import { useTranslation, Trans } from 'react-i18next'
const Verify = () => {
  const { t } = useTranslation()
  const { token  } = useParams()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(null)

  const activate = useCallback(() => {
      postRequest('/api/users/activate/', { token })
        .then(res => {
          if (res.is_active) {
            setSuccess(true)
            setLoading(false)
          } else {
            setSuccess(false)
            setLoading(false)
          }
        })
        .catch(err => {
          setSuccess(false)
          setLoading(false)
        })
  }, [token])

  useEffect(() => {
    activate()
  }, [activate])

  return (
    <div>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Account Activation')}</h2>
          {loading ? (
            <div className="LoadingHolder">
              <LoadingSpinner />
            </div>
          ) : success ? (
            <div>
              <p>{t('Your account has been activated.')}</p>
              <Link to="/login" Pill>{t('Login Now')}</Link>
            </div>
          ) : (
            <div>
              <p><strong>{t('An error occurred while trying to activate your account.')}</strong></p>
              <p>
                <Trans
                  i18nKey="newActivationMsg"
                  components={{ activationLink: <Link to="/resend-activation"/> }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="continuedIssuesMsg"
                  components={{ phone: <TenantUrl Phone />, email: <TenantUrl Email />}}
                />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Verify
