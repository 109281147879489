import { useContext, useState, useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import Link from 'components/Buttons/Link'
import LogoutModal from 'components/LogoutModal'
import Dashboard from './Dashboard'
import PolicyDetail from './PolicyDetail'
import Profile from './Profile'
import Contact from './Contact'
import Claims from 'account/Claims'
import Help from './Help'
import Disclosures from './Disclosures'
import Omnichannel from './Omnichannel'
import MakeAPayment from './MakeAPayment'
import Impersonate from 'components/Impersonate'
import NoMatch from 'components/NoMatch'
import RequestsMain from 'requests/Main'
import { AppContext, AccountContext } from 'utils/context'
import useBilling from './utils/useBilling'
import useAccount from './utils/useAccount'
import useLineitems from 'utils/useLineitems'
import LoadErrorModal from 'components/LoadError/LoadErrorModal'
import useStatus from 'utils/useStatus'
import { useTranslation } from 'react-i18next'

const Main = ({ tenant }) => {
  
  const { t } = useTranslation()
  let match = useRouteMatch()

  const { setHasRequest, refreshLoading, handleLogout } = useContext(AppContext)
  
  const acctState = useAccount()
  const billingState = null //useBilling(acctState.policies) //remove null and first comment to enable billing
  const lineitemState = useLineitems(acctState.currentRequest, acctState.updateCurrentRequest, acctState.policies, setHasRequest)
  const { error } = useStatus()
  
  
  const [hasError, setHasError] = useState(false)

  const Loader = <div className="LoadingHolder"><LoadingSpinner /></div>
  
  useEffect(() => {
    if (error) {
      if (error.response.status >= 400) {
        setHasError(true)
        handleLogout()
      }
      else {
        setHasError(false)
      }
    }
   }, [error]) // eslint-disable-line

  return (
    <AccountContext.Provider value={{ ...acctState, ...billingState, ...lineitemState }}>
      {hasError ? <LoadErrorModal /> : (acctState.policiesLoading || lineitemState.lineitemLoading || refreshLoading) ? Loader : (
        <Switch>
          <Route exact path={match.url} component={Dashboard} />
          <Route path={`${match.url}/profile`} component={Profile} />
          <Route path={`${match.url}/help`} component={Help} />
          <Route path={`${match.url}/contact`} component={Contact} />
          <Route path={`${match.url}/claims`} component={Claims} />
          <Route path={`${match.url}/make-a-payment`} component={MakeAPayment} />
          <Route path={`${match.url}/requests`} component={RequestsMain} />
          <Route path={`${match.url}/disclosures`} component={Disclosures} />
          {
            tenant.show_formio_page && 
            <Route path={`${match.url}/letsconnect`} render={(props) => <Omnichannel {...props} dynamic={false} />} />
          }
          {
            tenant.show_formio_page && 
            <Route path={`${match.url}/form`} render={(props) => <Omnichannel {...props} dynamic={true} />} />
          }
          <Route path={`${match.url}/:policyNumber`} component={PolicyDetail} />
          <Route>
            <NoMatch>
              <Link to="/account" Pill Small Outline>
                {t('Return to dashboard')}
              </Link>
            </NoMatch>
          </Route>
        </Switch>
      )}
      <Impersonate />
      <LogoutModal />
    </AccountContext.Provider>
  )
}

export default Main
