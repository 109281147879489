import { useContext } from 'react'
import Tile from 'components/Tile'
import AutoIcon from 'utils/icons/Auto'
import TrashIcon from 'utils/icons/Trash'
import Button from 'components/Buttons/Button'
import { titleCase, formatNumber } from 'utils/helpers'
import { conditionChoices } from 'utils/vars'
import { AccountContext } from 'utils/context'

import './style.scss'

const QuoteVehicles = ({ items = [], onDelete }) => {
  
  const { drivers } = useContext(AccountContext)

  const kms = item => {
    if (item.vehicle_condition === 'new' || item.vehicle_condition === 'demo') {
      return `, ${formatNumber(item.kms_at_purchase, '0,0')}km`
    }
    return null
  }
  
  const getCondition = (condition) => {
    const key = conditionChoices.findIndex(innerArray => innerArray.includes(condition));
    return conditionChoices[key][1]
  }
  
  const driversToShow = (vehicle) =>{
    let driversToShow = []
    drivers.filter(d => vehicle.registered_owners.some(j => j === d.driver_id)).forEach((d, index)=> driversToShow.push(index ? ', ' : '', d.full_name))
    if(vehicle.registered_owners.includes(0)){
      driversToShow.push(driversToShow.length > 0 ? ", " : "", vehicle.registered_owner_other)
    }
    return driversToShow
  }
  
  return (
    <div id="array-items" className="QuoteVehicles" data-testid="quote-vehicles">
      {items.length > 0 ? items.map((x, i) =>
        <Tile key={i} className="QuoteVehicle" Icon={AutoIcon} IconName="AutoIcon">
          <div className="TileHeader">
            <small className="uppercase">{x.year} {x.make}</small>
            <p className="secondary-d2 font-p2">
              {x.model_label || x.model}<br />
              <small className="grey-l1">{titleCase(getCondition(x.vehicle_condition))}{kms(x)}</small> <br/>
              <small className="grey-l1">{driversToShow(x)}</small>
            </p>
          </div>
          <Button onClick={e => onDelete(e, x.id)} DataTestId="delete">
            <TrashIcon />
          </Button>
        </Tile>
      ) : null}
    </div>
  )
}

export default QuoteVehicles