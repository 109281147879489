import React from 'react'
import TextArea from 'forms/fields/TextArea'
import Policies from 'forms/fields/Policies'
import { useTranslation } from 'react-i18next'
const RequestDetails = () => {
  const { t } = useTranslation()
  return (
    <fieldset>
      <Policies
        name="requested_policies"
        label={t('Which of your policies are you inquiring about?')}
        AllowMultiple
      />
      <TextArea
        name="details"
        label={t('How can we help you today?')}
        MaxChars={1000}
      />
    </fieldset>
  )
}

export default RequestDetails