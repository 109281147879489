import i18n from 'i18n';

export const ONTiers = {
  tier_1: 'G1',
  tier_2: 'G2',
  tier_3: 'G',
}

export const ABTiers = {
  tier_1: i18n.t('Class 7'),
  tier_2: i18n.t('Class 5-GDL'),
  tier_3: i18n.t('Class 5'),
}

export const NBTiers = {
  tier_1: i18n.t('Class 7 Level 1'),
  tier_2: i18n.t('Class 7 Level 2'),
  tier_3: i18n.t('Class 5'),
}

export const NLTiers = {
  tier_1: i18n.t('Class 5 Level 1'),
  tier_2: i18n.t('Class 5 Level 2'),
  tier_3: i18n.t('Class 5'),
}

export const NSTiers = {
  tier_1: i18n.t('Class 7'),
  tier_2: i18n.t('Class 5N'),
  tier_3: i18n.t('Class 5'),
}

export const PETiers = {
  tier_1: i18n.t('Class 7 Stage 1(L)'),
  tier_2: i18n.t('Class 5G'),
  tier_3: i18n.t('Class G'),
}

export const provinceTiers = {
  'ON': ONTiers,
  'AB': ABTiers,
  'NB': NBTiers,
  'NL': NLTiers,
  'NS': NSTiers,
  'PE': PETiers,
}
