import { useEffect } from 'react'
import './style.scss'

const getUrlKey = () => {
  const validUrls = [
    // Eventually we can add more specific urls here
    '/account',
  ]
  let urlKey = 'oss-default'
  for (const url of validUrls) {
    if (window.location.pathname.includes(url)) {
      urlKey = 'oss-' + url.split('/').filter(Boolean).join('-')
      break
    }
  }
  return urlKey
}

const CXOWrapper = ({ tenant = null }) => {
  
  useEffect(() => {
    
    if (tenant && tenant.CXOChatID) {
      let urlKey = getUrlKey()
      console.log('urlKey', urlKey)
      
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.innerHTML = `(function (i, s, o, r, g, v, a, m) {g = v ? g + '?v=' + v : g; i['BrandEmbassy'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }; i[r].l = +new Date(); a = s.createElement(o); m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g + '?' + Math.round(Date.now() / 1000 / 3600); m.parentNode.insertBefore(a, m)}) (window, document, 'script', 'brandembassy', 'https://livechat-static-de-ca1.niceincontact.com/4/chat.js'); brandembassy('init', 1137, '${tenant.CXOChatID}'); brandembassy('setCustomCss', '[class^="WaitingQueueModal"] {display: none};'); brandembassy('setCaseCustomField', 'crm_url_location', '${urlKey}');`
      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
    
  }, []) // eslint-disable-line

  return null
}

export default CXOWrapper
