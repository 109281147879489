import React, { useState } from 'react'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import DatePicker from './DatePicker'
import MonthNav from './MonthNav'
import Label from 'forms/fields/Label'
import FieldError from 'forms/fields/FieldError'
import useField from 'forms/utils/useField'

import './style.scss'

const DateChooser = ({ name, label, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, HelpCopy, className, minDate, maxDate, IncludeYear }) => {
  const { value, onChange, onFocus, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)

  const [date, setDate] = useState(value ? parseISO(value) : new Date())
  const [displayDate, setDisplayDate] = useState(value ? parseISO(value) : new Date())

  const classes = classNames('field date-chooser', {
    [className]: className,
    'active': active,
    'touched': touched,
    'has-error': error,
  })

  const setDisplay = value => {
    setDisplayDate(value)
  }

  const setDateValue = value => {
    setDate(value)
    onChange({ target: { value: format(value, 'yyyy-MM-dd') } })
  }

  const getHelp = () => {
    if (typeof HelpCopy === 'function') {
      return <HelpCopy />
    } else {
      return <span className="help-copy">{HelpCopy}</span>
    }
  }

  return (
    <div id={`${name}-field`} className={classes} onFocus={onFocus} data-testid={name}>
      <Label label={label} name={name} />
      <div className="field-wrapper">
        <MonthNav
          displayDate={displayDate}
          setDisplayDate={setDisplay}
          IncludeYear={IncludeYear}
        />
        <DatePicker
          date={date}
          set={setDateValue}
          displayDate={displayDate}
          minDate={minDate}
          maxDate={maxDate}
        />
        {HelpCopy && getHelp()}
      </div>
      <FieldError error={error} name={name} />
    </div>
  )
}

export default DateChooser
