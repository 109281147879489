import MultiPageForm from 'forms/MultiPageForm'
import PolicyRenewalDetails from 'forms/fieldsets/policy/PolicyRenewalDetails'
import PolicyRenewalDiscount from 'forms/fieldsets/policy/PolicyRenewalDiscount'
import PolicyRenewalConfirm from 'forms/fieldsets/policy/PolicyRenewalConfirm'

const PolicyRenew = () => {

  return (
    <MultiPageForm>
      <PolicyRenewalDetails path="request-details"/>
      <PolicyRenewalDiscount path="discount-review" />
      <PolicyRenewalConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default PolicyRenew