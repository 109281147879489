import { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { prettyDate, idsToValues } from 'utils/helpers'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { policyCancelReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const PolicyCancellationConfirm = () => {
  const { t } = useTranslation()
  let { policies } = useContext(AccountContext)
  let { data } = useContext(FormContext)
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails hideRequester requester={data['requester']}  requesterOther={data['requester_other']} />
      <ConfirmCard title={t('Policies Date')} slug="get-started" id={data.id}>
        <ul>
          <li>
            <small>{t('Date you want to cancel the insurance?')}</small>
            {prettyDate(data['effective_date'])}
          </li>
        </ul>
      </ConfirmCard>
      <ConfirmCard title={t('Details')} slug="request-details" id={data.id}>
        <ul>
          <li>
            <small>{t('Which policy/policies are you cancelling?')}</small>
            {idsToValues(data['cancelling_policies'], policies, "policy_id", "policy_number")}
          </li>
          <li>
            <small>{t('Why are you cancelling your policy?')}</small>
            {policyCancelReasons.find(r => r[0] === data['reason_for_cancellation'])[1]}
          </li>
          <li>
            <small>{t('Have you already set up new insurance elsewhere?')} </small>
            {data['new_insurance'] ? t('Yes') : t('No')} 
          </li>
        </ul>
      </ConfirmCard>
      {
        data['reason_for_cancellation'] !== 'sold_vehicle_or_property' &&
        <ConfirmCard title={t('Quote')} slug="request-quote" id={data.id}>
          <ul>
            {
              data['reason_for_cancellation'] !== 'sold_vehicle_or_property' &&
              <li>
                <small>{t('Would you be open to receiving a quote to possibly save you money?')}</small>
                {data['quote_required'] ? t('Yes') : t('No')}
              </li>
            }
            {
              data['quote_required'] === false &&
              <li>
                <small>{t('I have read and understand the cancellation scripting and want my policy cancelled.')}</small>
                {data['cancellation_consent_accepted'] ? t('Yes') : t('No')}
              </li>
            }
            {
              data['cancellation_consent_accepted'] === true &&
              <li>
                <small>{t('Would you like us to contact you for a quote next year?')}</small>
                {data['quote_next_year'] ? t('Yes') : t('No')}
              </li>
            }
            
          </ul>
        </ConfirmCard>
      }
      
    </fieldset>
  )
}

export default PolicyCancellationConfirm