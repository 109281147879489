import Radio from 'forms/fields/Radio'
import AntiTheft from './AntiTheft'
import { useTranslation, Trans } from 'react-i18next'
const Eligibility = () => {

  const { t } = useTranslation()

  const Existing = <><Trans i18nKey="exitingQuestion" components={{ s: <strong /> }} /></>
  const Winter = <><Trans i18nKey="winterTireQuestion" components={{ s: <strong /> }} /></>
  const Carry = <><Trans i18nKey="carryQuestion" components={{ s: <strong /> }} /></>
  const Special = <><Trans i18nKey="specialQuestion" components={{ s: <strong /> }} /></>
  const Modified = <><Trans i18nKey="modifiedQuestion" components={{ s: <strong /> }} /></>
  const Rebuilt = <><Trans i18nKey="rebuiltQuestion" components={{ s: <strong /> }} /></>

  return (
    <fieldset>
      <p className="fieldset-intro">{t('Let us know a little more about how you will be using this vehicle')}</p>
      <AntiTheft name="eligibility"/>
      <Radio
        name="winter_tires"
        label={t("Do you have winter tires for this vehicle?")}
        HtmlLabel={Winter}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
      <Radio
        name="existing_damage"
        label={t("Is there any existing or unrepaired damage to the vehicle?")}
        HtmlLabel={Existing}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
      <Radio
        name="vehicle_modified"
        label={t("Has the vehicle been modified or customized (after market/not factory installed; i.e. wheelchair lift, ground effects, sound system, etc.)?")}
        HtmlLabel={Modified}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
      <Radio
        name="carry_passengers_for_compensation"
        label={t("Will the vehicle be used to carry passengers for compensation (e.g. taxi, ride sharing services such as Uber or Lyft)?")}
        HtmlLabel={Carry}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
      <Radio
        name="carry_special_use"
        label={t("Will there be any special use of the vehicle, such as carrying dangerous goods or explosives?")}
        HtmlLabel={Special}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
      <Radio
        name="rebuilt_salvaged"
        label={t("Has the vehicle been rebuilt or salvaged?")}
        HtmlLabel={Rebuilt}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
    </fieldset>
  )
}

export default Eligibility