import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RegisteredOwners from './RegisteredOwners'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const OwnerDriverConfirm = ({ QuoteOnly }) => {
  
  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  const { drivers } = useContext(AccountContext)

  const principalDriver = drivers.find(d => d.driver_id == data['principal_driver']) || {} // eslint-disable-line

  return (
    <ConfirmCard title={t("Owner and Driver")} slug="owner-and-driver" id={data.id}>
      <ul>
        {!QuoteOnly && <RegisteredOwners drivers={drivers} owners={data['registered_owners']} other={data['registered_owner_other']} />}
        <li>
          <small>{t('Principal Driver')}</small>
          {`${principalDriver.full_name}`}
        </li>
      </ul>
    </ConfirmCard>
  )
}

export default OwnerDriverConfirm