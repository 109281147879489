import { useState, useEffect, useContext } from 'react'
import * as Sentry from '@sentry/browser'
import { getAuthedRequest } from 'utils/requests'
import { AppContext } from 'utils/context'
import { addUserToLogRocket } from 'utils/helpers'

const useAccount = () => {

  const { handleLogout, setAccountPolicies, refreshLoading } = useContext(AppContext)

  const [driversLoading, setDriversLoading] = useState(true)
  const [vehiclesLoading, setVehiclesLoading] = useState(true)
  const [locationsLoading, setLocationsLoading] = useState(true)
  const [policiesLoading, setPoliciesLoading] = useState(true)

  const [currentRequest, setCurrentRequest] = useState({ requestType: null, lineitemUrl: null })

  const [contact, setContact] = useState({})
  const [policies, setPolicies] = useState([])
  const [drivers, setDrivers] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [coverages, setCoverages] = useState([])
  const [locations, setLocations] = useState([])

  useEffect(() => {
    if (contact && contact.email) addUserToLogRocket(contact)
    if (policies && policies.length > 0) {
      setAccountPolicies(policies.map(p => ({ number: p.policy_number, type: p.lob})))
    }
  }, [contact, policies, setAccountPolicies])

  useEffect(() => {
    if (!refreshLoading) {
      const urls = [
        ['contacts', setContact],
        ['policies', setPolicies, setPoliciesLoading],
        ['drivers', setDrivers, setDriversLoading],
        ['vehicles', setVehicles, setVehiclesLoading],
        ['locations', setLocations, setLocationsLoading],
        ['coverages', setCoverages],
      ]
  
      try {
        urls.forEach(u => {
          getAuthedRequest(`/api/${u[0]}/`, true)
            .then(res => {
              if (u[0] === 'contacts') {
                u[1](res[0])
                Sentry.setContext("Contact", {
                  uuid: res[0].contact_uuid,
                  user_id: res[0].owner,
                  province: res[0].province,
                })
              } else {
                u[1](res)
              }
              if (u[2]) {
                u[2](false)
              }
            })
            .catch(handleLogout)
        })
      } catch (e) {
        handleLogout()
      }
    }
  }, [refreshLoading]) // eslint-disable-line

  const updateCurrentRequest = request => {
    if (typeof request === 'string' || request === null) {
      throw new Error('You must pass an object including either requestType or lineitemUrl')
    }
    setCurrentRequest({
      requestType: request.requestType,
      lineitemUrl: request.lineitemUrl,
    })
  }

  return {
    contact,
    policies,
    drivers,
    vehicles,
    locations,
    coverages,
    driversLoading,
    vehiclesLoading,
    locationsLoading,
    policiesLoading,
    currentRequest,
    updateCurrentRequest,
  }
}

export default useAccount
