import React from 'react'
import classNames from 'classnames'
import LoadingSpinner from 'components/LoadingSpinner'

import './style.scss'

const Button = ({ id, children, type, onClick, className, Pill, Accent, Transparent, Small, Light, Loading, Icon, Disabled, Hidden, DataAttr, DataAttrValue, DataTestId}) => {

  const cls = classNames('Btn', {
    'Pill': Pill,
    'Accent': Accent,
    'Transparent': Transparent,
    'Small': Small,
    'Light': Light,
    'Disabled': Disabled,
    'Hidden': Hidden,
    [className]: className,
    'Loading': Loading,
    'Icon': Icon,
  })

  const disabledClick = e => e.preventDefault()

  let dataAttrs = {}

  if (DataAttr && DataAttrValue) {
    dataAttrs = {
      [`data-${DataAttr}`]: DataAttrValue
    }
  }
  if (DataTestId) {
    dataAttrs['data-testid'] = DataTestId
  }
  return (
    <button
      type={type}
      className={cls}
      onClick={Disabled || Loading ? disabledClick : onClick}
      id={id}
      {...dataAttrs}
    >
      {Loading ?
        <div className="LoadingHolder">
          <LoadingSpinner />
        </div> :
        children
      }
    </button>
  )
}

export default Button