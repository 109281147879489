import { Fragment, useContext } from 'react'
import TenantStr from 'components/TenantStr'
import TenantUrl from 'components/TenantUrl'
import BasePage from 'components/BasePage'
import TelephoneLink from 'components/TelephoneLink'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'

const Claims = () => {

  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)
  
  const customCarriersNames = {
    'smartcov': {
      'ZENI': t('Zenith and Northbridge Insurance'),
      'HAL': t('Intact Insurance, Nordic Insurance')
    },
    'ihs': {
      'HAL': t('Nordic Insurance')
    }
  }

  const excludeCarriers = {
    'cis': new Set(['WAWA']),
    'ihs': new Set(['ROY,WEST', 'GORE'])
  }

  const allCarriers = [
    {
      id: 'AVIVA,SR,TRG,TRG2,6494',
      name: t("Aviva Canada, Scottish and York, Traders, Elite Hagerty"),
      telephones: [
        {
          realNumber: "1-866-692-8482",
          displayNumber: "1-866-692-8482",
        }
      ]
    },
    {
      id: 'ECON',
      name: t("Economical Insurance"),
      telephones: [
        {
          realNumber: "1-800-607-2424",
          displayNumber: "1-800-607-2424",
        }
      ]
    },
    {
      id: 'PEM&PAF',
      name: t("Pembridge & Pafco Insurance"),
      telephones: [
        {
          realNumber: "1-800-387-0462",
          displayNumber: "1-800-387-0462",
        }
      ]
    },
    {
      id: 'ROY,WEST',
      name: t("Royal & Sun Alliance, Western Assurance"),
      telephones: [
        {
          realNumber: "1-800-319-9993",
          displayNumber: "1-800-319-9993",
        }
      ]
    },
    {
      id: 'TravelersCanada',
      name: t("Travelers Canada"),
      telephones: [
        {
          realNumber: "1-800-661-5522",
          displayNumber: "1-800-661-5522",
        }
      ]
    },
    {
      id: 'WAWA',
      name: t("Wawanesa Insurance"),
      telephones: [
        {
          realNumber: "1-844-929-2637",
          displayNumber: "1-844-929-2637",
        }
      ]
    },
    {
      id: 'ZENI',
      name: t("Zenith Insurance"),
      telephones: [
        {
          realNumber: "1-855-621-6262",
          displayNumber: "1-855-621-6262",
        }
      ]
    },
    {
      id: 'TravelersEssential',
      name: t("Travelers Essential"),
      telephones: [
        {
          realNumber: "1-800-268-5371",
          displayNumber: "1-800-268-5371",
        },
        {
          realNumber: "1-800-265-4408",
          displayNumber: "1-800-265-4408",
        }
      ]
    },
    {
      id: 'HAL, WU',
      name: t("Intact"),
      telephones: [
        {
          realNumber: "1-866-464-2424",
          displayNumber: "1-866-464-2424",
        }
      ]
    },
    {
      id: 'GORE',
      name: t("Gore Mutual Insurance"),
      telephones: [
        {
          realNumber: "1-844-974-4673",
          displayNumber: "1-844-974-GORE(4673)",
        }
      ]
    },
    {
      id: 'MAX',
      name: t("Max Insurance"),
      telephones: [
        {
          realNumber: "1-877-770-7729",
          displayNumber: "1-877-770-7729",
        }
      ]
    },
  ]

  return (
    <BasePage>
      <div className="SitePage container">
        <h2>{t('Need to make a claim?')}</h2>
        <p><TenantStr Hours /></p>
        <p>
          <Trans
            i18nKey="contactUs"
            components={{
              // eslint-disable-next-line
              a1: <a className = "Link" href={tenant.contact_us_url}/>
            }}
          />
        </p>
        <p>{t('Or email:')} <TenantUrl Email /></p>

        <p>
          <Trans 
            i18nKey="potentialClaimMessage" 
            components={{ tenantName: <TenantStr LegalName />}} 
          />
        </p>
        <p>{t('In the event of an after-hours emergency claim, please call the appropriate number below:')}</p>
        {
          allCarriers.map((carrier, i) => {
            if (
              tenant && 
              (!(tenant.slug in excludeCarriers) || !excludeCarriers[tenant.slug].has(carrier.id))
            ){
              return (
                <p key={i}>
                  <strong>
                    {
                      !(tenant.slug in customCarriersNames) || 
                      !(carrier.id in customCarriersNames[tenant.slug]) 
                      ? carrier.name : customCarriersNames[tenant.slug][carrier.id]}
                  </strong>
                  <br />
                  {
                    carrier.telephones.map((telephone, j) => {
                      return (
                        <Fragment key={j}>
                          {j > 0 ? " or " : ""}
                          <TelephoneLink number={telephone.realNumber} displayNumber={telephone.displayNumber}/>
                        </Fragment>
                      )
                    })
                  }
                </p>
              )
            } else {
              return null
            }
          })
        }
      </div>
    </BasePage>
  )
}

export default Claims
