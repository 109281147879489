import { useContext } from 'react'
import BasePage from 'components/BasePage'
import { AppContext } from 'utils/context'
import Tile from 'components/Tile'
import InfoIcon from 'utils/icons/Info'
import { useTranslation } from 'react-i18next'

const Disclosures = () => {
  
  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)
  
  return (
    <BasePage>      
      <div className="container">
        <h2>{t('Disclosures')}</h2>
        {tenant.disclaimer_link && 
        <Tile Icon={InfoIcon} IconName="InfoIcon" LinkUrl={{pathname: tenant.disclaimer_link}} LinkTarget="_blank">
          <div className="TileHeader">
            <p className="secondary uppercase font-p2 zero-margin">{tenant.disclaimer_label}</p>
          </div>
        </Tile>
        }
        {tenant.disclosure_link &&
        <Tile Icon={InfoIcon} IconName="InfoIcon" LinkUrl={{pathname: tenant.disclosure_link}} LinkTarget="_blank">
          <div className="TileHeader">
            <p className="secondary uppercase font-p2 zero-margin">{tenant.disclosure_label}</p>
          </div>
        </Tile>
        }
        {tenant.privacy_link &&
        <Tile Icon={InfoIcon} IconName="InfoIcon" LinkUrl={{pathname: tenant.privacy_link}} LinkTarget="_blank">
          <div className="TileHeader">
            <p className="secondary uppercase font-p2 zero-margin">{tenant.privacy_label}</p>
          </div>
        </Tile>
        }
        {tenant.tc_link && 
        <Tile Icon={InfoIcon} IconName="InfoIcon" LinkUrl={{pathname: tenant.tc_link}} LinkTarget="_blank">
          <div className="TileHeader">
            <p className="secondary uppercase font-p2 zero-margin">{tenant.tc_label}</p>
          </div>
        </Tile>
        }
        {tenant.conduct_link && 
        <Tile Icon={InfoIcon} IconName="InfoIcon" LinkUrl={{pathname: tenant.conduct_link}} LinkTarget="_blank">
          <div className="TileHeader">
            <p className="secondary uppercase font-p2 zero-margin">{tenant.conduct_label}</p>
          </div>
        </Tile>
        }
      </div>
    </BasePage>
  )
}

export default Disclosures