import React from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import DriverRemoveGetStarted from 'forms/fieldsets/driver/DriverRemoveGetStarted'
import RemoveReason from 'forms/fieldsets/driver/RemoveReason'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import DriverRemoveConfirm from 'forms/fieldsets/driver/DriverRemoveConfirm'
import { useTranslation } from 'react-i18next'
import './styles/driver-remove.scss'

const DriverRemove = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="driver-remove" />
      <DriverRemoveGetStarted path="get-started" />
      <RemoveReason path="remove-reason" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('The removal of a driver can also impact how you are using your vehicle. Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <PrincipalDrivers path="principal-drivers"
        helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}
      />
      <DriverRemoveConfirm path="confirm" />
    </MultiPageForm>
  )
  }

export default DriverRemove