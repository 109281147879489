import { useState, useEffect, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import api from 'utils/requests'
import { requestTypes } from 'requests/Main/vars'
import { AppContext } from 'utils/context'

const fetchAll = url => api.get(url).then(res => res.data)

const useLineitems = (currentRequest, updateCurrentRequest, policies, setHasRequest) => {

  const { push } = useHistory()

  const { refreshLoading } = useContext(AppContext)

  const [submitting, setSubmitting] = useState(false)
  const [lineitemProvince, setLineitemProvince] = useState(null)

  const { data: allLineitems, error } = useSWR(!refreshLoading ? '/lineitems/' : null, fetchAll, {
    revalidateOnFocus: false,
  })

  const activeLineitems = useMemo(() => {
    if (allLineitems) {
      return allLineitems.filter(li => li.status !== 'submitted')
    }
    return []
  }, [allLineitems])

  const addLineitem = async (li = {}) => {

    // blegh. we want to create the lineitem but we also cannot
    // proceed until we know that the lineitem has mutated. the double
    // "await" ensures that lineitem exists to be the initial form data 

    const response = await api.post(`/lineitems/${li.lineitemUrl}/`, {})
    await mutate('/lineitems/')
    return response.data
  }

  const updateLineitem = async (data, fs) => {
    const { lineitemUrl } = currentRequest
    
    const response = await api.put(`/lineitems/${lineitemUrl}/${data.id}/${fs}/`, data)
    await mutate('/lineitems/')

    return response.data
  }

  const clearCurrentLineitem = () => {
    mutate('/lineitems/')
    updateCurrentRequest({ requestType: null, lineitemUrl: null })
  }

  const deleteLineitem = id => {
    setSubmitting(true)
    push('/account/requests')
    api.delete(`/lineitems/${id}/`)
      .then(() => {
        mutate('/lineitems/')
        setHasRequest(false)
        clearCurrentLineitem()
        setSubmitting(false)
      })
  }

  const determineType = () => {
    if (activeLineitems && activeLineitems.length > 0) {
      const liType = activeLineitems[0]['type']
      
      let parentType = null
      let subType = null

      Object.keys(requestTypes).forEach(key => {
        Object.keys(requestTypes[key]).forEach(sub => {
          if (requestTypes[key][sub]['slug'] === liType) {
            parentType = key
            subType = sub
          }
        })
      })
      return { requestType: parentType, lineitemUrl: subType }
    }
    return null
  }

  const resumeLink = () => {
    const types = determineType()
    const li = activeLineitems[0]
    let fs = li ? li.current_fieldset : ''
    if (fs === 'completed') fs = 'confirm'
    return types ?
      `/account/requests/${types.requestType}/${types.lineitemUrl}/${li.id}/${fs}` :
      '/account/requests'
  }

  const allowedRequestTypes = () => {
    let whitelist = []
    policies.forEach(p => {
      p.lineitem_whitelist.forEach(x => whitelist = [...whitelist, x])
    })
    
    const filteredTypes = { question: {}, quote: {}, change: {} }

    Object.keys(requestTypes).forEach(rt => {
      // rt = 'question', 'quote' or 'change'
      Object.keys(requestTypes[rt]).forEach(x => {
        // x = 'general-request', 'billing-request' etc.
        if (whitelist.includes(requestTypes[rt][x].slug)) {
          filteredTypes[rt][x] = requestTypes[rt][x]
        }
      })
    })

    return filteredTypes
  }

  useEffect(() => {
    if (!lineitemProvince) {
      if (policies.length === 1) {
        setLineitemProvince(policies[0].province)
      } else if (policies.length > 1) {
        if (activeLineitems && activeLineitems.length === 1) {
          const currentPolicy = policies.find(p => Number(p.id) === Number(activeLineitems[0].policy))
          setLineitemProvince(currentPolicy ? currentPolicy.province : 'ON')
        }
      }
    }
  }, [activeLineitems, policies, lineitemProvince])

  useEffect(() => {
    const types = determineType()
    if (types) updateCurrentRequest(types)
  }, [activeLineitems]) // eslint-disable-line

  useEffect(() => {
    if (activeLineitems.length > 0) {
      setHasRequest(true)
    } else {
      setHasRequest(false)
    }
  }, [activeLineitems, setHasRequest]) // eslint-disable-line

  return {
    lineitem: activeLineitems && activeLineitems.length > 0 ? activeLineitems[0] : {},
    resumeLink,
    addLineitem,
    updateLineitem,
    deleteLineitem,
    lineitemLoading: !allLineitems && !error,
    clearCurrentLineitem,
    allowedRequestTypes,
    submitting,
    setSubmitting,
    lineitemProvince,
    setLineitemProvince,
  }
}

export default useLineitems