import { useContext } from 'react'
import { parseISO, isValid } from 'date-fns'
import Button from 'components/Buttons/Button'
import TriDate from 'forms/fields/TriDate'
import useModal from 'utils/useModal'
import { provinceTiers } from './vars'
import { AppContext, FormContext } from 'utils/context'
import PencilIcon from 'utils/icons/Pencil'
import { useTranslation } from 'react-i18next'
import { translateDate } from 'utils/helpers'

const SingleDate = ({ tier, value, province, licenceCancel, licenceClose, licenceChange }) => {

  const { t } = useTranslation()
  const { clearAllErrors } = useContext(FormContext)
  const { dialogOpen, setDialogOpen } = useContext(AppContext)
  const { cls } = useModal('bottom', 'firstFocus')

  const handleOpen = e => {
    e.preventDefault()
    setDialogOpen(tier)
  }

  const validDate = () => {
    const dateObj = parseISO(value)
    return isValid(dateObj)
  }

  const dateVal = validDate() ? translateDate(parseISO(value), 'MMM dd, yyyy') : null

  const tiers = provinceTiers[province]

  const handleCancel = () => {
    clearAllErrors()
    licenceCancel(tier)
    setDialogOpen('')
  }

  const handleClose = () => {
    licenceClose()
    setDialogOpen('')
  }

  return (
    <div>
      <Button className="LicenceButton" onClick={handleOpen} DataTestId={tier}>
        <span>{tiers[tier]}</span> {dateVal ? <strong>{dateVal}</strong> : t('Add Date')} <PencilIcon />
      </Button>
      {dialogOpen === tier ? (
        <div className={cls}>
          <div className="ModalInner container">
            <div className="LicenceDate">
              <TriDate id="firstFocus" name={tier} ChangeCallback={licenceChange} />
              <Button Pill onClick={handleCancel} DataTestId="cancel">{t('Cancel')}</Button> 
              <Button Pill onClick={handleClose} Disabled={!validDate()} DataTestId="done">{t('Done')}</Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default SingleDate