import React, { useContext, useState, useEffect } from 'react'
import Text from 'forms/fields/Text'
import AllDrivers from 'forms/fields/AllDrivers'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import PreferredContactFields from 'forms/fieldsets/general/PreferredContactFields'
import { useTranslation } from 'react-i18next'

const RequesterDetails = ({ email, excludeDriver, isQuote, requester, requesterOther, hideRequester }) => {
  const { t } = useTranslation()
  let { data, setFieldValue } = useContext(FormContext)
  let acct = useContext(AccountContext)

  let [isOther, setIsOther] = useState(false)


  useEffect(() => {
    if (requester) {
      if (requester === 'other') {
        setFieldValue('requester_name', 'other')
        setFieldValue('requester_name_other', requesterOther)
      } else {
        setFieldValue('requester_name', requester)
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    setIsOther(data['requester_name'] === 'other')
  }, [data])

  const requesterLabel = isQuote ?
    t('Who is requesting this quote?') :
    t('Who is requesting this change?')

  return (
    <>
      {
        (hideRequester !== true) &&
        <AllDrivers
          name="requester_name"
          label={requesterLabel}
          exclude={excludeDriver}
          Stacked
          other
        />
      }
      {
        (hideRequester !== true) && isOther &&
        <Text
          name="requester_name_other"
          label={t('Requester name')}
        />
      }
      
      <PreferredContactFields email={acct.contact.email} />
    </>
  )
}

export default RequesterDetails