import { useContext } from 'react'
import SlideToggle from 'forms/fields/SlideToggle'
import Policies from 'forms/fields/Policies'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const Delivery = () => {

  const { t } = useTranslation()
  let { contact } = useContext(AccountContext)
  
  return (
    <fieldset>
      <Policies
        name="requested_policies"
        label={t('Which of your policies are you inquiring about?')}
        AllowMultiple
      />
      <div className="ToggleGroup">
        <span className="main-label">{t('How would you like to receive your payment schedule?')}</span>
        <SlideToggle
          name="send_by_email"
          label={t('sendByEmail', { email: contact.email })}
          DefaultValue={true}
        />
        <SlideToggle
          name="send_by_mail"
          label={t('sendByMail', { postal: contact.postal_code })}
        />
      </div>
    </fieldset>
  )
}

export default Delivery