import MultiPageForm from 'forms/MultiPageForm'
import Date from 'forms/fieldsets/billing/Date'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'

const PaymentDate = () => (
  <MultiPageForm>
    <Date path="date" />
    <PreferredContact path="preferred-contact" />
  </MultiPageForm>
)

export default PaymentDate