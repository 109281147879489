import { useContext, useEffect } from 'react'
import { Switch, Route, useParams } from 'react-router-dom'
import GeneralChange from 'requests/GeneralChange'
import GeneralQuote from 'requests/GeneralQuote'
import VehicleAdd from 'requests/VehicleAdd'
import VehicleReplace from 'requests/VehicleReplace'
import VehicleRemove from 'requests/VehicleRemove'
import DriverAdd from 'requests/DriverAdd'
import DriverRemove from 'requests/DriverRemove'
import AddressChange from 'requests/AddressChange'
import VehicleAddQuote from 'requests/VehicleAddQuote'
import VehicleReplaceQuote from 'requests/VehicleReplaceQuote'
import General from 'requests/General'
import GeneralQuestion from 'requests/GeneralQuestion'
import Billing from 'requests/Billing'
import PaymentSchedule from 'requests/PaymentSchedule'
import PaymentDate from 'requests/PaymentDate'
import PaymentDetails from 'requests/PaymentDetails'
import CoverageChange from 'requests/CoverageChange'
import PolicyCancel from 'requests/PolicyCancel'
import PolicyRenew from 'requests/PolicyRenew'
import ReduceCoverage from 'requests/ReduceCoverage'
import { AccountContext } from 'utils/context'
import ReinstateCoverage from 'requests/ReinstateCoverage'

const FormWrapper = () => {

  const { updateCurrentRequest } = useContext(AccountContext)

  const { requestType, lineitemUrl } = useParams()

  const base = `/account/requests`
  const idfs = `:id/:fs`

  useEffect(() => {
    updateCurrentRequest({ requestType, lineitemUrl })
  }, []) // eslint-disable-line

  return (
    <Switch>
      {/* change */}
      <Route path={`${base}/change/vehicle-add/${idfs}`} component={VehicleAdd} />
      <Route path={`${base}/change/vehicle-replace/${idfs}`} component={VehicleReplace} />
      <Route path={`${base}/change/vehicle-remove/${idfs}`} component={VehicleRemove} />
      <Route path={`${base}/change/driver-add/${idfs}`} component={DriverAdd} />
      <Route path={`${base}/change/driver-remove/${idfs}`} component={DriverRemove} />
      <Route path={`${base}/change/address-change/${idfs}`} component={AddressChange} />
      <Route path={`${base}/change/coverage-change/${idfs}`} component={CoverageChange} />
      <Route path={`${base}/change/policy-cancel/${idfs}`} component={PolicyCancel} />
      <Route path={`${base}/change/payment-date/${idfs}`} component={PaymentDate} />
      <Route path={`${base}/change/payment-details/${idfs}`} component={PaymentDetails} />
      <Route path={`${base}/change/change-other/${idfs}`} component={GeneralChange} />
      <Route path={`${base}/change/reduce-coverage/${idfs}`} component={ReduceCoverage} />
      <Route path={`${base}/change/reinstate-coverage/${idfs}`} component={ReinstateCoverage} />
      {/* quote */}
      <Route path={`${base}/quote/vehicle-add-quote/${idfs}`} component={VehicleAddQuote} />
      <Route path={`${base}/quote/vehicle-replace-quote/${idfs}`} component={VehicleReplaceQuote} />
      <Route path={`${base}/quote/quote-other/${idfs}`} component={GeneralQuote} />
      {/* question */}
      <Route path={`${base}/question/general-request/${idfs}`} component={General} />
      <Route path={`${base}/question/question-other/${idfs}`} component={GeneralQuestion} />
      <Route path={`${base}/question/billing-request/${idfs}`} component={Billing} />
      <Route path={`${base}/question/policy-renew/${idfs}`} component={PolicyRenew} />
      <Route path={`${base}/question/payment-schedule/${idfs}`} component={PaymentSchedule} />
    </Switch>
  )
}

export default FormWrapper