import React from 'react'
// import { provinceChoices } from 'utils/vars'

const Address = ({ location, className }) => {

  const add1 = location.address_one ? <span>{location.address_one}<br /></span> : null
  const add2 = location.address_two ? <span>{location.address_two}<br /></span> : null
  
  const cityProvincePostal = () => {
    let city = location.city || ''

    if (city.includes(',')) {
      city = city.split(',')[0]
    }

    // let province = location.province ? provinceChoices.find(p => p[0] === location.province)[1] : location.province
    let province = location.province
    let postal = location.postal_code
    return city ? <span>{city}, {province} {postal}</span> : <span>{province} {postal}</span>
  }

  return (
    <address className={className || null}>
      {add1}
      {add2}
      {cityProvincePostal()}
    </address>
  )
}

export default Address