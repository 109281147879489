import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import ReinstateCoverageGetStarted from 'forms/fieldsets/vehicle/ReinstateCoverageGetStarted'
import ReinstateReason from 'forms/fieldsets/vehicle/ReinstateReason'
import VehicleUsage from 'forms/fieldsets/vehicle/VehicleUsage'
import CoverageNew from 'forms/fieldsets/vehicle/CoverageNew'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import CoverageReview from 'forms/fieldsets/vehicle/CoverageReview'
import { useTranslation } from 'react-i18next'
import React from 'react'
import ReinstateCoverageConfirm from 'forms/fieldsets/vehicle/ReinstateCoverageConfirm'


const ReinstateCoverage = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="reinstate-coverage" />
      <ReinstateCoverageGetStarted path="get-started"/>
      <ReinstateReason path="reinstate-reason"/>
      <VehicleUsage path="vehicle-usage" />
      <CoverageNew path="coverage" />
      <ChangedDetails path="policy-changes"
        helpCopy={t('Reinstating coverage on a vehicle from a policy may result in a change in how you use your remaining vehicle(s). Please confirm or update your vehicle usage below.')}/>
      <PrincipalDrivers path="principal-drivers"
        helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}/>
      <CoverageReview path="coverage-review"/>
      <ReinstateCoverageConfirm path="confirm"/>
    </MultiPageForm>
  )
}

export default ReinstateCoverage
