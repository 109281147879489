import React from 'react'
import Link from 'components/Buttons/Link'
import { useTranslation } from 'react-i18next'
const ResetSuccess = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Your Password Has Been Reset')}</h2>
          <p>{t('You can now login with your new password.')}</p>
          <p>
            <Link to="/login" Pill>{t('Login')}</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ResetSuccess