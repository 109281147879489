import axios from 'axios'
import 'whatwg-fetch'
import Cookies from 'js-cookie'
import i18next from 'i18next'

// utility fns

export const toJson = response => {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

export const checkStatus = response => {
  if (response.ok) {
    return Promise.resolve(response)
  }

  return response.json().then(json => {
    const err = response.statusText || `An error occurred at ${window.location.pathname}`
    const error = new Error(err)
    error.response = json
    return Promise.reject(error)
  })
}

export const wait = () => new Promise(resolve => setTimeout(resolve, 1000))

// standard wrapped calls
// All headers need to be modified so they are not forced french. Need to make dynamic to accept user selected language

export const getRequest = (url) => {
  const headers = new Headers({ 'Content-Type': 'application/json', 'Accept-Language': i18next.language })
  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(checkStatus)
    .then(toJson)
}

export const getAuthedRequest = (url) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'Accept-Language': i18next.language 
  })
  const csrf = Cookies.get('csrftoken')
  headers.append('X-CSRFToken', csrf)
  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(checkStatus)
    .then(toJson)
}


export const delRequest = (url) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'Accept-Language': i18next.language
  })
  const csrf = Cookies.get('csrftoken')
  headers.append('X-CSRFToken', csrf)
  return fetch(url, {
    method: 'DELETE',
    headers,
  })
    .then(checkStatus)
    .then(toJson)
}

export const postRequest = (url, data, controller = null) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'Accept-Language': i18next.language
  })
  const csrf = Cookies.get('csrftoken')
  headers.append('X-CSRFToken', csrf)
  const config = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
    mode: 'cors',
    credentials: 'same-origin',
  }
  if (controller) config.signal = controller.signal
  return fetch(url, config)
    .then(checkStatus)
    .then(toJson)
}

export const putRequest = (url, data) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'Accept-Language': i18next.language
  })
  const csrf = Cookies.get('csrftoken')
  headers.append('X-CSRFToken', csrf)
  return fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
    mode: 'cors',
    credentials: 'same-origin',
  })
    .then(checkStatus)
    .then(toJson)
}

// switching to axios for simplicity, SWR, etc.

const api = axios.create({
  baseURL: `/api/`,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
})

api.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = i18next.language;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api