import React from 'react'
import { format, addMonths, subMonths } from 'date-fns'
import { Next, Previous } from './navIcons'
import { translateDate } from 'utils/helpers'

const MonthNav = ({ displayDate, setDisplayDate, IncludeYear }) => {

  const prev = e => {
    e.preventDefault()
    setDisplayDate(subMonths(displayDate, 1))
  }
  const next = e => {
    e.preventDefault()
    setDisplayDate(addMonths(displayDate, 1))
  }
  return (
    <div className="MonthNav">
      <button className="Prev" onClick={prev} data-testid="previous">
        <Previous />
      </button>
      {translateDate(displayDate, 'MMMM') + ' '}
      {IncludeYear && format(displayDate, 'yyyy')}
      <button className="Next" onClick={next} data-testid="next">
        <Next />
      </button>
    </div>
  )
}

export default MonthNav
