import React, { useState, useEffect, useContext } from 'react'
import { AccountContext } from 'utils/context'
import LoadingSpinner from 'components/LoadingSpinner'
import { getAuthedRequest } from 'utils/requests'
import { compareDates } from 'utils/helpers'
import PreviousRequest from 'requests/PreviousRequest'
import { useTranslation } from 'react-i18next'
const Previous = () => {
  const { t } = useTranslation()
  let { drivers } = useContext(AccountContext)

  let [lineItems, setLineItems] = useState(null)
  let [requests, setRequests] = useState(null)

  useEffect(() => {
    getAuthedRequest('/api/lineitems/')
      .then(res => {
        if (res.length > 0) {
          let submitted = res.filter(r => r.status === 'submitted')
          setLineItems(submitted.length > 0 ? submitted : 'none')
        } else {
          setLineItems('none')
        }
      })

    getAuthedRequest('/api/requests/')
      .then(res => {
        if (res.length > 0) {
          setRequests(res)
        } else {
          setRequests('none')
        }
      })
      .catch(err => {
        console.log(err)
      })

  }, [])

  if (requests === null) {
    return (
      <div className="LoadingHolder">
        <LoadingSpinner />
      </div>
    )
  }

  const getRequesterName = (lineItem) => {
    let r = requests.find(req => req.id === lineItem.request)
    if(r['requester_name'] === "other"){
      return r.requester_name_other
    }
    let driver = drivers.find(d => String(d.driver_id) === r['requester_name'])
    return driver ? driver.full_name : null
  }

  return (
      <div>
        <h2>{t('Your previous requests')}</h2>
          {lineItems === 'none' || lineItems === null ? (
            <p>{t('No requests have been submitted yet')}</p>
          ) : (
            <div className="PreviousRequests">
              {lineItems.sort(compareDates).map(l =>
                <div key={l.id} data-testid="request">
                  <PreviousRequest lineItem={l} requesterName={getRequesterName(l)}/>
                </div>
              )}
            </div>
          )}
      </div>
  )
}

export default Previous