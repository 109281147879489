import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import OwnerDriverConfirm from 'forms/fieldsets/vehicle/OwnerDriverConfirm'
import UsageConfirm from 'forms/fieldsets/vehicle/UsageConfirm'
import EligibilityConfirm from 'forms/fieldsets/vehicle/EligibilityConfirm'
import CoverageConfirm from 'forms/fieldsets/vehicle/CoverageConfirm'
import VehicleInformationConfirm from 'forms/fieldsets/vehicle/VehicleInformationConfirm'
import FinancingConfirm from 'forms/fieldsets/vehicle/FinancingConfirm'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { provinceChoices } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const VehicleAddConfirm = props => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)

  const province = provinceChoices.find(ch => ch[0] === data['registered_province']) || ''

  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <div className="NewVehicle">
        <small>{t('Your New')}</small>
        <h4>
          <span>{data['year']} {data['make']} {data['model']}</span>
        </h4>
      </div>
      <VehicleInformationConfirm province={province[1]} />
      <OwnerDriverConfirm />
      <UsageConfirm />
      <EligibilityConfirm />
      <CoverageConfirm />
      <FinancingConfirm />
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default VehicleAddConfirm