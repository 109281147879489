import React, { useContext } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { FormContext } from 'utils/context'
import { driverRemoveReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const RemoveReason = () => {

  const { t } = useTranslation()
  let { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  let showOther = data['remove_reason'] === 'other'
  let showMovingTo = data['remove_reason'] === 'new_policy'

  const reasonChange = value => {
    if (value === 'other') {
      setFieldValue('moving_to_carrier', null)
      setFieldValue('moving_to_policy_number', null)
      clearAllErrors()
    }
    if (value === 'new_policy') {
      setFieldValue('remove_reason_other', null)
    }
    if (value === 'no_vehicle_access') {
      setFieldValue('moving_to_carrier', null)
      setFieldValue('moving_to_policy_number', null)
      setFieldValue('remove_reason_other', null)
    }
    if (value === 'no_licence') {
      setFieldValue('moving_to_carrier', null)
      setFieldValue('moving_to_policy_number', null)
      setFieldValue('remove_reason_other', null)
    }
    clearAllErrors()
  }

  return (
    <fieldset>
      <Radio
        name="remove_reason"
        label={t('Why are you removing this driver?')}
        choices={driverRemoveReasons}
        ChangeCallback={reasonChange}
      />
      {showOther && <Text name="remove_reason_other" label={t('Reason for Removing')} />}
      {showMovingTo && <>
        <Text
          name="moving_to_carrier"
          label={t("Insurance Carrier")}
        />
        <Text name="moving_to_policy_number" label={t('Policy Number')} />
      </>}
    </fieldset>
  )
}

export default RemoveReason