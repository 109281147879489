import React from 'react'
import { provinceTiers } from './vars'
import WarningIcon from 'utils/icons/Warning'
import { useTranslation } from 'react-i18next'

const TierErrors = ({ errors, province }) => {

  const { t } = useTranslation()
  
  // we receive multiple coded errors from the API for licences
  // in the future we can provide more, richer error messages
  // but for now we'll cycle through them and display the last one
  // typical error msg: "tier_2 must be after 2000-09-01"

  const tiers = provinceTiers[province]
  let error = ''
  let tier = ''

  Object.keys(errors).forEach(x => {
    if (errors[x].msg) {
      tier = x
      error = errors[x]
    }
  })
  
  const errorKeyTranslations = {
    missing_tier: t('You must provide a valid {{drivers_license_tier}} date', {drivers_license_tier: tiers[tier]}),
    before_min_age: t('{{drivers_license_tier}} must be after {{date}}', {drivers_license_tier: tiers[tier], date: error.err_date}),
    before_gdl: t('{{drivers_license_tier}} must be after {{date}}', {drivers_license_tier: tiers[tier], date: error.err_date}),
    before_min_gap: t('{{drivers_license_tier}} must be after {{date}}', {drivers_license_tier: tiers[tier], date: error.err_date}),
    after_max: t('{{drivers_license_tier}} must be after {{date}}', {drivers_license_tier: tiers[tier], date: error.err_date}),
    gdl_licence_for_non_gdl: t('Graduated licensing began in {{date}}, did you mean to provide only a {{drivers_license_tier}} date?', {date: error.err_date, drivers_license_tier: tiers['tier_3']}),
    date_in_future: t('{{drivers_license_tier}} is a future date - please provide only dates before today.', {drivers_license_tier: tiers[tier]}),
    no_licence_dates: t('No dates provided'),
    no_birth_date: t('We were unable to calculate your date of birth, please go back and verify your licence number'),
    dates_not_in_order: t('Dates provided must be consecutive')
  }

  return error ? (
    <div className="TierErrors" data-testid="tier-errors">
      <WarningIcon /> <span>{errorKeyTranslations[error.code]}</span>
    </div>
  ) : null
}

export default TierErrors