import React, { useContext } from 'react'
import Button from 'components/Buttons/Button'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const LogoutButton = () => {

  const { t } = useTranslation()
  const { handleLogout, setDialogOpen } = useContext(AppContext)
  const logout = () => {
    setDialogOpen('')
    handleLogout()
  }

  return (
    <Button onClick={logout} className="LogoutButton" DataTestId="logout">
      {t('Logout')}
    </Button>
  )
}

export default LogoutButton