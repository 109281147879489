import React, { useContext } from 'react'
import classNames from 'classnames'
import Button from 'components/Buttons/Button'
import { SupportContext } from 'utils/context'
import QuestionIcon from 'utils/icons/Question'
import { useTranslation } from 'react-i18next'

const Label = ({ label, HtmlLabel, name, htmlFor, secondaryLabel, className, DisabledChoice, children, Optional, DataTestId }) => {

  const { t } = useTranslation()
  const { snippets, setSupportWithField } = useContext(SupportContext)

  const hasSnippet = snippets ? snippets.find(f => {
    return f.slug === name
  }) : false

  const supportClick = e => {
    e.preventDefault()
    setSupportWithField(name)
  }

  const cls = classNames('secondary-label', {
    [className]: className,
    'DisabledChoice': DisabledChoice,
    'has-help': hasSnippet,
  })

  // Labels need to be updates to include htmlFor so it cannot also be based off name as this leads to code breaking in specific instances
  if (secondaryLabel) {
    return (
      <label className={cls} htmlFor={htmlFor || name} data-testid={DataTestId || name}>
        {children} {hasSnippet && <Button onClick={supportClick} Icon><QuestionIcon /></Button>}
      </label>
    )
  }

  if (label || HtmlLabel) {
    return (
      <label className={hasSnippet ? 'main-label has-help' : 'main-label'} htmlFor={name} data-testid={DataTestId || name}>
        {HtmlLabel || label} {hasSnippet && <Button onClick={supportClick} Icon><QuestionIcon /></Button>}
        {children}
        {Optional && <small className="Optional">{t('(Optional)')}</small>}
      </label>
    )
  }

  if (children) {
    return (
      <label className={hasSnippet ? 'main-label has-help' : 'main-label'} htmlFor={name} data-testid={DataTestId || name}>
        {children}
      </label>
    )
  }

  return <label htmlFor={name} className="sr-only">{name}</label>
}

export default Label