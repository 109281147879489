import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import GetStarted from 'forms/fieldsets/policy/PolicyCancelGetStarted'
import PolicyCancellationDetails from 'forms/fieldsets/policy/PolicyCancellationDetails'
import PolicyCancellationQuote from 'forms/fieldsets/policy/PolicyCancellationQuote'
import PolicyCancellationConfirm from 'forms/fieldsets/policy/PolicyCancellationConfirm'

const PolicyCancel = () => {

  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="policy-cancel" />
      <GetStarted path="get-started"/>
      <PolicyCancellationDetails path="request-details"/>
      <PolicyCancellationQuote path="request-quote" />
      <PolicyCancellationConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default PolicyCancel