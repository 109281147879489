import React, { useContext } from 'react'
import { subYears } from 'date-fns'
import Text from 'forms/fields/Text'
import Select from 'forms/fields/Select'
import Radio from 'forms/fields/Radio'
import TriDate from 'forms/fields/TriDate'
import PolicyVehicles from 'forms/fields/PolicyVehicles'
import { genderChoices, relationshipChoices, maritalChoices, authChoices, driverTypeChoices } from 'forms/utils/vars'
import { FormContext } from 'utils/context'
import { albertaLicence } from 'forms/utils/cleans'
import { titleCase } from 'utils/helpers'
import { useTranslation, Trans } from 'react-i18next'

const AbInformation = ({vehicles}) => {
  
  let { data, setFieldValue, clearFieldError, clearAllErrors } = useContext(FormContext)
  const { t } = useTranslation()

  const driverAuthority = data['first_name'] ? <Trans i18nKey="driverAuthority" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="generalAuthority" />
  const driverPrimaryVehicle = data['first_name'] ? <Trans i18nKey="driverPrimaryVehicle" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="generalPrimaryVehicle" />
  const howDoYouKnow = data['first_name'] ? <Trans i18nKey="howDoYouKnow" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="howDoYouKnowGeneral" />
  const outOfProvinceHistory = data['first_name'] ? <Trans i18nKey="outOfProvinceHistory" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="outOfProvinceHistoryGeneral" />

  const setMarital = value => {
    if (value === 'spouse') {
      setFieldValue('marital_status', 'm')
    } else {
      setFieldValue('marital_status', null)
    }
    if (value !== 'other') {
      setFieldValue('relationship_status_other', null)
      clearFieldError('relationship_status_other')
    }
  }

  const onLicenceChange = () => {
    clearAllErrors()
  }
  
  return (
    <fieldset>
      <Text name="first_name" label={t('First Name')} />
      <Text name="last_name" label={t('Last Name')} />
      <Select
        name="relationship_status"
        label={howDoYouKnow}
        choices={relationshipChoices}
        ChangeCallback={setMarital}
      />
      {data['relationship_status'] === 'other' && (
        <Text
          name="relationship_status_other"
          label={t('What is the relationship between you and the driver?')}
        />
      )}
      <Radio
        name="out_of_province_history"
        label={outOfProvinceHistory}
        choices={[[false, t('No')], [true, t('Yes')]]}
      />
      <Text
        name="drivers_licence_number"
        label={t('Licence Number')}
        cleans={[albertaLicence]}
        ChangeCallback={onLicenceChange}
      />
      <TriDate
        name="date_of_birth"
        label={t('Date of Birth')}
        minDate={subYears(new Date(), 100)}
        maxDate={subYears(new Date(), 14)}
      />
      <Select
        name="gender"
        label={t("What gender is listed on their Driver's Licence?")}
        choices={genderChoices}
      />
      <Select name="marital_status" label="Marital Status" choices={maritalChoices} />
      <Radio
        name="authorization_status"
        label={driverAuthority}
        choices={authChoices}
      />
      <PolicyVehicles 
        name="vehicle_assignment"
        label={driverPrimaryVehicle}
        HelpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}
      />
      {vehicles.length === 1 && 
        <Radio
          label={t('Will they be primary or secondary driver of this vehicle?')}
          name="driver_type"
          choices={driverTypeChoices}
        />
      }
      <Text Optional name="additional_information" label={t('Are there any additional details you would like to provide regarding the use of your vehicles?')} />
    </fieldset>
  )
}

export default AbInformation