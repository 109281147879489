import { useEffect, useContext, useState } from 'react'
import { AccountContext, FormContext } from 'utils/context'
import Text from 'forms/fields/Text'
import SlideToggle from 'forms/fields/SlideToggle'
import TextArea from 'forms/fields/TextArea'
import MultiCheckbox from 'forms/fields/MultiCheckbox'
import { useTranslation } from 'react-i18next'

const RenewalDiscount = () => {
  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)

  let { policies, vehicles } = useContext(AccountContext)

  const [showAutoDiscounts, setShowAutoDiscounts] = useState(false)
  const [showHabDiscounts, setShowHabDiscounts] = useState(false)
  const [winterTiresVehiclesChoices, setWinterTiresVehiclesChoices] = useState([])

  useEffect(() => {
    let updatedShowAutoDiscounts = showAutoDiscounts
    let updatedShowHabDiscounts = showHabDiscounts
    const availableVehiclesChoices = [];

    for (let i = 0; i < data['renewing_policies'].length; i++) {
      const policyId = data['renewing_policies'][i]
      const policy = policies.find(policy => policyId === policy.policy_id)
  
      if (policy.lob === "auto") {
        updatedShowAutoDiscounts = true
        vehicles.forEach((vehicle) => {
          if (vehicle.policy === policy.id) {
            availableVehiclesChoices.push([vehicle.vehicle_id, vehicle.full_vehicle_name])
          }
        })
      } else if (policy.lob === "hab") {
        updatedShowHabDiscounts = true
      }
  
      if (updatedShowAutoDiscounts && updatedShowHabDiscounts) break
    }

    if (updatedShowAutoDiscounts === false) {
      setFieldValue('download_app', null)
      setFieldValue('winter_tires', null)
      setFieldValue('retiree', null)
      setFieldValue('out_of_town_student', null)
      
    }
  
    if (updatedShowHabDiscounts === false) {
      setFieldValue('non_smoker', null)
      setFieldValue('water_damage_prevention', null)
      setFieldValue('monitored_alarm', null)
      setFieldValue('mortgage_free', null)
    }

    setShowAutoDiscounts(updatedShowAutoDiscounts)
    setShowHabDiscounts(updatedShowHabDiscounts)
    setWinterTiresVehiclesChoices(availableVehiclesChoices)

  }, []) // eslint-disable-line

  const lifestyleChangesCallback = value => {
    if (value === false) {
      setFieldValue('lifestyle_changes_explanation', null)
      clearFieldError('lifestyle_changes_explanation')
    }
  }

  const winterTiresChangesCallback = value => {
    if (value === false) {
      setFieldValue('winter_tires_vehicles', null)
      clearFieldError('winter_tires_vehicles')
    }
  }

  const outOfTownStudentCallback = value => {
    if (value === false) {
      setFieldValue('out_of_town_student_school', null)
      clearFieldError('out_of_town_student_school')
      setFieldValue('out_of_town_student_address', null)
      clearFieldError('out_of_town_student_address')
    }
  }

  return (
    <fieldset>
      <h3>{t('Please select all that apply to you.')}</h3>
      
      <SlideToggle
        name="combine_policies"
        label={t('Yes')}
        MainLabel={t('If applicable, are you and your spouse interested in combining your auto and property policies with the same insurance company? Combining policies could save you up to an additional 20% off of each policy!')}
      />
      <SlideToggle
        name="lifestyle_changes"
        label={t('Yes')}
        MainLabel={t('Have you moved, bought a new car, got married, reduced your driving habits or done anything else that may have an impact on your monthly payments?')}
        ChangeCallback={lifestyleChangesCallback}
      />

      {
        data['lifestyle_changes'] === true &&
        <TextArea
          name="lifestyle_changes_explanation"
          label={t('Please explain.')}
          HelpCopy={t('If applicable, please submit the request with the corresponding form under Make a Change.')}
        />
      }

      {
        showAutoDiscounts &&
        <>
          <SlideToggle
            name="download_app"
            label={t('Yes')}
            MainLabel={t('Would you be willing to download an app that rewards good driving behaviours for savings of up to 30%?')}
          />
          <SlideToggle
            name="winter_tires"
            label={t('Yes')}
            MainLabel={t('Do you have winter tires for your vehicle(s)?')}
            ChangeCallback={winterTiresChangesCallback}
            HelpCopy={
              data["winter_tires"] 
              ? t("Winter Tire (not available in all provinces) If you purchased winter tires and they are equipped from Nov. 1st until Mar. 31st of every year, you'll earn up to an additional 5% off! If for whatever reason you don't put them on one year, please let us know.")
              : ""
            }
          />
        </>
      }
      {
        data['winter_tires'] === true &&
        <MultiCheckbox
          name="winter_tires_vehicles"
          label={t('Select the vehicles that have winter tires.')}
          choices={winterTiresVehiclesChoices}
        />
      }
      { showAutoDiscounts &&
        <>
          <SlideToggle
            name="retiree"
            label={t('Yes')}
            MainLabel={t('Have you retired recently? You may be eligible for additional discounts!')}
          />
          <SlideToggle
            name="out_of_town_student"
            label={t('Yes')}
            MainLabel={t('Is your child attending a post-secondary institution located over 100kms away from home? They may be eligible for discounts up to an additional 50% off!')}
            ChangeCallback={outOfTownStudentCallback}
          />
        </>
      }

      {
        data['out_of_town_student'] === true &&
        <>
          <Text
            name="out_of_town_student_school"
            label={t('What is the name of the school that the child attends?')}
          />
          <Text
            name="out_of_town_student_address"
            label={t('What is the address of the school that the child attends?')}
          />
        </>
      }

      {
        showHabDiscounts &&
        <>
          <SlideToggle
            name="non_smoker"
            label={t('Yes')}
            MainLabel={t('Are you a Non Smoker?')}
            HelpCopy={t("If you or anyone that resides in this house doesn't smoke at all, then you may qualify for this discount which will save you an additional 5% off per policy term!")}
          />
          <SlideToggle
            name="water_damage_prevention"
            label={t('Yes')}
            MainLabel={t('Do you qualify for a Water Damage Prevention discount?')}
            HelpCopy={t('If you have a sump-pump and/or a backflow valve, some of our insurance companies offer additional discount for preventative measures!')}
          />
          <SlideToggle
            name="monitored_alarm"
            label={t('Yes')}
            MainLabel={t('Do you have a centrally monitored alarm?')}
            HelpCopy={t("If you pay a monthly fee to a company to centrally monitor your burglar and fire alarm, then you'll save up to an additional 5% off!")}
          />
          <SlideToggle
            name="mortgage_free"
            label={t('Yes')}
            MainLabel={t('Are you mortgage free?')}
            HelpCopy={t("Congratulations! You've paid off your mortgage and now you'll be eligible for an additional savings of up to 10%!")}
          />
        </>
      }

    </fieldset>
  )
}

export default RenewalDiscount