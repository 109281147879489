import { useState, useContext, useEffect } from 'react'
import { postRequest, putRequest, delRequest } from 'utils/requests'
import { FormContext } from 'utils/context'
import { t2LastThreeYears, getLicenceType } from 'forms/utils/helpers'

const useLicensing = (id, province) => {

  const { data, setFieldValue, clearFieldValue, clearFieldError } = useContext(FormContext)

  const [tier_1, setTier1] = useState('')
  const [tier_2, setTier2] = useState('')
  const [tier_3, setTier3] = useState('')

  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)

  const licenceChange = (value, name) => {
    if (name === 'tier_1') setTier1(value)
    if (name === 'tier_2') setTier2(value)
    if (name === 'tier_3') setTier3(value)
    if (name === 'tier_2') {
      if (!t2LastThreeYears(value)) {
        clearFieldValue('training')
      }
    }
  }

  const createLicence = () => {
    postRequest('/api/lineitems/licences/', {
      owner: id,
      licence_type: getLicenceType(province),
      tier_1: tier_1 || null,
      tier_2: tier_2 || null,
      tier_3: tier_3 || null,
    })
      .then(res => {
        setFieldValue('licences', [res])
        setSuccess(true)
        setErrors({})
      })
      .catch(err => setErrors(err.response))
  }

  const updateLicence = (licenceId, training = false) => {
    putRequest(`/api/lineitems/licences/${licenceId}/`, {
      owner: id,
      licence_type: getLicenceType(province),
      tier_1: tier_1 || null,
      tier_2: tier_2 || null,
      tier_3: tier_3 || null,
      training,
    })
      .then(res => {
        setFieldValue('licences', [res])
        setErrors({})
      })
      .catch(err => setErrors(err.response))
  }

  const updateTraining = val => {
    const licenceId = data.licences.length > 0 ? data.licences[0].id : null
    updateLicence(licenceId, val)
  }

  const licenceClose = () => {
    const licenceId = data.licences.length > 0 ? data.licences[0].id : null
    if (licenceId) updateLicence(licenceId)
    else createLicence()
  }

  const setTierValue = (tier, value) => {
    if (tier === 'tier_1') {
      setTier1(value)
      setFieldValue('tier_1', value)
    }
    if (tier === 'tier_2') { 
      setTier2(value)
      setFieldValue('tier_2', value)
    }
    if (tier === 'tier_3') { 
      setTier3(value)
      setFieldValue('tier_3', value)
    }
  }

  const cancelTier = tier => {
    clearFieldValue(tier)
    if (tier === 'tier_1') setTier1('')
    if (tier === 'tier_2') setTier2('')
    if (tier === 'tier_3') setTier3('')
  }

  const licenceCancel = tier => {
    if (data.licences.length > 0) {
      if (data.licences[0][tier]) {
        const previousValue  = data.licences[0][tier]
        setFieldValue(tier, previousValue)
        if (tier === 'tier_1') setTier1(previousValue)
        if (tier === 'tier_2') setTier2(previousValue)
        if (tier === 'tier_3') setTier3(previousValue)
      } else {
        cancelTier(tier)
      }
    } else {
      cancelTier(tier)
    }
    clearFieldError(tier)
  }

  const clearDates = e => {
    e.preventDefault()
    const id = data.licences.length > 0 ? data.licences[0].id : null
    if (id) {
      delRequest(`/api/lineitems/licences/${id}/`)
        .then(() => {
          setFieldValue('licences', [])
          setTier1('')
          setTier2('')
          setTier3('')
          clearFieldValue('tier_1')
          clearFieldValue('tier_2')
          clearFieldValue('tier_3')
          setErrors({})
          setSuccess(false)
        })
    }
  }

  useEffect(() => {
    if (data.licences.length > 0) {
      let licence = data.licences[0]
      if (licence.tier_1 !== null) setTierValue('tier_1', licence.tier_1)
      if (licence.tier_2 !== null) setTierValue('tier_2', licence.tier_2)
      if (licence.tier_3 !== null) setTierValue('tier_3', licence.tier_3)
      setSuccess(true)
    }
  }, []) // eslint-disable-line

  return {
    tier_1, tier_2, tier_3,
    licenceClose,
    licenceChange,
    licenceCancel,
    errors,
    clearDates,
    updateTraining,
    eligibleForTraining: t2LastThreeYears(tier_2), 
    hasTraining: data.licences.length > 0 && data.licences[0].training,
    success,
  }
}

export default useLicensing