import { useContext } from 'react'
import Tile from 'components/Tile'
import PropertyIcon from 'utils/icons/Property'
import Address from 'components/Address'
import { AccountContext } from 'utils/context'
import policyType from 'account/HabPolicies/policyType'

const HabDetail = ({ policy }) => {

  const { locations } = useContext(AccountContext)

  const policyLocations = locations.filter(l => l.policy === policy.id)
  
	return (
		<div>
      {policyLocations.map((l, i) => (
        <Tile key={i} Icon={PropertyIcon} IconName="PropertyIcon">
          <div className="TileBody">
            <small className="uppercase subtle">{policyType(policy.policy_type)}</small>
            <Address key={l.id} location={l} className="secondary" />
          </div>
        </Tile>
      ))}
    </div>
  )
}

export default HabDetail