import React, { useContext } from 'react'
import { AccountContext, AppContext } from 'utils/context'

const TenantStr = ({ ProductName, LegalName, FooterName, Address, City, PostalCode, Province, Hours }) => {
const { tenant } = useContext(AppContext)
const { contact } = useContext(AccountContext)

  if (tenant) {
    if (ProductName) {
      return <span>{tenant.product_name}</span>
    }
    if (LegalName) {
      return <span>{tenant.legal_name}</span>
    }
    if (FooterName) {
      return <span>{tenant.footer_name}</span>
    }
    if (Address) {
      return <span>{tenant.address}</span>
    }
    if (City) {
      return <span>{tenant.city}</span>
    }
    if (PostalCode) {
      return <span>{tenant.postal_code}</span>
    }
    if (Province) {
      return <span>{tenant.province}</span>
    }
    if (Hours) {
      let location = "default"
      
      if (contact && contact.province && tenant.hours_of_operation[contact.province]) {
        location = contact.province
      }
      
      if (tenant.hours_of_operation[location]) {
        return <span>{tenant.hours_of_operation[location]}</span>
      }
    }
  }

  return ''
}

export default TenantStr