import { useContext } from 'react'
import SubForm from 'forms/SubForm'
import QuoteVehicles from 'forms/fields/QuoteVehicles'
import SimpleForm from 'forms/SimpleForm'
import VinLookupSubForm from 'forms/fieldsets/vehicle/VinLookupSubForm'
import { FormContext } from 'utils/context'
import api from 'utils/requests'
import { useTranslation } from 'react-i18next'

const QuoteVehicleLookup = () => {

  const { t } = useTranslation()
  const { data, setFieldValue, setFormError, clearAllErrors } = useContext(FormContext)

  const deleteVehicle = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    const request = api.delete(`/lineitems/quote-vehicles/${id}/`)
    fetch(request)
      .then(res => {
        const next = [...data['quote_vehicles']].filter(x => x.id !== id)
        setFieldValue('quote_vehicles', next)
      })
      .catch(console.log)
  }

  const addSuccess = res => {
    setFormError('')
    setFieldValue('quote_vehicles', [
      ...data['quote_vehicles'],
      res,
    ])
  }

  const showForm = data['quote_vehicles'].length < 3

  const getRemaining = () => {
    const len = data['quote_vehicles'].length
    if (len === 0) {
      return t('You can add three more vehicles to this quote if you wish.')
    } else if (len === 1) {
      return t('You can add two more vehicles to this quote if you wish.')
    } else if (len === 2) {
      return t('You can add one more vehicle to this quote if you wish.')
    } else {
      return t('You have added the maximum number of vehicles to this quote.')
    }
  }

  return (
    <fieldset>
      <p className="fieldset-intro">
        <strong>{t('You can add up to 3 vehicles to your quote.')}</strong>  
      </p>
      {data && data['quote_vehicles'].length > 0 && (
        <QuoteVehicles
          items={data ? data['quote_vehicles'] : []}
          onDelete={deleteVehicle}
        />
      )}
      {showForm && (
        <SubForm>
          <SimpleForm SubmitUrl="/api/lineitems/quote-vehicles/" SubmitLabel={t('Submit')} SuccessCallback={addSuccess} InitialData={{ owner: data.id }}>
            <VinLookupSubForm
              parentData={data}
              clearParentFormError={clearAllErrors}
            />
          </SimpleForm>
        </SubForm>
      )}
      {data['quote_vehicles'].length > 0 && (
        <p><strong>{getRemaining()}</strong></p>
      )}
    </fieldset>
  )
}

export default QuoteVehicleLookup