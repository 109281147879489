import { useContext, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import { AppContext } from 'utils/context'

const useModal = (fromDirection, focusId = 'firstFocus') => {

  const { dialogOpen, setDialogOpen } = useContext(AppContext)
  const [active, setActive] = useState(false)

  const closeModal = useCallback(e => {

    if (e && e.target && e.target.nodeName !== 'A') {
      // filters for events within a form to prevent form submission
      e && e.preventDefault()
    }
    setActive(false)
    setTimeout(() => setDialogOpen(''), 162)
  }, [setActive, setDialogOpen])

  const keyPress = useCallback(e => {
    if (active) {
      if (e && e.key && e.key.toLowerCase() === 'escape') {
        closeModal()
      }
    }
  }, [active, closeModal])

  useEffect(() => {
    if (dialogOpen) {
      const elem = document.getElementById(focusId)
      if (elem) elem.focus()
    }
  }, [dialogOpen, focusId])

  useEffect(() => {
    document.addEventListener("keydown", keyPress, false)
    return () => document.removeEventListener("keydown", keyPress, false)
  }, [active, keyPress])

  useEffect(() => {
    const timeout = dialogOpen ? setTimeout(() => {
        setActive(true)
    }, 162) : () => null
    return () => clearTimeout(timeout)
  }, [dialogOpen])

  const cls = classNames('Modal', {
    'AnchorLeft': fromDirection === 'left',
    'AnchorBottom': fromDirection === 'bottom',
    'Active': active,
  })

  return {
    cls,
    active,
    closeModal,
    dialogOpen,
  }
}

export default useModal
