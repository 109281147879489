import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import PinkSlip from 'utils/icons/PinkSlip'
import { postRequest } from 'utils/requests'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const RequestPinks = () => {
  const { t } = useTranslation()
  const [url, setUrl] = useState(null)

  const { vehicles } = useContext(AccountContext)

  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      try {
        const ids = vehicles.map(v => v.id)
        const response = await postRequest('/api/pink-slips/', { vehicles: ids }, controller)
        setUrl(response.content.url)
        controller = null
      } catch (e) {
        console.log('RequestPinks error: ', e)
      }
    })()
    return () => controller?.abort()
  }, [vehicles, url])

  return vehicles && url ? (
    <a href={url} className="Tile HasIcon" target="_blank" rel="noreferrer" data-testid="download-view-pink-slips">
      <div className="TileIcon">
        <PinkSlip />
      </div>
      <div className="TileHeader">
        <h4 className="uppercase">{t('Download/View Pink Slips')}</h4>
        <small>{t('View your proof of auto insurance here.')}</small>
      </div>
    </a>
  ) : null
}

export default RequestPinks