import { useContext } from 'react'
import Tile from 'components/Tile'
import Link from 'components/Buttons/Link'
import PinksButton from 'account/PinksButton'
import AutoIcon from 'utils/icons/Auto'
import UserIcon from 'utils/icons/User'
import { AccountContext } from 'utils/context'
import { getPolicyValue } from 'account/utils/helpers'
import { useTranslation } from 'react-i18next'

const AutoPolicies = () => {

  const { t } = useTranslation()
  const { policies, drivers, vehicles } = useContext(AccountContext)

  return (
    <>
      {vehicles.map((v, i) => (
        <Tile key={i} Icon={AutoIcon} IconName="AutoIcon">
          <div className="TileHeader">
            <small className="uppercase subtle">{v.year} {v.make}</small>
            <p className="secondary font-p2 zero-margin">{v.model}</p>
          </div>
          <div className="TileBody">
            <Link to={`/account/${getPolicyValue(v.policy, policies, 'policy_number')}`} Pill Light Small ariaLabel=
            {t('View detail for policy {{policyNumber}}', {policyNumber: getPolicyValue(v.policy, policies, 'policy_number')})} DataTestId={`driver-policy--${i}`}>
              {getPolicyValue(v.policy, policies, 'policy_number') || t('Loading…')}
            </Link>
            <PinksButton ids={[v.id]} hasPink={v.pink_slip_available} Right />
          </div>
        </Tile>
      ))}
      {drivers.map((d, i) => (
        <Tile key={i} Icon={UserIcon} IconName="UserIcon">
          <div className="TileHeader">
            {d.first_name && d.last_name ? (
              <>
                <small className="uppercase subtle">{d.last_name}</small>
                <p className="secondary uppercase font-p2 zero-margin">{d.first_name}</p>
              </>
            ) : (
              <p className="secondary uppercase font-p2 zero-margin">{d.short_name}</p>
            )}
          </div>
          <div className="TileBody">
            <Link to={`/account/${getPolicyValue(d.policy, policies, 'policy_number')}`} Pill Light Small ariaLabel=
              {t('View detail for policy {{policyNumber}}', {policyNumber: getPolicyValue(d.policy, policies, 'policy_number')})} DataTestId={`driver-policy--${i}`}>
                {getPolicyValue(d.policy, policies, 'policy_number') || t('Loading…')}
              </Link>
          </div>
        </Tile>
      ))}
    </>
  )
}

export default AutoPolicies