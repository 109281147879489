import React from 'react'
import { addDays, startOfDay } from 'date-fns'
import PoliciesPk from 'forms/fields/PoliciesPk'
import DateChooser from 'forms/fields/DateChooser'

const GetStarted = ({ getStartedPoliciesLabel, getStartedDateLabel, getStartedDateHelpCopy }) => {
  return (
    <fieldset>
     <PoliciesPk
        name="policy"
        label={getStartedPoliciesLabel}
        AutoOnly
      />
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={getStartedDateLabel}
        IncludeYear
        HelpCopy={getStartedDateHelpCopy}
      />
    </fieldset>
  )
}

export default GetStarted