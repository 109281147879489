import { useRef } from 'react'
import Tile from 'components/Tile'

const LoadingTiles = () => {

  const random = Math.floor(Math.random() * 2) + 1
  const randomNumber = useRef(random)

  return (
    <>
      {[...Array(randomNumber.current)].map((x, i) =>
        <Tile key={i} Loading />
      )}
    </>
  )
}

export default LoadingTiles