import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import OwnerDriverConfirm from 'forms/fieldsets/vehicle/OwnerDriverConfirm'
import UsageConfirm from 'forms/fieldsets/vehicle/UsageConfirm'
import EligibilityConfirm from 'forms/fieldsets/vehicle/EligibilityConfirm'
import CoverageConfirm from 'forms/fieldsets/vehicle/CoverageConfirm'
import VehicleInformationConfirm from 'forms/fieldsets/vehicle/VehicleInformationConfirm'
import FinancingConfirm from 'forms/fieldsets/vehicle/FinancingConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { provinceChoices } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const VehicleReplaceConfirm = () => {
  
  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  const { vehicles } = useContext(AccountContext)

  const province = provinceChoices.find(ch => ch[0] === data['registered_province']) || ''

  const removedVehicle = vehicles.find(v => String(v.vehicle_id) === data['vehicle_id']) || {}

  const reasonString = () => {
    switch (data['remove_reason']) {
      case 'sell':
        return t('Selling vehicle')
      case 'trade':
        return t('Trading vehicle')
      default:
        return t('Other reason')
    }
  }

  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Vehicle to Remove")} slug="get-started" id={data.id}>
        <ul>
          <li>
            {removedVehicle.full_vehicle_name}
          </li>
          <li>
            <small>{t('Reason for Removing')}</small>
            {reasonString()}
          </li>
        </ul>
      </ConfirmCard>
      <div className="NewVehicle">
        <small>{t('Your New')}</small>
        <h4>
          <span>{data['year']} {data['make']} {data['model']}</span>
        </h4>
      </div>
      <VehicleInformationConfirm province={province[1]} />
      <OwnerDriverConfirm />
      <UsageConfirm />
      <EligibilityConfirm />
      <CoverageConfirm />
      <FinancingConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default VehicleReplaceConfirm