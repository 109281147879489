import { useState, useContext, useEffect } from 'react'
import { AppContext } from 'utils/context'
import useStatus from 'utils/useStatus'

const useSession = () => {

  // 1. user login
  // 2. backend attempts to refresh status with pivotal
  // 3. response is either 'pending', 'complete' or 'error'
  // 4. if 'pending', retry for 15 seconds
  // 5. if 'error', display error to user
  // 6. if 'complete', user session is active and login proceeds
  // 7. if 'complete', set timer for ~20 mins, logout modal appearing at ~19 mins
  // 8. periodically (~10 seconds, or on window refocus) check with backend to see if session is still active

  const [cancelLoading, setCancelLoading] = useState(false)

  const { remaining, rawRefreshSession } = useStatus()

  const { dialogOpen, setDialogOpen } = useContext(AppContext)

  const cancelLogout = () => {
    setCancelLoading(true)
    rawRefreshSession()
  }

  useEffect(() => {
    if (dialogOpen !== 'logout' && dialogOpen !== 'logout-canceled') {
      if (remaining && remaining < 100) {
        setDialogOpen('logout')
      }
    }
  }, [remaining, dialogOpen, setDialogOpen])

  return {
    cancelLogout,
    cancelLoading,
    setCancelLoading,
  }
}

export default useSession