import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'

const MultiCheckbox = ({ choices, name, label, HtmlLabel, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, className, HelpCopy }) => {

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)

  const classes = classNames('field multi-checkbox', {
    [className]: className,
    'active': active,
    'touched': touched,
    'has-error': error,
    'stacked': true,
  })

  const handleChange = e => {
    const name = Number(e.target.name)
    let currentVal = value ? value.map(v => v) : []
    if (currentVal.includes(name)) {
      currentVal = currentVal.filter(v => v !== name)
    } else {
      currentVal.push(name)
    }
    onChange({ target: { value: currentVal } })
  }

  const getValue = item => value ? value.includes(item) : false

  return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
      <Label label={label} HtmlLabel={HtmlLabel} name={name} />
      <div className="field-wrapper">
        <div className="choices">
          {choices.map(ch =>
            <Label
              key={ch[0]}
              secondaryLabel
              className={getValue(ch[0]) ? 'checked' : null}
            >
            <input
              type="checkbox"
              name={ch[0]}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              checked={getValue(ch[0])}
            />
              <span>{ch[1]}</span>
            </Label>
          )}
        </div>
      </div>
      <FieldError error={error} name={name} />
      {HelpCopy && <div className="help-copy">{HelpCopy}</div>}
    </div>
  )
}

export default MultiCheckbox
