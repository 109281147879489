import React from 'react'
import Radio from 'forms/fields/Radio'

const Vehicles = ({ value, choices, ...props }) => {

  const single = choices.length === 1

  return (
    <Radio
      {...props}
      value={value}
      choices={choices}
      DefaultValue={single ? choices[0][0] : null}
    />
  )
}

export default Vehicles