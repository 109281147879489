import { parseISO, subYears, isValid, isBefore, isAfter } from 'date-fns'
import { prettyDate, dateFromString } from 'utils/helpers'
import { reverseDateFromString } from 'forms/utils/cleans'
import i18n from 'i18n'

export const getLicenceType = province => {
  switch(province) {
    case 'AB':
      return 'class_5'
    case 'NB':
      return 'class_5'
    case 'NL':
      return 'class_5'
    case 'NS':
      return 'class_5'
    case 'PE':
      return 'class_g'
    default:
      return 'g' // ontario
  }
}


export const reformatDate = date => {
  const splitDate = date.split('-')
  let year = splitDate[0]
  let month = splitDate[1]
  let day = splitDate[2]
  if (month.length === 1) {
    month = `0${month}`
  }
  if (day.length === 1) {
    day = `0${day}`
  }
  return `${year}-${month}-${day}`
}

export const t2LastThreeYears = val => {
  if (val) {
    const t2Date = parseISO(val)
    const threeYearsAgo = subYears(new Date(), 3)
    if (isAfter(t2Date, threeYearsAgo)) {
      return true
    }
  }
  return false
}

export const validPostal = postal => {
  const first = 'abceghjklmnprstvxy'
  const second = 'abceghjklmnprstvwxyz'
  const postalRegex = new RegExp(`^[${first}]\\d[${second}]( )?\\d[${second}]\\d$`, 'i')
  if (postal.match(postalRegex)) {
    return true
  }
}

export const isNumeric = num => {
  return !isNaN(parseFloat(num)) && isFinite(num)
}

export const processedAddresses = (addresses = []) => {
  let streets = []
  let city = ''
  let province = ''

  const address = addresses[0]
  const cityAndProvince = address['city']['name'].split(', ')
  city = cityAndProvince[0]
  province = cityAndProvince[1]

  if (addresses.length === 1) {
    if (address.name) {
      streets = [[address.name, address.name]]
    } else {
      throw new Error('streets is null')
    }
  } else if (addresses.length > 1) {
    streets = addresses.map(a => [a.name, a.name])
  }

  return { streets, city, province }
}

export const vehicleYear = vehicle => {
  if (vehicle && vehicle.year) {
    return vehicle.year
  }
  throw new Error('no year found')
}

export const vehicleMake = vehicle => {
  if (vehicle && vehicle.make) {
    if (Array.isArray(vehicle.make)) {
      return vehicle.make[1]
    }
  }
  throw new Error(i18n.t('no make found'))
}

export const processedVehicle = vehicle => {
  if (!vehicle.year) {
    throw new Error(i18n.t('no year found'))
  }
  if (!(vehicle.make && vehicle.make[0])) {
    throw new Error(i18n.t('no make found'))
  }
  return {
    year: vehicle.year,
    make: vehicle.make[1],
    models: vehicle.models,
  }
}

const vinRegex = new RegExp("^[ABCDEFGHJKLMNPRSTUVWXYZ1234567890]{17}$", "i")

export const validVin = value => {
    if (value === null || value === undefined) {
      return false
    }
    return value.match(vinRegex)
}

export const filterKeys = data => {
  if (data && Object.keys(data).length > 0) {
    delete data['created']
    delete data['modified']
    delete data['fieldsets']
    delete data['furthest_fieldset']
    delete data['line_item_type']
    delete data['created_at']
    delete data['request']
  }
  return data
}

export const getPostalCode = (policyId = null, policies = null) => {
  if (policyId && policies && policies.length > 0) {
    let auto = policies.find(p => p.id === policyId.toString())
    if (auto) {
      return auto.province
    }
  }
  return 'ON'
}

const validDay = day => {
  if (
    !day ||
    typeof day !== 'string' ||
    day.length < 1 ||
    day.length > 2 ||
    day === '0'
  ) {
    throw new Error(i18n.t('Invalid date, check the day entered'))
  }
  return true
}

const validMonth = month => {
  if (
    !month ||
    typeof month !== 'string' ||
    month.length < 1 ||
    month.length > 2 ||
    month === '0'
  ) {
    throw new Error(i18n.t('Invalid date, check the month entered'))
  }
  return true
}

const validYear = year => {
  if (
    !year ||
    typeof year !== 'string' ||
    year.length < 4 ||
    year.length > 4
  ) {
    throw new Error(i18n.t('Invalid date, check the year entered'))
  }
  return true
}

const fullValidDate = dateObj => {
  if (!isValid(dateObj)) {
    throw new Error(i18n.t("There's a problem with the date entered"))
  }
}

export const cleanDate = (date, minDate, maxDate) => {
  if (date === null) {
    throw new Error(i18n.t('This field is required'))
  } else {
    let arr = date.split('-')
    validDay(arr[2])
    validMonth(arr[1])
    validYear(arr[0])
    let d = new Date(arr[0], arr[1] - 1, arr[2])
    fullValidDate(d)
    if (minDate && isBefore(d, minDate)) {
      throw new Error(i18n.t('mustBeAfter', {date: prettyDate(minDate)}))
    }
    if (maxDate && isAfter(d, maxDate)) {
      throw new Error(i18n.t('mustBeBefore', {date: prettyDate(maxDate)}))
    }
  }
  return true
}

export const isFemaleFromLicenceMonth = month => {
  if (Number(month)) {
    if (Number(month) > 50 && Number(month) < 63) {
      return true
    }
  }
  return false
}

export const femaleMonth = month => {
  if (month) {
    if (Number(month) > 0 && Number(month) < 13) {
      return month.length === 1 ? `0${month}` : month
    } else if (Number(month) > 50 && Number(month) < 63) {
      month = Number(month - 50)
      return String(month).length === 1 ? `0${month}` : month
    }
  }
}

export const dobAndGenderFromLicence = licence => {
  const date = licence.substr(licence.length - 6)
  const { year, month, day } = dateFromString(date)
  return {
    dob: `${year}-${femaleMonth(month)}-${day}`,
    gender: isFemaleFromLicenceMonth(month) ? 'female' : 'male',
  }
}

export const dobFromNlLicence = licence => {
  const date = licence.substr(1, 6)
  const { year, month, day } = dateFromString(date)
  return `${year}-${month}-${day}`
}

export const dobFromNsLicence = licence => {
  const date = licence.substr(5, 11)
  const { year, month, day } = reverseDateFromString(date)
  return `${year}-${month}-${day}`
}

// dedupe an array of arrays (typically for choices for a Select component)

export const dedupe = arr => {
  const result = arr.reduce((unique, o) => {
    if(!unique.some(obj => obj.value === o.value)) {
      unique.push(o)
    }
    return unique
  },[])
  return result
}

export const showField = (field, data = null) => {
  if (data && data[field]) {
    if (typeof data[field] === 'string') {
      return true
    }
  }
  return false
}
