import React from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import VehicleRemoveGetStarted from 'forms/fieldsets/vehicle/VehicleRemoveGetStarted'
import RemoveReason from 'forms/fieldsets/vehicle/RemoveReason'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import VehicleRemoveConfirm from 'forms/fieldsets/vehicle/VehicleRemoveConfirm'
import { useTranslation } from 'react-i18next'
import './styles/vehicle-remove.scss'

const VehicleRemove = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="vehicle-remove" />
      <VehicleRemoveGetStarted
        path="get-started"
        getStartedLabel={t('When will you no longer have possession of the vehicle?')}
      />
      <RemoveReason path="remove-reason" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('Removing a vehicle from a policy may result in a change in how you use your remaining vehicle(s). Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <PrincipalDrivers path="principal-drivers" 
        helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}
      />
      <VehicleRemoveConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default VehicleRemove