import { useContext, useState } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import BasePage from 'components/BasePage'
import TenantComponent from 'components/TenantComponent'
import Home from './Home'
import Existing from './Existing'
import Selector from './Selector'
import FormWrapper from './FormWrapper'
import Previous from './Previous'
import Received from './Received'
import Button from 'components/Buttons/Button'
import { AccountContext } from 'utils/context'
import './style.scss'
import { useTranslation } from 'react-i18next'

const RequestsMain = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const { push } = useHistory()
  const { path } = useRouteMatch()

  const { currentRequest: req, lineitem, addLineitem } = useContext(AccountContext)

  // this page handles every request at `/account/requests/*`
  // it accepts a requestType (question, quote, change) and
  // a lineitem type (vehicle-add, coverage-change, etc.)
  // the specific form URLs are then handled in FormWrapper.js

  const startLineitem = () => {
    setLoading(true)
    addLineitem(req)
      .then(res => {
        setLoading(false)
        push(`/account/requests/${req.requestType}/${req.lineitemUrl}/${res.id}/${res.current_fieldset}`)
      })
  }

  return (
    <BasePage>
      <div className="container">
        <Switch>
          <Route exact path={path} component={lineitem.id ? Existing : Home} />
          <Route exact path={`${path}/previous`} component={Previous} />
          <Route exact path={`${path}/:requestType/received`} component={Received}>
            <TenantComponent element={<Received />} elementName="Received" />
          </Route>
          <Route exact path={`${path}/:requestType`} component={Selector} />
          <Route path={`${path}/:requestType/:lineitemUrl`} component={FormWrapper} />
        </Switch>
      </div>
      <Switch>
        <Route exact path={`${path}/previous`} component={null} />
        <Route exact path={`${path}/:requestType`} component={() =>
          <div className="FormNav RequestNav">
            <div className="container" style={{ justifyContent: 'flex-end' }}>
              <Button onClick={startLineitem} Disabled={!req.lineitemUrl} Pill Light Loading={loading} DataTestId="start-request">
                {t('Start Request')}
              </Button>
            </div>
          </div>
        } />
      </Switch>
    </BasePage>
  )
}

export default RequestsMain