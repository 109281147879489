import React from 'react'
// import numeral from 'numeral'
import Button from 'components/Buttons/Button'
import InfoIcon from 'utils/icons/Info'

function CoverageItem({ item, activeTooltip, setActiveTooltip }) {

  if (item.deductible_amount || item.deductible_percentage || item.limit_amount) {
    // do something?
  }

  const handleClick = () => {
    if (item.coverage_code === activeTooltip) {
      setActiveTooltip('')
    } else {
      setActiveTooltip(item.coverage_code)
    }
  }

  return item ? (
    <li className="CoverageItem clearfix">
      {item.help &&
        <Button onClick={handleClick} Icon>
          <InfoIcon />
        </Button>
      }
      <span className="ItemTitle">{item.desc}</span>
      {item.help && (
        <div className={activeTooltip === item.coverage_code ? 'CovHelp visible' : 'CovHelp'}>
          <small>{item.help}</small>
        </div>
      )}
    </li>
  ) : null
}

export default CoverageItem