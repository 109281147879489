import { useState, useEffect } from 'react'

const useTimer = delta => {
  const [count, setCount] = useState(delta)

  useEffect(() => {
    const tick = () => setCount(prevState => prevState - 1)
    const timer = setInterval(() => tick(), 1000)
    return () => clearInterval(timer)
  }, [count, delta])

  return { count, setCount }
}

export default useTimer