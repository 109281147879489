import React, { useContext } from 'react'
import { AccountContext, FormContext } from 'utils/context'
import Select from 'forms/fields/Select'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { vehicleReduceReasons, vehicleReduceDuration, vehicleReduceAmount } from 'forms/utils/vars'
import { useTranslation, Trans } from 'react-i18next'
const ReduceReason = () => {

  const { t } = useTranslation()
  const { policies } = useContext(AccountContext)
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  
  const Rebuilt = <><Trans i18nKey="rebuiltQuestion" components={{ s: <strong /> }} /></>

  let policy = policies.find(p => p.id === data.policy)
  let showOtherText = data['reduce_reason'] === 'other'
  let inAlberta = policy.province === "AB"
  let keepGlass = data['glass_coverage_choice'] === 'keep'
  
  const reasonChange = value => {
    if (value !== 'other') {
      setFieldValue('reduce_reason_other', null)
      clearAllErrors()
    }
  }

  return (
    <fieldset>
      <Radio
        name="reduce_reason"
        label={t("Why are you reducing coverage for this vehicle?")}
        choices={vehicleReduceReasons}
        ChangeCallback={reasonChange}/>
      {showOtherText && (
        <Text name="reduce_reason_other" label={t("Other Reason")} />
      )}
      <span className="help-copy">{t('Any potential credit resulting from this change will be calculated based on the number of days that coverage was reduced, coverage must be reduced for a minimum of 45-60 days (timeframe varies by insurance company).')}</span>
      <Select
        name="reduction_duration"
        label={t("Approximate duration of reduction")}
        choices={vehicleReduceDuration}/>
      <Select
        name="requested_deductible"
        label={t("Choose deductible amount")}
        choices={vehicleReduceAmount[policy.carrier_code] ? vehicleReduceAmount[policy.carrier_code] : vehicleReduceAmount["other_carriers"]} />
      <Text name="parking_location" label={t("What address will the vehicle will be parked at?")}/>
      {inAlberta &&
      <div>
        <Radio
          name="glass_coverage_choice"
          label={t("While your vehicle is on reduced coverage, would you like full glass coverage or limited glass coverage?")}
          choices={[['keep', 'Limited Glass Coverage'], ['full', 'Full Glass Coverage']]} 
          Stacked
          />
          {keepGlass && 
            <div className="help-copy">
              <span>{t('Limited Glass Coverage restricts coverage to the following:')}</span>
              <ul>
                <li>{t('Specified perils')}</li>
                <li>{t('Impact with a live undomesticated animal')}</li>
                <li>{t("Another vehicle striking your vehicle when it's not in use or operation")}</li>
                <li>{t('Vandalism to all windows except the windshield')}</li>
              </ul>
              <p>{t('It does not cover falling objects like rocks or stones.')}</p>
            </div>
          }
        </div>
      }
      <Radio
        name="rebuilt_salvaged"
        label={t("Has the vehicle been rebuilt or salvaged?")}
        HtmlLabel={Rebuilt}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
    </fieldset>
  )
}

export default ReduceReason
