import { useContext } from 'react'
import Text from 'forms/fields/Text'
import Radio from 'forms/fields/Radio'
import Select from 'forms/fields/Select'
import { FormContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import { relationshipChoices } from 'forms/utils/vars'
import { titleCase } from 'utils/helpers'

const OtherDriversSubForm = ({ parentData }) => {
  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)

  const howDoYouKnow = data['first_name'] ? <Trans i18nKey="howDoYouKnow" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="howDoYouKnowGeneral" />

  const insuredCallback = value => {
    if (value === false) {
      setFieldValue('insurance_company', '')
      setFieldValue('policy_number', '')
    }
  }

  const relationCallBack = value => {
    if (value !== 'other') {
      setFieldValue('relationship_status_other', null)
      clearFieldError('relationship_status_other')
    }
  }

  return (
    <>
      <Text name="owner" DefaultValue={parentData.id} Hidden />
      <Text label={t("Other licensed driver's name")} name="driver_name" />
      <Select
        name="relationship_status"
        label={howDoYouKnow}
        choices={relationshipChoices}
        ChangeCallback={relationCallBack}
      />
      {data['relationship_status'] === 'other' && (
        <Text
          name="relationship_status_other"
          label={t("What is the relationship between you and the driver?")}
        />
      )}
      <Radio
        name="currently_insured"
        label={t('Is the driver currently insured?')}
        choices={[[false, t('No')], [true, t('Yes')]]}
        ChangeCallback={insuredCallback}
        HelpCopy={t('Please note this does not necessarily add a driver to your policy, if the driver is not insured we may need to add them to your policy, and we will contact you if that is necessary.')}
      />
      {data['currently_insured'] && (
        <>
          <Text label={t('Insurance Company')} name="insurance_company" />
          <Text label={t('Policy Number')} name="policy_number" />
        </>
      )}
    </>
  )
}

export default OtherDriversSubForm