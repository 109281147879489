import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import { useTranslation, Trans } from 'react-i18next'
const Received = () => {
  const { t } = useTranslation()
  return (
    <div className="container">
      <p>
        <Trans
          i18nKey="requestReceivedMessage"
          components={{ productName: <TenantStr ProductName />}}
        />
      </p>
      <ul className="bullet-list">
        <li>{t('We’ll process your request in the next 1-2 business days.')}</li>
        <li>{t('We’ll contact you if we have any questions.')}</li>
        <li>{t('We’ll email you confirmation once your request has been completed.')}</li>
      </ul>
      <p>
        <Trans
          i18nKey="anyQuestonsMessage"
          components={{ productName: <TenantStr ProductName />}}
        />
      </p>
      <Link to="/account" Pill>{t('Account Summary')}</Link>
    </div>
  )
}

export default Received