import React, { useContext, useEffect } from 'react'
import ArrayRadio from 'forms/fields/ArrayRadio'
import ArrayItems from 'forms/fields/ArrayItems'
import SubForm from 'forms/SubForm'
import SimpleForm from 'forms/SimpleForm'
import OtherDriversSubForm from './OtherDriversSubForm'
import { FormContext } from 'utils/context'
import { delRequest } from 'utils/requests'
import { useTranslation } from 'react-i18next'

const OtherDrivers = () => {
  const { t } = useTranslation()
  const { data, setFieldValue, setFieldError, clearFieldError } = useContext(FormContext)

  const NoneCallback = value => {
    if (value === 'none') {
      if (data['other_drivers'].length > 0) {
        setFieldError('has_other_drivers', t('Please remove all added drivers first'))
        setFieldValue('has_other_drivers', 'one_or_more')
      }
    }
  }

  const deleteDriver = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    const request = delRequest(`/api/lineitems/other-drivers/${id}/`)
    fetch(request)
      .then(res => {
        const next = [...data['other_drivers']].filter(x => x.id !== id)
        setFieldValue('other_drivers', next)
        clearFieldError('has_other_drivers')
      })
      .catch(console.log)
  }

  const addSuccess = res => {
    clearFieldError('has_other_drivers')
    setFieldValue('other_drivers', [
      ...data['other_drivers'],
      res,
    ])
  }

  useEffect(() => {
    if (data.other_drivers.length > 0) {
      setFieldValue('has_other_drivers', 'one_or_more')
    }
  }, []) // eslint-disable-line

  return (
    <fieldset>
      <ArrayRadio
        name="has_other_drivers"
        ArrayToggleValue="one_or_more"
        ChangeCallback={NoneCallback}
        label={t('Are there any other licensed drivers in the household or anyone who has frequent access to your vehicle who are not listed on your policy?')}
        choices={[['one_or_more', t('One or More')], ['none', t('None')]]}
      >
        <ArrayItems
          items={data ? data['other_drivers'] : []}
          title="driver_name"
          onDelete={deleteDriver}
          Placeholder={t('Please provide details on the driver below')}
        />
        <SubForm>
          <SimpleForm SubmitUrl="/api/lineitems/other-drivers/" SubmitLabel={t('Submit')} SuccessCallback={addSuccess} InitialData={{ owner: data.id, currently_insured: false }}>
            <OtherDriversSubForm parentData={data} />
          </SimpleForm>
        </SubForm>
      </ArrayRadio>
    </fieldset>
  )
}

export default OtherDrivers

