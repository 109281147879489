import { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { FormContext } from 'utils/context'
import CoverageNew from 'forms/fieldsets/vehicle/CoverageNew'
import Radio from 'forms/fields/Radio'
import { getAuthedRequest } from 'utils/requests'
import LoadingSpinner from 'components/LoadingSpinner'
import { useTranslation } from 'react-i18next'

const CoverageReview = () => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAuthedRequest('/api/lineitems/')
      .then(res => {
        const current = res.find(li => li.id === data.id)
        if (current.vehicle_coverages) {
          setFieldValue('vehicle_coverages', current.vehicle_coverages)
          setIsLoading(false)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [data.vehicle_coverages]) // eslint-disable-line

  const changeCallback = () => {
    clearAllErrors()
  }

  return (
    <fieldset>
      <p className="fieldset-intro"> 
        {t('With this change in coverage, will you need to make any other changes to the remaining vehicle(s) on this policy?')}
      </p>

      {isLoading && 
        <div className="LoadingHolder"><LoadingSpinner /></div>}
      
      {!isLoading ? 
      <div data-testid="fetch-success">{
        data.vehicle_coverages.length > 0 ? 
        data.vehicle_coverages.map((vehicle, index) => (
          <div key={index}>
            <Radio
              name={`${vehicle.id}_change_vehicle_coverage`}
              label={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
              choices={[[true, t('Yes')], [false, t('No')]]}
              ChangeCallback={changeCallback}
            />
            <div style={data[`${vehicle.id}_change_vehicle_coverage`] === true ? {} : {display: "none"}}>
              <CoverageNew prefix={vehicle.id}/>
            </div>
          </div>
        )) : <strong>{t('This is not applicable to your current policy. Please click continue.')}</strong>
      }</div>
      : null }
      
    </fieldset>
  )
}

export default CoverageReview
