import React from 'react'
import PolicyDrivers from 'forms/fields/PolicyDrivers'
import { useTranslation } from 'react-i18next'

const QuoteOwnerDriver = () => {
  const { t } = useTranslation()
  return (
    <fieldset>
      <PolicyDrivers
        name="principal_driver"
        label={t("Principal Driver")}
        Stacked
      />
    </fieldset>
  )
}

export default QuoteOwnerDriver