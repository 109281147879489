import React, { useEffect, useContext } from 'react'
import Radio from 'forms/fields/Radio'
import { driversDisable } from 'forms/fieldsets/vehicle/OwnerDriver'
import { FormContext } from 'utils/context'


const Drivers = ({ value, choices, callBack, ...props }) => {
  
  const { results }  = useContext(driversDisable)
  const { data } = useContext(FormContext)

  const single = choices.length === 1
  
  const changeCallBack = (val, name, id) => {
    if(callBack){
      callBack('newCar', val)
    }
  }
  
  useEffect(() => {
    if(callBack && data['principal_driver']){
      callBack('newCar', Number(data['principal_driver']))
    }
  }, [typeof results === 'object' && Object.keys(results).length]) //eslint-disable-line

  return (
    <Radio
      {...props}
      value={value}
      choices={choices}
      DefaultValue={single ? choices[0][0] : null}
      ChangeCallback={changeCallBack}
      disabledChoices={results ? results['newCar'] : null}
    />
  )
}

export default Drivers