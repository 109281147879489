import React, { useContext } from 'react'
import { AccountContext } from 'utils/context'
import Drivers from 'forms/fields/Drivers'

const PolicyVehicles = ({ value, exclude, IncludeNotListed, ...props }) => {

  let { vehicles, lineitem } = useContext(AccountContext)

  let finalChoices = []

  vehicles.forEach(v => {
    if (v.policy === lineitem.policy) {
      finalChoices.push([`${v.vehicle_id}`, `${v.full_vehicle_name}`])
    }
  })

  if (exclude && exclude.id) {
    finalChoices = finalChoices.filter(ch => ch[0] !== exclude.id)
  }

  return (
    <Drivers
      {...props}
      value={value}
      choices={finalChoices}
      Stacked
    />
  )
}

export default PolicyVehicles
