import { useContext } from 'react'
import TenantUrl from 'components/TenantUrl'
import { AppContext } from 'utils/context'
import { Trans } from 'react-i18next'
import './style.scss'

const TermsConditions = ({ Signup }) => {

  const { tenant } = useContext(AppContext)

  const hasLinks = tenant && tenant.tc_link && tenant.privacy_link
  const signUpMsg = <><Trans i18nKey='signUpOnlineMsg' components={{terms: <TenantUrl Terms />, privacy: <TenantUrl Privacy />}}/></>
  const enterSiteMsg = <><Trans i18nKey='enterSiteOnlineMsg' components={{terms: <TenantUrl Terms />, privacy: <TenantUrl Privacy />}}/></>
  
  return hasLinks ? (
    <small className="TermsConditions inline-links">{Signup ? signUpMsg : enterSiteMsg}
    </small>
  ) : null
}

export default TermsConditions