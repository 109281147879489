import classNames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'

import './style.scss'

const Link = ({ id, children, to, className, Pill, Light, Accent, Transparent, Outline, Subtle, Small, Disabled, Hidden, onClick, tabIndex, ariaLabel, DataTestId }) => {

  const cls = classNames('Link', {
    'Pill': Pill,
    'Light': Light,
    'Accent': Accent,
    'Transparent': Transparent,
    'Outline': Outline,
    'Subtle': Subtle,
    'Small': Small,
    'Disabled': Disabled,
    'Hidden': Hidden,
    [className]: className,
  })

  let href = to || '/'

  if (Disabled) {
    href = '#'
  }
  
  let dataAttrs = {}
  if (DataTestId) {
    dataAttrs['data-testid'] = DataTestId
  }

  return (
    <RouterLink className={cls} to={href} id={id} onClick={onClick} tabIndex={tabIndex} aria-label={ariaLabel || null} {...dataAttrs}>
      {children}
    </RouterLink>
  )
}

export default Link