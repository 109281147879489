import NoMatch from 'components/NoMatch'
import Link from 'components/Buttons/Link'
import { useTranslation } from 'react-i18next'
const NotFound = () => {
  const { t } = useTranslation()
  return (
    <NoMatch Heading="Policy could not be found">
      <Link to="/account" Pill Small Outline>
        {t('Return to dashboard')}
      </Link>
    </NoMatch>
  )
}

export default NotFound