import { useContext } from 'react'
import Tile from 'components/Tile'
import CoverageList from './CoverageList'
import PinksButton from 'account/PinksButton'
import PolicyPinks from './PolicyPinks'
import AutoIcon from 'utils/icons/Auto'
import UserIcon from 'utils/icons/User'
import { AccountContext } from 'utils/context'

const AutoDetail = ({ policy }) => {

  const { vehicles, drivers } = useContext(AccountContext)

  const policyVehicles = vehicles.filter(v => v.policy === policy.id)
  const policyDrivers = drivers.filter(d => d.policy === policy.id)

	return (
    <>
      {policyDrivers.map((d, i) => (
        <Tile key={i} Icon={UserIcon} IconName="UserIcon">
          <div className="TileHeader">
            {d.first_name && d.last_name ? (
              <>
                <small className="subtle uppercase">{d.last_name}</small>
                <p className="secondary uppercase font-p2 zero-margin">{d.first_name}</p>
              </>
            ) : (
              <p className="secondary uppercase font-p2 zero-margin">{d.short_name}</p>
            )}
          </div>
        </Tile>
      ))}
      <PolicyPinks vehicles={policyVehicles} /> 
      {policyVehicles.map((v, i) => (
        <Tile key={i} Icon={AutoIcon} IconName="AutoIcon">
          <div className="TileHeader">
            <small className="subtle uppercase">{v.year} {v.make}</small>
            <p className="secondary font-p2 zero-margin">{v.model}</p>
          </div>
          <div className="TileBody">
            <PinksButton ids={[v.id]} hasPink={v.pink_slip_available} />
          </div>
          <div className="TileBody">
            <CoverageList vehicleId={v.id} />
          </div>
        </Tile>
      ))}
    </>
  )
}

export default AutoDetail