import Link from 'components/Buttons/Link'
import ArrowIcon from 'utils/icons/Arrow'
import { useTranslation } from 'react-i18next'

const SiteNav = ({ closeModal }) => {
  const { t } = useTranslation()
  return (
    <>
      <Link id="firstFocus" onClick={closeModal} to="/" DataTestId="home">{t('Home')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/login" DataTestId="login">{t('Login')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/signup" DataTestId="signup">{t('Signup')}<ArrowIcon /></Link>
      <hr />
      <Link onClick={closeModal} to="/contact" DataTestId="contact">{t('Contact')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/disclosures" DataTestId="disclosures">{t('Disclosures')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/help" DataTestId="help">{t('Help')}<ArrowIcon /></Link>
    </>
  )
}

export default SiteNav