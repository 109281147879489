import React, { useContext } from 'react'
import Radio from 'forms/fields/Radio'
import Usage from 'forms/fieldsets/vehicle/Usage'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import PrincipalDrivers from './PrincipalDrivers'
import LoadingSpinner from 'components/LoadingSpinner'
import useUsage from 'forms/utils/useUsage'
import { useTranslation } from 'react-i18next'

const ChangedDetails = ({helpCopy}) => {

  const { t } = useTranslation()
  const { data, clearAllErrors } = useContext(FormContext)
  const { vehicles, policies } = useContext(AccountContext)
  
  const { showAskChanges, summaryLabels, isLoading } = useUsage()
  
  const vehicleBeingReplaced = vehicles.find(v => v.vehicle_id === Number(data['vehicle_id']))
  
  const singleVehicle = vin => {
    const single = vehicles.find(v => v.vin === vin)
    return single ? `${single.full_vehicle_name}` : t('No valid vehicle name')
  }

  const showParkedAtNight = vin => {
    const vehicle = vehicles.find(v => v.vin === vin)
    const policy = policies.find(p => p.id === vehicle.policy)
    return policy.carrier_code === 'PEM' || policy.carrier_code === 'PAF'
  }

  const valueFromForm = (id, field) => {
    if (data[`${id}_${field}`]) {
      return data[`${id}_${field}`]
    }
    return null
  }
  
  const clearSameUsageErrors = (val) => {
    if (val) {
      clearAllErrors()
    }
  }
  
  return (
    <fieldset>
      <p className="fieldset-intro">{helpCopy}</p>

      {isLoading && 
        <div className="LoadingHolder"><LoadingSpinner /></div>}
      
      {!isLoading ?
        <div data-testid="fetch-success">{
          (data.vehicle_changes.length > 0) ? data.vehicle_changes.filter((vehicle) => vehicle.vin !== vehicleBeingReplaced?.vin).map((vehicle, index) => (
            <div key={vehicle.id} data-testid="single-vehicle-usage">
              <strong className="form-title">{singleVehicle(vehicle.vin)}</strong>
              {
                showAskChanges[index] && 
                <>
                  <div>{t('Are you still using the vehicle for:')}</div>
                  <ul>
                    {summaryLabels[index].map(label => (
                      <li key={label}>{label}</li>
                    ))}
                  </ul>
                  <Radio
                    name={`${vehicle.id}_same_usage`}
                    choices={[[true, t('Yes')], [false, t('No')]]}
                    DefaultValue={valueFromForm(vehicle.id, 'same_usage') || vehicle.same_usage}
                    ChangeCallback={clearSameUsageErrors}
                  />
                </>
              }

              <div style={data[`${vehicle.id}_same_usage`] === false ? {} : {display: "none"}} >
                <Usage
                  prefix={vehicle.id}
                  defaultUsage={valueFromForm(vehicle.id, 'usage') || vehicle.usage}
                  defaultAnnual={valueFromForm(vehicle.id, 'annual_kms') || vehicle.annual_kms}
                  defaultDaily={valueFromForm(vehicle.id, 'daily_kms') || vehicle.daily_kms}
                  defaultBusiness={valueFromForm(vehicle.id, 'business_annual_kms') || vehicle.business_annual_kms}
                  usageLabel={t('Please update your vehicle usage below.')}
                  ShowParkedAtNight={showParkedAtNight(vehicle.vin)}
                  DefaultParkedAtNight={valueFromForm(vehicle.id, 'parked_overnight') || vehicle.parked_overnight}
                  defaultCommuteVehicle={valueFromForm(vehicle.id, 'commute_vehicle') || vehicle.commute_vehicle}
                />
              </div>
            </div>
          )) : <strong>{t('This is not applicable to your current policy. Please click continue.')}</strong>
        }</div> 
      : null} 
      {(data.type === 'address_change' || data.type === 'vehicle_add_quote' || data.type === 'vehicle_replace_quote') && <PrincipalDrivers helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}/> }
    </fieldset>
  )
}

export default ChangedDetails