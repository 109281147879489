import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import CharacterCount from './CharacterCount'
import FieldError from './FieldError'

const TextArea = ({ name, label, placeholder, DefaultValue, SupportCopy, HelpCopy, ChangeCallback, FocusCallback, BlurCallback, className, Hidden, cleans, Optional, MaxChars }) => {

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, cleans, Optional)

  const classes = classNames('field textarea', {
    [className]: className,
    'active': active,
    'touched': touched,
    'has-error': error,
    'hidden': Hidden,
    'show-counter': MaxChars,
  })

  const getHelp = () => {
    if (typeof HelpCopy === 'function') {
      return <HelpCopy />
    } else {
      return <span className="help-copy">{HelpCopy}</span>
    }
  }
  
  const getSupport = () => {
    if (typeof SupportCopy === 'function') {
      return <SupportCopy />
    } else {
      return <span className="support-copy">{SupportCopy}</span>
    }
  }

  return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
      <Label label={label} name={name} />
      {SupportCopy && getSupport()}
      <div className="field-wrapper">
        <div style={{ position: 'relative' }}>
          <textarea
            id={name}
            name={name}
            value={value || ''}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            rows="3"
            style={{resize: 'vertical', minHeight: '150px'}}
          />
          {MaxChars && <CharacterCount value={value} MaxChars={MaxChars} />}
        </div>
        <FieldError error={error} name={name} />
        {HelpCopy && getHelp()}
      </div>
    </div>
  )
}

export default TextArea