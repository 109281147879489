import React, { useContext } from 'react'
import { AppContext } from 'utils/context'
import TenantUrl from 'components/TenantUrl'
import { useTranslation } from 'react-i18next'
import './style.scss'

const Footer = () => {
  
  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)

  const links = {
    smartcov: [<TenantUrl Privacy />, <TenantUrl Disclaimer />, <TenantUrl Conduct />],
    ihs: [<TenantUrl Privacy />, <TenantUrl Terms />, <TenantUrl Conduct />],
    inova: [<TenantUrl Privacy />, <TenantUrl Terms />, <TenantUrl Conduct />, <TenantUrl Fact />],
    vertis: [<TenantUrl Privacy />, <TenantUrl Terms />, <TenantUrl Conduct />],
    easy: [<TenantUrl Privacy />, <TenantUrl Terms />, <TenantUrl Conduct />]
  }

  return (
    <footer className="Footer">
      <div className="container">
        <address>
          {tenant.footer_name}<br />
          {tenant.address}, {tenant.city},<br />
          {tenant.province} {tenant.postal_code}
        </address>
        <hr />
        <span>
          <i>{t('Phone:')}</i> <TenantUrl Phone />
        </span>
        <span>
          <i>{t('Fax:')}</i> <TenantUrl Fax />
        </span>
        {
          tenant && tenant.slug in links && links[tenant.slug].map(function(link, i){
            return <span key={i}>{link}</span>
          })
        }
      </div>
    </footer>
  )
}

export default Footer