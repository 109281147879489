import React from 'react'
import TenantStr from 'components/TenantStr'
import TenantUrl from 'components/TenantUrl'
import Button from 'components/Buttons/Button'
import Divider from 'components/Divider'
import { DollarIcon, AutoIcon, LiabilityIcon, HelpIcon, QuestionIcon } from './icons'
import { isHccCallCenterHours, getTenantChatType } from 'utils/helpers'
import './style.scss'
import { useTranslation, Trans } from 'react-i18next'

const HomeBlock = ({ tenant }) => {

  const { t } = useTranslation()
  const isInova = tenant && tenant.slug === 'inova'
  const nonEip = tenant && tenant.slug !== 'hcceip' && tenant.slug !== 'inova'

  const openChat = () => {
    const chatType = getTenantChatType(tenant)
    if (chatType === 'olark') window.olark('api.box.expand')
    else if(chatType === 'fresh') window.fcWidget.open()
    else if (chatType === 'cxo') window.cxone('chat', 'openChatWindow');
  }

  return (
    <div className="HomeBlock">
      <h2>{t('Access your auto and property policies online, anytime.')}</h2>
      <p>
        <Trans
          i18nKey="allowsYouToAccess"
          components={{ productName: <TenantStr ProductName />}}
        />
      </p>

      {isInova ? (
        isHccCallCenterHours() ? <p>
          <Trans
            i18nKey="helpSigning"
            components={{ button: <Button onClick={openChat} DataTestId="open-chat"/>}}
          />
        </p> : null
      ) : (
        <p>
          <Trans
            i18nKey="callTenantHelp"
            components={{ tenantName: <TenantStr LegalName />, phone: <TenantUrl Phone />}}
          />
        </p>
      )}

      <Divider Small />

      <h3>
        <Trans
          i18nKey="youCanWithTenant"
          components={{ productName: <TenantStr ProductName />}}
        />
      </h3>
      
      <ul className="clearfix">
        <li className="clearfix">
          <h4>{t('Access Your Billing Information')}</h4>
          <p><DollarIcon /> {t('View your upcoming monthly payments, check your outstanding balance, request your billing schedule by email or mail, or change your account information.')}</p>
        </li>
        <li className="clearfix">
          <h4>{t('Update Your Auto Insurance Policy')}</h4>
          <p><AutoIcon /> 
            <Trans
              i18nKey="easyWayToUpdateMessage"
              components={{ productName: <TenantStr ProductName />}}
            />
          </p>
        </li>
        <li className="clearfix">
          <h4>{t('View Your Liability Slips')}</h4>
          <p><LiabilityIcon /> {t('Download and print your temporary pink slips while you wait for your permanent copies.')}</p>
        </li>
        {isInova && (
          <li className="clearfix">
              <h4>{t('Have another type of change to your car or home policy?')}</h4>
              <p><QuestionIcon /> {t("Select general inquiry, tell us what you need, and we'll get back to you.")}</p>
          </li>
        )}
        {nonEip && (
          <li className="clearfix">
            <h4>{t('Get Help Via Web Chat')}</h4>
            <p><HelpIcon /> {t('Need help? During our business hours, our chat agents are on standby to answer any questions you might have.')}</p>
          </li>
        )} 
      </ul>
    </div>
  )
}

export default HomeBlock