import React from 'react'
import { useTranslation } from 'react-i18next'
const Contact = () => {
  const { t } = useTranslation()
  return (
    <div className="SitePage">
      <div className="container">
        <h2>{t('Activation Re-sent')}</h2>
        <p><strong>{t('Thank you!')}</strong> {t('Please check your email for further instructions.')}</p>
        <p>{t("If the email doesn't appear in your inbox, look in your spam folder or contact us.")}</p>
      </div>
    </div>
  )
}

export default Contact
