import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { formatNumber, titleCase } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { parkedAtNightChoices, vehicleUse } from 'forms/utils/vars'

const UsageConfirm = () => {
  
  const { t } = useTranslation()
  const form = useContext(FormContext)

  const showAnnual = form.data['usage'] === 'pleasure' ||
                     form.data['usage'] === 'commuting' ||
                     form.data['usage'] === 'business'

  const showDaily = form.data['usage'] === 'commuting' ||
                    form.data['usage'] === 'business'

  const showBusiness = form.data['usage'] === 'business'

  const showParked = form.data['parked_overnight']
  
  const showCommuteUsage = form.data['usage'] === 'pleasure'

  let parkedAtNight = parkedAtNightChoices.filter(arr => arr.includes(showParked)).flatMap(arr => arr)
  
  const getUsage = (usage) => {
    const key = vehicleUse.findIndex(innerArray => innerArray.includes(usage));
    return vehicleUse[key][1]
  }
  
  return (
    <ConfirmCard title={t("Vehicle Usage")} slug="vehicle-usage" id={form.data.id}>
      <ul>
        <li>
          <small>{t('Usage')}</small>
          {titleCase(getUsage(form.data['usage']))}
        </li>
        {showAnnual && <li>
          <small>{t('Annual KMs')}</small>
          {formatNumber(form.data['annual_kms'], '0,0')}km
          </li>}
        {showCommuteUsage && <li>
          <small>{t('Commute Vehicle')}</small>
          {form.data['commute_vehicle']}
          </li>}
        {showDaily && <li>
          <small>{t('KMs driven daily to work or school one way')}</small>
          {formatNumber(form.data['daily_kms'], '0,0')}km
          </li>}
        {showBusiness && <li>
          <small>{t('Business Annual KMs')}</small>
          {formatNumber(form.data['business_annual_kms'], '0,0')}km
          </li>}
        {showParked && <li>
            <small>{t('Parked at Night')}</small>
            {parkedAtNight[1]}
          </li>}
      </ul>
    </ConfirmCard>
  )
}

export default UsageConfirm