import React, { useContext } from 'react'
import { parseISO, isAfter, subYears } from 'date-fns'
import classNames from 'classnames'
import { FormContext } from 'utils/context'
import Tile from 'components/Tile'
import Button from 'components/Buttons/Button'
import TriDate from 'forms/fields/TriDate'
import SlideToggle from 'forms/fields/SlideToggle'
import SingleDate from 'forms/licensing/SingleDate'
import TierErrors from 'forms/licensing/TierErrors'
import { titleCase } from 'utils/helpers'
import useLicensing from 'forms/licensing/useLicensing'
import { useTranslation, Trans } from 'react-i18next'

const DriverLicensing = ({ province }) => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)

  const driverLicenceDate = data['first_name'] ? <Trans i18nKey="firstNameLicenceDate" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="driverLicenceDate" />

  const { tier_3, tier_2, tier_1, licenceCancel, licenceClose, licenceChange, updateTraining, eligibleForTraining, hasTraining, errors, clearDates, success } = useLicensing(data['id'], province)

  const clearDisabled = tier_1 === null && tier_2 === null && tier_3 === null

  const fieldProps = { licenceClose, licenceCancel, licenceChange, province }

  let min = new Date()

  const minDate = () => {
    if (tier_2) {
      min = subYears(parseISO(tier_2), 3)
      if (isAfter(parseISO(tier_1), min)) {
        return parseISO(tier_1)
      }
    }
    return min
  }

  const cls = classNames('LicenceTile', {
    'success': success && Object.keys(errors).length < 1,
    'error': Object.keys(errors).length > 0,
  })

  return (
    <fieldset>
      <Tile className={cls}>
        <div className="TileHeader">
          <strong>{driverLicenceDate}</strong>
          <Button onClick={clearDates} Disabled={clearDisabled} DataTestId="clear-dates">{t('Clear all dates')}</Button>
        </div>
        <div className="TileBody">
          <SingleDate tier="tier_1" value={tier_1} {...fieldProps} />
          <SingleDate tier="tier_2" value={tier_2} {...fieldProps} />
          <SingleDate tier="tier_3" value={tier_3} {...fieldProps} />
          <TierErrors errors={errors} province={province} />
        </div>
      </Tile>
      <p>
        {success && Object.keys(errors).length < 1 ?
          t('Successfully added a licence') :
          t('Please continue adding information above')
        }
      </p>
      {eligibleForTraining && (
        <SlideToggle
          name="training"
          label={t('Does the driver have a driver training certificate?')}
          ChangeCallback={updateTraining}
        />
      )}
      {hasTraining && (
        <TriDate
          name="primary_training_date"
          label={t('On what date did this driver receive a driver training certificate?')}
          minDate={minDate()}
          maxDate={new Date()}
        />
      )}
    </fieldset>
  )
}

export default DriverLicensing