import React from 'react'
import { format, eachDayOfInterval, startOfMonth, endOfMonth } from 'date-fns'
import Day from './Day'
import { useTranslation } from 'react-i18next'

const DatePicker = ({ date, set, minDate, maxDate, displayDate }) => {

  const { t } = useTranslation()
  let start = startOfMonth(displayDate)
  let end = endOfMonth(displayDate)

  const displayMonth = () => eachDayOfInterval({ start, end }).map((day, i) => {
    return (
      <Day
        key={format(day, 'dd')}
        minDate={minDate}
        maxDate={maxDate}
        displayDate={displayDate}
        date={date}
        day={day}
        set={set}
      />
    )
  })

  return (
    <div className="DatePicker">
      {[t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')].map(d => (
        <span key={d} className="DateHdr"><small>{d}</small></span>
      ))}
      {displayMonth()}
    </div>
  )
}

export default DatePicker
