import React, { useContext } from 'react'
import { addDays, startOfDay } from 'date-fns'
import Vehicles from 'forms/fields/Vehicles'
import DateChooser from 'forms/fields/DateChooser'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const ReinstateCoverageGetStarted = () => {

  const { t } = useTranslation()
  const { vehicles, policies, coverages } = useContext(AccountContext)
  const { setFieldValue } = useContext(FormContext)

  const cov = coverages.filter(c => c.coverage_code === '16' || 
                                    c.coverage_code === '17' || 
                                    c.coverage_code === 'CMP' || 
                                    c.coverage_code === 'COL' || 
                                    c.coverage_code === 'TPBI')
  

  const setPolicy = value => {
    const vehicle = vehicles.find(v => v.vehicle_id === Number(value))
    setFieldValue('policy', vehicle.policy)
    setFieldValue('full_vehicle_name', vehicle.full_vehicle_name)
  }

  let policyToVehicles = {}
  let coverageToVehicle = {}

  policies.forEach(policy => {
    policyToVehicles[policy.id] = []
  })

  vehicles.forEach(vehicle => {
    coverageToVehicle[vehicle.id] = []
  })

  for (const vehicleID in coverageToVehicle) {
    cov.forEach(c => {
      if (c.vehicle === parseInt(vehicleID)) {
        coverageToVehicle[vehicleID].push(c.coverage_code)
      }
    })
  }

  vehicles.forEach(vehicle => {
    let has16Only = coverageToVehicle[vehicle.id].includes('16')&& !coverageToVehicle[vehicle.id].includes('17')
    let hasCMPOnly = coverageToVehicle[vehicle.id].includes('CMP') && !coverageToVehicle[vehicle.id].includes('COL') && !coverageToVehicle[vehicle.id].includes('TPBI')
    if(has16Only || hasCMPOnly){
      policyToVehicles[vehicle.policy].push([vehicle.vehicle_id, `${vehicle.full_vehicle_name}`]);
    }
  })  

  let finalChoices = []

  for (const policyID in policyToVehicles) {
    if (policyToVehicles[policyID].length > 0) {
      policyToVehicles[policyID].forEach(vehicle => {
        finalChoices.push(vehicle)
      })
    }
  }

  return (
    <fieldset>
      <Vehicles
        name="vehicle_id"
        label={t("Which vehicle would you like to reinstate coverage?")}
        ChangeCallback={setPolicy}
        Stacked
        choices={finalChoices}
      />
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t("When would you like to start to reinstate your coverage?")}
        IncludeYear
      />
    </fieldset>
  )
}

export default ReinstateCoverageGetStarted
