import { useContext } from 'react'
import Tile from 'components/Tile'
import Text from 'forms/fields/Text'
import Radio from 'forms/fields/Radio'
import Select from 'forms/fields/Select'
import { FormContext } from 'utils/context'
import { showField } from 'forms/utils/helpers'
import JasmineError from 'forms/fieldsets/general/JasmineError'
import useVinLookup from 'forms/utils/useVinLookup'
import useVehicleLookup from 'forms/utils/useVehicleLookup'
import AutoIcon from 'utils/icons/Auto'
import QuoteVehicleDetails from './QuoteVehicleDetails'
import { useTranslation } from 'react-i18next'

const VinLookupSubForm = ({ parentData, clearParentFormError }) => {

  const { t } = useTranslation()
  const { data, setFieldValue } = useContext(FormContext)

  const { loading, models, error, newLookup } = useVinLookup()

  const {
    viccYears, viccMakes, viccModels, lookupViccMakes,
    lookupViccModels, viccMakesLoading, viccModelsLoading,
  } = useVehicleLookup(clearParentFormError)

  const hasVin = data['has_vin'] === 'yes'
  const noVin = data['has_vin'] === 'no'

  const hasVehicle = () => {
    const { year, make, slug } = data
    if (year && year !== -1 && make && make !== -1 && slug && slug !== -1) {
      return true
    }
    return false
  }

  const clearError = () => clearParentFormError()
  const lookUp = value => newLookup(value.toUpperCase())
  const setModel = val => setFieldValue('model', val)

  return (
    <>
      <Text name="owner" DefaultValue={parentData.id} Hidden />
      <Radio
        name="has_vin"
        label={t("Do you have the VIN for this vehicle?")}
        ChangeCallback={clearError}
      />
      {hasVin && (
        <>
          <Text
            name="vin"
            label={t("Enter your new vehicle's VIN, we'll look up the year, make and model")}
            Loading={loading}
            ChangeCallback={lookUp}
          />
          {!error && models.length > 1 && (
            <Select
              name="slug"
              label={t("Vehicle Model")}
              choices={models.map(m => [m[1], m[1]])}
              ChangeCallback={setModel}
            />
          )}
          {error ?
            <JasmineError type="VIN" /> :
            data.year && data.make ? (
              <Tile className="VehicleToAdd" Icon={AutoIcon} IconName="AutoIcon">
                <div className="TileHeader">
                  <small className="uppercase">{data.year} {data.make}</small>
                  <p className="secondary-d2 font-p2">
                    {data.slug && data.model !== '-1'? data.model : t('Please select a model above')}
                  </p>
                </div>
              </Tile>
            ) : null}
        </>
      )}
      {noVin && (
        <>
          <Select
            name="year"
            label={t("Year")}
            choices={viccYears}
            ChangeCallback={lookupViccMakes}
          />
          {showField('year', data) && (
            <Select
              name="make"
              label={t("Make")}
              choices={viccMakes}
              ChangeCallback={lookupViccModels}
              Loading={viccMakesLoading}
            />
          )}
          {showField('year', data) && showField('make', data) && (
            <Select
              name="slug"
              label={t("Model")}
              choices={viccModels}
              Loading={viccModelsLoading}
            />
          )}
        </>
      )}
      {hasVehicle() && <QuoteVehicleDetails parentData={parentData} />}
    </>
  )
}

export default VinLookupSubForm