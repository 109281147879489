import React, { useContext } from 'react'
import { parseISO, isBefore, isAfter, isValid } from 'date-fns'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'
import { FormContext } from 'utils/context'
import { translateDate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'

const TriDate = ({ id, name, label, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, HelpCopy, className, KeyPress, minDate, maxDate, cleans, Optional }) => {
  
  const { t } = useTranslation()
  const { setFieldError } = useContext(FormContext)

  const { value, onChange, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, cleans, Optional)

  const year = value ? value.split('-')[0] : ''
  const month = value ? value.split('-')[1] : ''
  const day = value ? value.split('-')[2] : ''

  const dateClean = () => {
    if (minDate) {
      if (isValid(parseISO(value))) {
        if (isBefore(parseISO(value), minDate)) {
          setFieldError(name, t('Cannot be before {{date}}', {date: translateDate(minDate, 'MMM dd, yyyy')}))
        }
      }
      if (maxDate) {
        if (isValid(parseISO(value))) {
          if (isAfter(parseISO(value), maxDate)) {
            setFieldError(name, t('Cannot be after {{date}}', {date: translateDate(maxDate, 'MMM dd, yyyy')}))
          }
        }
      }
    }
  }

  const checkValues = () => {
    if (year) {
      const current = new Date().getFullYear()
      if (year.length < 4 || year.length > 4) setFieldError(name, t('Year must be 4 digits'))
      else if (year < 1900 || year > current) setFieldError(name, t('Year must be after 1900 and before {{date}}', {date:  current + 1}))
    }
    if (month) {
      if (month.length < 2 || month.length > 2) setFieldError(name, t('Month must be 2 digits'))
      else if (month > 12 || month === '00') setFieldError(name, t('Month must be between 01 and 12'))
    }
    if (day) {
      if (day.length < 2 || day.length > 2) setFieldError(name, t('Day must be 2 digits'))
      else if (day > 31 || day === '00') setFieldError(name, t('Day must be between 01 and 31'))
    }
    if (year && month && day) dateClean()
  }

  const yearChange = e => {
    const { value: v } = e.target
    onChange({ target: { value: `${v}-${month}-${day}` } })
  }

  const monthChange = e => {
    const { value: v } = e.target
    onChange({ target: { value: `${year}-${v}-${day}` } })
  }

  const dayChange = e => {
    const { value: v } = e.target
    onChange({ target: { value: `${year}-${month}-${v}` } })
  }

  const yearBlur = e => {
    const { value: v } = e.target
    checkValues()
    onBlur({ target: { value: `${v}-${month}-${day}` } })
  }

  const monthBlur = e => {
    const { value: v } = e.target
    checkValues()
    onBlur({ target: { value: `${year}-${v}-${day}` } })
  }

  const dayBlur = e => {
    const { value: v } = e.target
    checkValues()
    onBlur({ target: { value: `${year}-${month}-${v}` } })
  }

  const classes = classNames('field tri-date', {
    [className]: className,
    'active': active,
    'touched': touched,
    'has-error': error,
  })
  
  const getHelp = () => {
    if (typeof HelpCopy === 'function') {
      return <HelpCopy />
    } else {
      return <span className="help-copy">{HelpCopy}</span>
    }
  }

  return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
      <Label label={label} name={name} />
      <div className="field-wrapper">
        <div className="clearfix">
          <input
            id={id}
            name="day"
            value={day}
            type="number"
            placeholder={t("DD")}
            onChange={dayChange}
            onBlur={dayBlur}
            maxLength="2"
            // onFocus={onFocus}
            // onKeyPress={KeyPress ? e => KeyPress(e, name) : () => {}}
          /><span className="delimeter">-</span>
          <input
            name="month"
            value={month}
            type="number"
            placeholder={t("MM")}
            onChange={monthChange}
            onBlur={monthBlur}
            maxLength="2"
            // onFocus={onFocus}
            // onKeyPress={KeyPress ? e => KeyPress(e, name) : () => { }}
          /><span className="delimeter">-</span>
          <input
            name="year"
            value={year}
            type="number"
            placeholder={t("YYYY")}
            onChange={yearChange}
            onBlur={yearBlur}
            maxLength="4"
            // onFocus={onFocus}
            // onKeyPress={KeyPress ? e => KeyPress(e, name) : () => { }}
          />
        </div>
      </div>
      <FieldError error={error} name={name} />
      {HelpCopy && getHelp()}
    </div>
  )
}

export default TriDate