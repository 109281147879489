import { useEffect } from 'react'

const FreshchatWrapper = () => {

  useEffect(() => {

    const src = `(function (d, w, c) { if(!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "98f8e8fbdb2310cbdb27329f99a06d2ee74c03cc"); s.setAttribute("data-bot-hash", "78ee3a423f3c5ce8cce22e3062a27519547ecff2"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "us"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } }) (document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return ; } }, function(successResponse) { }, function(errorResponse) { }); });`

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = src

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, []) // eslint-disable-line

  return null
}

export default FreshchatWrapper