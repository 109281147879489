import { Link } from 'react-router-dom'
import classNames from 'classnames'
import TenantComponent  from 'components/TenantComponent'
import LoadingSpinner from 'components/LoadingSpinner'

import './style.scss'

const Tile = ({ children, Warning, White, className, Icon, IconName, SmallIcon, Loading, LinkUrl, onClick, LinkDescription, LinkTarget, TestId }) => {

  const cls = classNames('Tile', {
    'Warning': Warning,
    'White': White,
    'HasIcon': Icon,
    'HasSmallIcon': SmallIcon,
    [className]: className,
  })

  const content = Loading ?
    <div className="LoadingHolder"><LoadingSpinner /></div> :
    <>
      {Icon &&
        <div className={SmallIcon ? 'TileIcon Small' : 'TileIcon'}>
          {Icon && IconName ? (
            <TenantComponent element={<Icon />} elementName={IconName} />
          ) : (
            <Icon />
          )}
        </div>
      }
      {children}
    </>

  if (onClick) {
    return (
      <button className={cls}>
        {content}
      </button>
    )
  }

  return LinkUrl ? (
    <Link to={LinkUrl} className={cls} aria-label={LinkDescription ? LinkDescription : content} target={LinkTarget ? LinkTarget : ""} data-testid={TestId}>
      {content}
    </Link>
  ) : (
    <div className={cls} data-testid={TestId}>
      {content}
    </div>
  )
}

export default Tile