import { useContext } from 'react'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './style.scss'

const HeaderImage = () => {

  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)

  return (
    <div className="HeaderImage">
      <img src={tenant.GenericHdr} alt="Masthead" />
      <div className="Promo">
        <div className="container">
          <h2>{t('Your Insurance.')}</h2>
          <h3>{t('On Your Schedule.')}</h3>
        </div>
      </div>
    </div>
  )
}

export default HeaderImage