import React, { useEffect, useContext, useRef } from 'react'
import TextArea from 'forms/fields/TextArea'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const InquiryTypeTextArea = props => {

  const { t } = useTranslation()
  const { data, setFieldValue } = useContext(FormContext)

  const TypeFromData = data['inquiry_type']

  const previousValue = useRef()

  const type = props.InquiryType

  let label = ''
  let placeholder = ''
  let HelpCopy = ''

  if (type === 'other') {
    label = t('Please provide more information below')
    HelpCopy = t('Please do not include any personal information such as credit card information or bank account details.')
  } else if (type === 'change_payment_date') {
    label = t('Please provide the date you wish to change your payment date to. Please note that we cannot guarantee the date will be available.')
    placeholder = t('E.g. the 1st or 5th of the month')
    HelpCopy = t('We require approximately 7 - 10 business days notice to make the changes. Please ensure you have the funds in your current account for your next regularly scheduled payment.')
  }

  useEffect(() => {
    
    if (previousValue.current !== undefined) {
      if (previousValue.current !== TypeFromData) {
        setFieldValue('details', null)
      }
    }
    
    previousValue.current = TypeFromData
  
  }, [TypeFromData, setFieldValue])

  const show = type === 'other' || type === 'change_payment_date'

  return show ? (
    <TextArea {...props} label={label} placeholder={placeholder} HelpCopy={HelpCopy} />
  ) : null
}

export default InquiryTypeTextArea