import { useContext } from 'react'
import Masthead from 'components/Masthead'
import LogoutButton from 'components/LogoutButton'
import Link from 'components/Buttons/Link'
import ArrowIcon from 'utils/icons/Arrow'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const AuthedNav = ({ closeModal }) => {

  const { t } = useTranslation()
  const { accountPolicies } = useContext(AppContext)
  
  const PolicyType = {
    auto: t("AUTO"),
    hab: t("HAB")
  }

  return (
    <>
      <Masthead id="firstFocus" />
      <Link onClick={closeModal} to="/account" DataTestId="policy-summary">{t('Policy Summary')} <ArrowIcon /></Link>
      {accountPolicies.map(pol => (
        <Link
          to={`/account/${pol.number}`}
          key={pol.number}
          onClick={closeModal}
          className="NavPolLink"
          Arrow
          DataTestId={`policy--${pol.number}`}
        >
          {pol.number} <small>({PolicyType[pol.type]})</small>
        </Link>
      ))}
      <hr />
      <Link onClick={closeModal} to="/account/requests" className="Highlight" DataTestId="make-request">
        {t('Make a Request')} <ArrowIcon />
      </Link>
      <Link onClick={closeModal} to="/account/requests/previous" className="Highlight" DataTestId="previous-request">{t('Previous Requests')}<ArrowIcon /></Link>
      <hr />
      <Link onClick={closeModal} to="/account/profile" DataTestId="profile">{t('Profile')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/account/claims" DataTestId="claims">{t('Claims')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/account/help" DataTestId="help">{t('Help')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/account/contact" DataTestId="contact">{t('Contact')}<ArrowIcon /></Link>
      <Link onClick={closeModal} to="/account/disclosures" DataTestId="disclosures">{t('Disclosures')}<ArrowIcon /></Link>
      <LogoutButton>{t('Logout')}</LogoutButton>
    </>
  )
}

export default AuthedNav