import React from 'react'
import SimpleForm from 'forms/SimpleForm'
import TenantStr from 'components/TenantStr'
import TenantUrl from 'components/TenantUrl'
import Text from 'forms/fields/Text'
import { emailAddress } from 'forms/utils/cleans'
import { useTranslation, Trans } from 'react-i18next'
const ResendActivation = ({ history }) => {
  const { t } = useTranslation()
  const resendSuccess = res => {
    if (res) {
      history.push('/activation-resent')
    }
  }

  return (
    <div className="SitePage">
      <div className="container">
        <h2>{t('Resend Activation')}</h2>
        <p><strong>{t('This account has not been activated.')}</strong></p>
        <p>
          <Trans
            i18nKey="didNotActivateMsg"
            components={{ productName: <TenantStr ProductName />, s: <strong/> }}
          />
        </p>
        <p>{t("If the email doesn't appear in your inbox, look in your spam folder. If you still did not receive your email, or it has been over 24 hours, please fill in your email address below and we can send you a new activation email.")}</p>
        <p>
          <Trans
            i18nKey="callOrEmailTenantMsg"
            components={{ phone: <TenantUrl Phone />, email: <TenantUrl Email />}}
          />
        </p>
        <SimpleForm SubmitUrl="/api/users/resend/activation/" SubmitLabel={t('Submit')} SuccessCallback={resendSuccess}>
          <Text
            name="email"
            placeholder="user@email.ca"
            cleans={[emailAddress]}
          />
        </SimpleForm>
      </div>
    </div>
  )
}

export default ResendActivation
