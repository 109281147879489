import React from 'react'
import { prettyDate } from 'utils/helpers'
import { provinceTiers } from 'forms/licensing/vars'

const LicensingConfirm = ({ licences, province }) => {
  
  const getType = (type, tier) => {
    try {
      const types = provinceTiers[province]
      return types[tier]
    } catch (e) {
      throw new Error(`No licensingtiers set for ${province}`)
    }
  }

  return (
    <div>
      {licences.map(l =>
        <div key={l.licence_type} className="LicenceConfirm">
          {l.tier_1 &&
            <>
              <strong>{getType(l.licence_type, 'tier_1')}</strong> {prettyDate(l.tier_1)}<br />
            </>
          }
          {l.tier_2 &&
            <>
              <strong>{getType(l.licence_type, 'tier_2')}</strong> {prettyDate(l.tier_2)}<br />
            </>
          }
          {l.tier_3 &&
            <>
              <strong>{getType(l.licence_type, 'tier_3')}</strong> {prettyDate(l.tier_3)}<br />
            </>
          }
        </div>
      )}
    </div>
  )
}

export default LicensingConfirm