import React, { useContext } from 'react'
import LoginForm from 'components/LoginForm'
import HomeBlock from './HomeBlock'
import Button from 'components/Buttons/Button'
import Link from 'components/Buttons/Link'
import HeaderImage from 'components/HeaderImage'
import TenantStr from 'components/TenantStr'
import Divider from 'components/Divider'
import LogoutButton from 'components/LogoutButton'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
const Home = () => {
  const { t } = useTranslation()
  const { tenant, showMaintenance, authed } = useContext(AppContext)

  return (
    <div className="SitePage HomePage">
      <HeaderImage />
      <div className="container">
        {showMaintenance ? null : authed ? (
          <div>
            <p>{t('You are currently logged in.')}</p>
            <Link to="/account" Pill>{t('Account')}</Link>{' '}
            <LogoutButton />
          </div>
        ) : (
          <div className="LoginOrSignup">
            <div className="LoginOrSignupToggle">
              <Button DataTestId="login">{t('Login')}</Button>
              <Link to="/signup">{t('Create an Account')}</Link>
            </div>
            <div>
              <div className="HomeActionBlock HomeLoginBlock">
                <h2 className="text-center"> 
                  <Trans
                    i18nKey="logInTenant"
                    components={{ productName: <TenantStr ProductName />, s: <strong /> }}
                  />
                </h2>
                <LoginForm />
              </div>
            </div>
          </div>
        )}
        <Divider Small />
        <HomeBlock tenant={tenant} />
      </div>
    </div>
  )
}

export default Home