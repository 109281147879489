import React, { useContext, useEffect, useState } from 'react'
import { FormContext, AccountContext } from 'utils/context'
import { addDays, startOfDay, differenceInMonths } from 'date-fns'
import PoliciesPk from 'forms/fields/PoliciesPk'
import DateChooser from 'forms/fields/DateChooser'
import Radio from 'forms/fields/Radio'
import { noLiabilityInsuranceDuration } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const GetStarted = () => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  const { policies } = useContext(AccountContext)

  const [policyCarrier, setPolicyCarrier] = useState(null)
  const [policyProvince, setPolicyProvince] = useState(null)

  const liabilityCoverageCallback = value => {
    if (value === true) {
      setFieldValue('no_liability_insurance_duration', null)
      clearFieldError('no_liability_insurance_duration')
    }
  }

  useEffect(() => {
    const policy = policies.find(policy => policy.id === Number(data['policy']))
    if (policy) {
      setPolicyCarrier(policy.carrier_code)
      setPolicyProvince(policy.province)
    }
  }, [data['policy']]) // eslint-disable-line

  return (
    <fieldset>
      <PoliciesPk
        name="policy"
        label={t("To which policy would you like to add a vehicle?")}
        AutoOnly
      />
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t("When do you want to add this vehicle?")}
        IncludeYear
        HelpCopy={t("If this is a new vehicle, this date should be the date you legally take possession of the vehicle. If this is an existing vehicle, new to this policy, the date should be the day you want to start coverage.")}
      />
      <DateChooser
        maxDate={addDays(new Date(), 30)}
        name="purchase_date"
        label={t("What date did you purchase the vehicle?")}
        IncludeYear
        HelpCopy={t("This is the date you legally took possession of this vehicle.")}
      />
      { 
        policyCarrier && policyProvince && 
        policyCarrier in noLiabilityInsuranceDuration && 
        policyProvince in noLiabilityInsuranceDuration[policyCarrier] &&
        data["purchase_date"] && differenceInMonths(new Date(data["effective_date"]), new Date(data["purchase_date"])) >= noLiabilityInsuranceDuration[policyCarrier][policyProvince] &&
        <>
          <Radio
            name="liability_coverage"
            label={t("Does this vehicle currently have liability coverage?")}
            choices={[[true, t('Yes')], [false, t('No')]]}
            ChangeCallback={liabilityCoverageCallback}
          />
          {
            data["liability_coverage"] === false &&
            <Radio
              name="no_liability_insurance_duration"
              label={t("How long has the vehicle been without liability insurance?")}
              choices={[
                [
                  `less_than`,
                  t('lessThanXMonths', {count: noLiabilityInsuranceDuration[policyCarrier][policyProvince]})
                ], 
                [
                  `greater_equal`,
                  t('greaterThanXMonths', {count: noLiabilityInsuranceDuration[policyCarrier][policyProvince]})
                ]
              ]}
            />
          }
        </>
      }

    </fieldset>
  )
}
export default GetStarted