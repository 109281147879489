import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'utils/context'
// import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import { useTranslation } from 'react-i18next'

import './style.scss'

const Masthead = ({ id }) => {

  const { authed, tenant, setDialogOpen } = useContext(AppContext)
  const { i18n } = useTranslation()

  return (
    <Link id={id} to={authed ? '/account' : '/'} className={authed ? 'Masthead authed' : 'Masthead'} onClick={() => {setDialogOpen('')}}>
      <h1>
        <span className="sr-only"><TenantStr LegalName/></span>
        {tenant && i18n.language === 'en' && tenant.LogoEN}
        {tenant && i18n.language === 'fr' && (tenant.LogoFR || tenant.LogoEN)}
      </h1>
    </Link>
  )
}

export default Masthead