import { useContext } from 'react'
import Label from 'forms/fields/Label'
import { AccountContext } from 'utils/context'

const QuestionSet = ({ questions }) => {

  const { currentRequest, updateCurrentRequest } = useContext(AccountContext)

  return Object.keys(questions).map((q, i) =>
    <Label
      key={i}
      secondaryLabel
      htmlFor={q}
      className={currentRequest.lineitemUrl === q ? 'checked' : null}
    >
      <input
        id={q}
        type="radio"
        name="request_type"
        value={currentRequest.lineitemUrl === q}
        checked={currentRequest.lineitemUrl === q}
        onChange={() => updateCurrentRequest({ requestType: currentRequest.requestType, lineitemUrl: q })}
      /> <span>{questions[q].label}</span>
    </Label>
  )
}

export default QuestionSet