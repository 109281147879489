import { parseISO } from 'date-fns'
import TrashIcon from 'utils/icons/Trash'
import Button from 'components/Buttons/Button'
import { cancellationChoices } from 'forms/utils/vars'
import './style.scss'
import { translateDate } from 'utils/helpers'


const CancellationItems = ({ items = [], title = '', onDelete, Placeholder }) => {

  const getTitle = val => {
    const item = cancellationChoices.find(x => x[0] === val)
    return item[1]
  }

  const formatDate = str => {
    const date = parseISO(str)
    return translateDate(date, 'MMM d, yyyy')
  }

  return (
    <div id="array-items" className="ArrayItems CancellationItems">
      {items.length > 0 ? items.map((x, i) =>
        <div key={i} className="ArrayItem" data-testid={`history-items--${i}`}>
          <div>
            {getTitle(x[title])}
            <small className="CancellationDates">{formatDate(x['cancellation_date'])} {x['insurance_reinstatement_date'] ?  `- ${formatDate(x['insurance_reinstatement_date'])}` : ''}</small>
          </div>
          <Button onClick={e => onDelete(e, x.id)} DataTestId="delete">
            <TrashIcon />
          </Button>
        </div>
      ) : <i className="ArrayPlaceholder">{Placeholder}</i>}
    </div>
  )
}

export default CancellationItems