import React from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import VehicleReplaceGetStarted from 'forms/fieldsets/vehicle/VehicleReplaceGetStarted'
import VehicleReplaceRemoveReason from 'forms/fieldsets/vehicle/VehicleReplaceRemoveReason'
import VehicleLookup from 'forms/fieldsets/vehicle/VehicleLookup'
import OwnerDriver from 'forms/fieldsets/vehicle/OwnerDriver'
import VehicleDetails from 'forms/fieldsets/vehicle/VehicleDetails'
import VehicleReplaceVehicleUsage from 'forms/fieldsets/vehicle/VehicleReplaceVehicleUsage'
import Eligibility from 'forms/fieldsets/vehicle/Eligibility'
import CoverageNew from 'forms/fieldsets/vehicle/CoverageNew'
import LeasedOrFinanced from 'forms/fieldsets/vehicle/LeasedOrFinanced'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import VehicleReplaceConfirm from 'forms/fieldsets/vehicle/VehicleReplaceConfirm'
import { useTranslation } from 'react-i18next'
import './styles/vehicle-replace.scss'

const VehicleReplace = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="vehicle-replace" />
      <VehicleReplaceGetStarted path="get-started" />
      <VehicleReplaceRemoveReason path="remove-reason" />
      <VehicleLookup path="vehicle-lookup" />
      <OwnerDriver path="owner-and-driver" />
      <VehicleDetails path="vehicle-details" />
      <VehicleReplaceVehicleUsage path="vehicle-usage" />
      <Eligibility path="eligibility" />
      <CoverageNew path="coverage" />
      <LeasedOrFinanced path="financing-details" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('When you add a vehicle you may find that your use of your existing vehicles changes as well. Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <VehicleReplaceConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default VehicleReplace