import { useContext } from 'react'
import { AppContext } from 'utils/context'
import { Link } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

const SignupError = () => {
  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)
  return (
    <>
      <p>{t("There was a problem registering with this email and policy number. It could be due to:")}</p>
      <ul>
        <li>{t("Policy number or email are already in use")}</li>
        <li>{t("Policy number and email do not match any policies we have on file")}</li>
      </ul>
      <p>
        <Trans
          i18nKey="passwordResetLink"
          components={{
            // eslint-disable-next-line
            passwordLink: <Link className="Link" to="/forgot-password"/>
          }}
        />
      </p>
      <p>
        <Trans
          i18nKey="difficultyContactUs"
          components={{
            // eslint-disable-next-line
            a1: <a className = "Link" href={tenant.contact_us_url}/>
          }} 
        />
      </p>
    </>
  )
}

export default SignupError