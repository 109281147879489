import { useContext, useState, useEffect } from 'react'
import { AccountContext, FormContext } from 'utils/context'
import AllDrivers from 'forms/fields/AllDrivers'
import Policies from 'forms/fields/Policies'
import Text from 'forms/fields/Text'
import { useTranslation } from 'react-i18next'
const RenewalDetails = () => {
  const { t } = useTranslation()
  const { policies } = useContext(AccountContext)
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  
  const [displayReasons, setDisplayReasons] = useState([])

  let hasAuto = false

  for (let i = 0; i < policies.length; i++) {
    if (policies[i].lob === 'auto') {
      hasAuto = true
      break
    } 
  }

  const requesterCallback = value => {
    if (value === 'other') {
      setFieldValue('requester_other', null)
      clearFieldError('requester_other')
    }
  }

  const reasonsToStay = [
   {
      carriers: {
        "GA": "Aviva",
        "GA2": "Aviva",
        "TRG": "Traders",
        "TRG2": "Traders",
        "SAY": "Scottish and York",
        "SR": "Scottish and York"
      },
      title: t("- Aviva's Claims Satisfaction Guarantee"),
      listOfReasons: [
        {
          value: t('If you are not satisfied with the service you received during the settlement of a covered claim and Aviva is unable to remedy the situation, they will pay you an amount equal to the annual premium of your policy at the time of loss, in addition to the settlement amount you receive for your claim.'),
          type: "item"
        }
      ],
      lobs: ["auto", "hab"]
    },
    {
      carriers: {
        "DOC": "Dominion of Canada",
        "DOC2": "Travelers",
        "CIC": "Chieftain Insurance"
      },
      title: t('Reasons to stay: Single Pay Discount - Auto Only'),
      listOfReasons: [
        {
          value: t('If you pay in full, you receive 5% off of the base rate.'),
          type: "item"
        }
      ],
      lobs: ["auto"]
    },
    {
      carriers: {
        "GORE": "Gore Mutual"
      },
      title: t('Reasons to stay: OPCF 20/27 - Auto'),
      listOfReasons: [
        {
          value: t('Packaged endorsement free of charge. Covers you for a rental car (due to a claim) and physical damage on that rental car (even if you rent a vehicle anywhere in CAN/USA).'),
          type: "item"
        }
      ],
      lobs: ["auto"]
    },
    {
      carriers: {
        "6494": "Hagerty"
      },
      title: t('Reasons to stay'),
      listOfReasons: [
        {
          value: t('Hagerty is offering a new coverage at no additional cost Guaranteed Value Plus. Having Guaranteed Value Plus will provide you a guaranteed value settlement amount in the case of a total loss, with no depreciation. In addition, the guaranteed value plus provides; no appraisal required, $750 spare parts coverage, $500 car covers coverage, deductible waiver for glass repairs, $2000 for reward and recovery assistance, automatic new ride coverage for 14 days for up to $50,000 vehicle value.'),
          type: "item"
        }
      ],
      lobs: ["auto"]
    },
    {
      carriers: {
        "HAL": "Intact Insurance",
        "HAL3": "Intact Insurance",
        "WU": "Intact Insurance",
      },
      title: t('Reasons to stay'),
      listOfReasons: [
        {
          value: t('24/7 Claims Guarantee'),
          type: "subheader"
        },
        {
          value: t('Intact will respond within 30 minutes of the claims call, or you will receive up to $1000 of your premiums back.'),
          type: "item"
        },
        {
          value: t('Tire Blowout Protector'),
          type: "subheader"
        },
        {
          value: t('Free with All Perils/Collision coverage - payout up to $200 to repair or replace a tire if damaged/destroyed by a blowout, no deductible.'),
          type: "item"
        },
        {
          value: t('Contents Damage by Collision'),
          type: "subheader"
        },
        {
          value: t('Will pay up to $1000 to repair or replace contents in the vehicle if damaged or destroyed by collision or upset. Will only pay for loss or damage that exceeds the deductible amount.'),
          type: "item"
        }
      ],
      lobs: ["auto"]
    },
    {
      carriers: {
        "WAWA": "Wawanesa"
      },
      carrierCodes: ["WAWA"],
      title: t('Reasons to stay: Paws - Auto'),
      listOfReasons: [
        {
          value: t('Coverage available up to $2000 per pet, per accident for injuries as a result of an accident.'),
          type: "item"
        }
      ],
      lobs: ["auto"]
    },
    {
      carriers: {
        "ECON": "Economical"
      },
      title: t('Reasons to stay - Identity Theft Protection'),
      listOfReasons: [
        {
          value: t('$10,000 Identity Theft protection Free of Charge.'),
          type: "item"
        }
      ],
      lobs: ["hab"]
    },
    {
      carriers: {
        "HAL": "Intact Insurance",
        "HAL3": "Intact Insurance",
        "WU": "Intact Insurance",
      },
      title: t('Reasons to stay'),
      listOfReasons: [
        {
          value: t('24/7 Claims Guarantee'),
          type: "subheader"
        },
        {
          value: t('Intact will respond within 30 minutes of the claims call, or you will receive up to $1000 of your premiums back.'),
          type: "item"
        },
        {
          value: t('Travel Well'),
          type: "subheader"
        },
        {
          value: t("Worldwide emergency medical coverage for an insured spouse, children and grandchildren. $5 million limit - Covers multiple trips up to 30 days and doesn't count as a claim."),
          type: "item"
        }
      ],
      lobs: ["hab"]
    },
    {
      carriers: {
        "PEM": "Pembridge",
        "PAF": "Pafco",
        "PERT": "Perth Insurance"
      },
      title: t('Reasons to stay - Home Warranty'),
      listOfReasons: [
        {
          value: t('Included standard - This endorsement provides coverage up to $2,000 per occurrence or $10,000 per policy term for repair or replacement of the systems and components of a home (central heating and AC, electrical system, water heater, emergency plumbing, appliances, etc.) Deductible is $100.'),
          type: "item"
        }
      ],
      lobs: ["hab"]
    }
  ]

  useEffect(() => {
    if (data['renewing_policies']) {
      const carrierCodes = {}
      
      data['renewing_policies'].forEach((id) => {
        const policy = policies.find(policy => id === policy.policy_id)
        if (policy.lob in carrierCodes) {
          carrierCodes[policy.lob].add(policy.carrier_code)
        } else {
          carrierCodes[policy.lob] = new Set([policy.carrier_code])
        }
      })
      
      const displayReasonsAuto = []
      const displayReasonsHab = []
      
      reasonsToStay.forEach((reason) => {
        const carrierNames = new Set()
        const containedLobs = new Set()

        reason.lobs.forEach((lob) => {
          if (lob in carrierCodes) {
            containedLobs.add(lob)
            const intersections = Array.from(carrierCodes[lob]).filter(code => code in reason.carriers)
            if (intersections.length > 0) {
              intersections.forEach((carrierCode) => {
                carrierNames.add(reason.carriers[carrierCode])
              })
            }
          }
        })
        if (carrierNames.size > 0) {
          const newReason = structuredClone(reason);
          newReason["carrierNames"] = Array.from(carrierNames)
          if (containedLobs.has('auto')) {
            displayReasonsAuto.push(newReason) 
          } else {
            displayReasonsHab.push(newReason) 
          }
        }
      })
      // auto needs to be shown first before hab
      const newDisplayReasons = displayReasonsAuto.concat(displayReasonsHab)
      setDisplayReasons(newDisplayReasons)
    }
    

  }, [data['renewing_policies']]) // eslint-disable-line

  return (
    <fieldset>
      {
        hasAuto &&
        <AllDrivers
          name="requester"
          label={t('Who is making the inquiry?')}
          ChangeCallback={requesterCallback}
          Stacked
          other
        />
      }
      
      {
        (data["requester"] === "other" || hasAuto === false)&&
        <Text
          name="requester_other"
          label={(hasAuto) ? t('What is the name of the person making the inquiry?') : t('Who is making the inquiry?')}
        />
      }
      <Policies
        name="renewing_policies"
        label={t('Which of your policies are you inquiring about?')}
        AllowMultiple
        DisplaySingle
      />

      <div>
        {
          (displayReasons.length) > 0 &&
          <h3>{t('Reasons to stay with your current insurance company.')}</h3>
        }
        {
          displayReasons.map((reason, i) => {
            return (
              <div className="reason-to-stay"key={i}>
                <span><b>{reason.carrierNames.join(", ")}</b> {reason.title}</span>
                <ul>
                  {
                    reason.listOfReasons.map((item, j) => {
                      if (item.type === "subheader") {
                        return (
                          <span key={j}><b>{item.value}</b></span>
                        )
                      } else if (item.type === "item") {
                        return (
                          <li key={j}>{item.value}</li>
                        )
                      }
                    })
                  }
                </ul>
              </div>
            )
          })
        }
      </div>

      <div>
        <h3>{t('Reasons to stay with us, your broker.')}</h3>
        <span><b>{t('Broker Advantage:')}</b></span>
        <ul>
          <li><b>{t('Brokerage Model')}</b> - {t("We do the shopping for you as we work with Canada's leading insurance companies.")}</li>
          <li><b>{t('24/7 Claims Service')}</b> - {t('Make a claim any time.')}</li>
          <li><b>{t('Claims Counselling')}</b> - {t("We'll be there to help you understand the impact of a claim before submitting it to the insurance company")}.</li>
          <li><b>{t('Immediate Service')}</b> - {t("Each licensed broker has access to your file, and they can process your request for you. You don't have to wait for a specific broker to call you back!")}</li>
        </ul>
      </div>
      
    </fieldset>
  )
}

export default RenewalDetails