import React, { useContext } from 'react'
import { AccountContext, AppContext } from 'utils/context'
import { regionChoices } from 'utils/vars'
import { useTranslation, Trans } from 'react-i18next'

const TenantUrl = ({ Phone, Fax, Email, Contact, Terms, Privacy, Disclaimer, Conduct, Fact, Appointment, Disclosure}) => {
const { tenant } = useContext(AppContext)
const { contact } = useContext(AccountContext)
const { t, i18n } = useTranslation()

  let email;
  let telephone;
  let url;
  let label;

  if (tenant) {
    if (Email) {
      label = tenant.email
      email = tenant.email
    }
    else if (Phone) {
      if (tenant.phone_numbers && tenant.phone_numbers.default) {
        label = tenant.phone_numbers.default.phone
        telephone = tenant.phone_numbers.default.phone
      }
    }
    else if (Fax) {
      if (tenant.phone_numbers && tenant.phone_numbers.default) {
        label = tenant.phone_numbers.default.fax
        telephone = tenant.phone_numbers.default.fax
      }
    }
    else if (Contact) {
      label = t("contact us")
      url = tenant.contact_us_url
    }
    else if (Terms) {
      label = tenant.tc_label
      url = tenant.tc_link
    }
    else if (Privacy) {
      label = tenant.privacy_label
      url = tenant.privacy_link
    }
    else if (Disclaimer) {
      label = tenant.disclaimer_label
      url = tenant.disclaimer_link
    }
    else if (Conduct) {
      label = tenant.conduct_label
      url = tenant.conduct_link
    }
    else if (Disclosure) {
      label = tenant.disclosure_label
      url = tenant.disclosure_link
    }
    else if (Fact) {
      label = tenant.fact_label
      url = tenant.fact_link
    }
    else if (Appointment) {
      const contactProvince = (contact && regionChoices[contact.province]) || 'ontario'
      const accountPath = contact ? '/account' : ''

      const appointmentUrls = {
        inova: 
          <p className="InlineButtons">
            <Trans
              i18nKey="inova::appointmentSchedule" 
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href={i18n.language === 'fr' ? `https://www.inovainc.ca/fr/${contactProvince}/rendez-vous/` : `https://inovainc.ca/${contactProvince}/appointment/`} target="_blank" rel="noreferrer"/>,
                // eslint-disable-next-line
                a2: <a className="Btn Pill Small" href={`${accountPath}/letsconnect`}/>
              }}
            />
          </p>,
        smartcov: 
          <p className="InlineButtons">
            <Trans
              i18nKey="smartcov::appointmentSchedule" 
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href={'https://www.hubsmartcoverage.ca/appointment/'} target="_blank" rel="noreferrer"/>,
                // eslint-disable-next-line
                a2: <a className="Btn Pill Small" href={`${accountPath}/letsconnect`}/>
              }}
            />
          </p>,
        ihs:
          <p className="InlineButtons">
            <Trans
              i18nKey="ihs::letsConnect" 
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href={`${accountPath}/letsconnect`}/>
              }}
            />
          </p>,
        cis:
          <p className="InlineButtons">
            <Trans
              i18nKey="cis::letsConnect" 
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href={`${accountPath}/letsconnect`}/>
              }}
            />
          </p>,
        hub_bpo:
          <p className="InlineButtons">
            <Trans
              i18nKey="hub_bpo::letsConnect" 
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href={`${accountPath}/letsconnect`}/>
              }}
            />
          </p>,
      }
      if (tenant.slug in appointmentUrls) {
        return appointmentUrls[tenant.slug]
      }
      return ''
    }
  }

  if (email) {
    return <a className="Link" href={`mailto:${email}`} tabIndex="-1">{label}</a>
  }
  if (telephone) {
    return <a className="Link" tabIndex="-1" href={`tel:${telephone}`}>{label}</a>
  }
  if (url) {
    return <a className = "Link" href={url}>{label}</a>
  }
  return ''
}

export default TenantUrl