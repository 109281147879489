import MultiPageForm from 'forms/MultiPageForm'
import Delivery from 'forms/fieldsets/billing/Delivery'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'

const PaymentSchedule = () => (
  <MultiPageForm>
    <Delivery path="delivery" />
    <PreferredContact path="preferred-contact" />
  </MultiPageForm>
)

export default PaymentSchedule