import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const FinancingConfirm = () => {
  
  const { t } = useTranslation()
  const form = useContext(FormContext)

  const financing = form.data['financing_type']
  const isLeased = financing === 'leased'
  const isFinanced = financing === 'financed'
  const isNeither = financing === 'neither'
  
  return (
    <ConfirmCard title={t("Financial Interest")} slug="financing-details" id={form.data.id}>
      <ul>
        <li>
          {(isLeased || isFinanced) && (
            <>
              {isLeased ?
                <small>{t('Leasing Company')}</small> :
                <small>{t('Financing Company')}</small>
              }
              {form.data['other_company'] ?
                <address>
                  {form.data['other_company']}<br />
                  {form.data['company_unit_number'] ? `${form.data['company_unit_number']} - ${form.data['company_street_number']} ${form.data['company_street_name']}` : `${form.data['company_street_number']} ${form.data['company_street_name']}`}<br />
                  {`${form.data['company_city']}, ${form.data['company_province']}`}<br />
                  {form.data['company_postal_code']}
                </address>
              : <address>
                  {form.data['financing_company']}<br />
                  {form.data['financing_address']}<br />
                  {form.data['financing_municipality']}, {form.data['financing_province']}<br />
                  {form.data['financing_postal_code']}
                </address>
              }
            </>
          )}
          {isNeither && (
            <p>{t('Neither leased nor financed')}</p>
          )}
        </li>
      </ul>
    </ConfirmCard>
  )
}

export default FinancingConfirm