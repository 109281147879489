import React, { useContext } from 'react'
import Text from 'forms/fields/Text'
import PasswordCopy from 'components/PasswordCopy'
import { uppercaseChars, number } from 'forms/utils/cleans'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const DoublePassword = ({ pw1, pw2, New }) => {
  
  const { t } = useTranslation()
  const { data, setFieldError } = useContext(FormContext)

  const PasswordChecker = value => {
    const first = data[pw1]
    const second = data[pw2]
    if (first && second) {
      if (first !== second) {
        setFieldError(pw2, t('Passwords do not match'))
      }
    }
  }

  return (
    <>
      <Text
        name={pw1}
        label={New ? t('New Password') : t('Password')}
        type="password"
        minLength={8}
        cleans={[number, uppercaseChars]}
        HelpCopy={PasswordCopy}
        BlurCallback={PasswordChecker}
        autocomplete="new-password"
      />
      <Text
        name={pw2}
        label={New ? t('Confirm New Password') : t('Confirm Password')}
        type="password"
        minLength={8}
        cleans={[number, uppercaseChars]}
        BlurCallback={PasswordChecker}
        autocomplete="new-password"
      />
    </>
  )
}

export default DoublePassword