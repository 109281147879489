import React, { useContext, useEffect } from 'react'
import Radio from 'forms/fields/Radio'
import TextArea from 'forms/fields/TextArea'
import ArrayRadio from 'forms/fields/ArrayRadio'
import HistoryItems from './HistoryItems'
import SubForm from 'forms/SubForm'
import SimpleForm from 'forms/SimpleForm'
import DriverHistorySubForm from './DriverHistorySubForm'
import { AccountContext, FormContext } from 'utils/context'
import { delRequest } from 'utils/requests'
import { driverHistoryAdditionalQuestionsProvinces } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const DriverHistory = () => {
  
  const { t } = useTranslation()
  const { lineitemProvince } = useContext(AccountContext)
  const { data, setFieldValue, setFieldError, clearFieldError } = useContext(FormContext)
  
  const driver_name = data['first_name']

  const NoneCallback = value => {
    if (value === 'none') {
      if (data['suspensions'].length > 0) {
        setFieldError('has_suspensions', t('Please remove all suspensions first'))
        setFieldValue('has_suspensions', 'one_or_more')
      }
    }
  }

  const deleteSuspension = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    const request = delRequest(`/api/lineitems/suspensions/${id}/`)
    fetch(request)
      .then(res => {
        const next = [...data['suspensions']].filter(x => x.id !== id)
        setFieldValue('suspensions', next)
        clearFieldError('has_suspensions')
      })
      .catch(console.log)
  }

  const addSuccess = res => {
    clearFieldError('has_suspensions')
    setFieldValue('suspensions', [
      ...data['suspensions'],
      res,
    ])
  }

  useEffect(() => {
    if (data.suspensions.length > 0) {
      setFieldValue('has_suspensions', 'one_or_more')
    }
  }, []) // eslint-disable-line

  return (
    <fieldset className="DriverHistory">
      <Radio
        name="auto_fraud"
        label={t('Has {{driver_name}} ever been convicted of auto insurance fraud?', {driver_name: driver_name})}
        choices={[[true, t('Yes')], [false, t('No')]]} 
      />
      {
        driverHistoryAdditionalQuestionsProvinces.has(lineitemProvince) &&
        <>
          <Radio
            name="experienced_faint"
            label={t('Is {{driver_name}} subject to fainting spells, dizziness or loss of consciousness?', {driver_name: driver_name})}
            choices={[[true, t('Yes')], [false, t('No')]]}
          />
          <Radio
            name="diagnosed_diseases"
            label={t('Has {{driver_name}} suffered from a heart disorder, epilepsy, diabetes, defective vision or hearing, or any other physical or mental disability which might affect the safe operation of a vehicle?', {driver_name: driver_name})}
            choices={[[true, t('Yes')], [false, t('No')]]}
          />
          <TextArea
            name="driver_occupation"
            label={t('Please provide {{driver_name}}\'s occupation', {driver_name: driver_name})}
            HelpCopy={t('You may qualify for an additional discount based on the occupation of the driver.')}
          />
        </>
      }
      <ArrayRadio
        name="has_suspensions"
        label={t('In the last 6 years, how many times has {{driver_name}} had their licence suspended?', {driver_name: driver_name})}
        ArrayToggleValue="one_or_more"
        ChangeCallback={NoneCallback}
        choices={[['one_or_more', t('One or More')], ['none', t('None')]]}
      >
        <HistoryItems
          items={data['suspensions']}
          title="reason"
          onDelete={deleteSuspension}
          Placeholder={t('Please provide details on the driver below')}
        />
        <SubForm>
          <SimpleForm SubmitUrl="/api/lineitems/suspensions/" SubmitLabel={t('Submit')} SuccessCallback={addSuccess} InitialData={{ owner: data.id }} AllowCancel>
            <DriverHistorySubForm parentData={data} />
          </SimpleForm>
        </SubForm>
      </ArrayRadio>
    </fieldset>
  )
}

export default DriverHistory
