import React from 'react'
import { useTranslation } from 'react-i18next'

export const VehicleAddHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('Vehicle information (i.e. VIN, year, make, model, and trim) is required so that we can properly insure your vehicle. You can find your VIN: on your current insurance policy, on your vehicle (usually in the driver door or on your dashboard), or on your bill of sale or leasing agreement.')}</small></p>
      <p><small>{t('If your vehicle is leased or financed, we require the name and address of your financial institution. They are required to be listed on your insurance policy.')}</small></p>
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy. If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy. We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

export const VehicleReplaceHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('Vehicle information (i.e. VIN, year, make, model, and trim) is required so that we can properly insure your vehicle. You can find your VIN: on your current insurance policy, on your vehicle (usually in the driver door or on your dashboard), or on your bill of sale or leasing agreement.')}</small></p>
      <p><small>{t('If your vehicle is leased or financed, we require the name and address of your financial institution. They are required to be listed on your insurance policy.')}</small></p>
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy.If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy.We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

export const VehicleRemoveHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy. If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy. We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

export const DriverAddHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('We require your licensing dates to make sure you are given the credit you deserve for your driving experience.')}</small></p>
      <p><small>{t('Licence suspension details are required because sometimes it can affect your insurance, but sometimes it doesn’t, so we want to get it right.')}</small></p>
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy.If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy.We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

export const DriverRemoveHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy. If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy. We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

export const AddressChangeHelp = () => {
  const { t } = useTranslation()
  return (
    <div className="requirements-help">
      <p><small>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy. If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy. We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</small></p>
    </div>
  )
}

