import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'
import { FormContext } from 'utils/context'
import { novaScotiaLicence, nsLicenceAndLastName, validNsDateFromLicence } from 'forms/utils/cleans'
import { dobFromNsLicence } from 'forms/utils/helpers'
import { Trans } from 'react-i18next'
import { translateDate } from 'utils/helpers'

const NsLicenceNumber = ({ id, name, type, label, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, className, Nested, cleans, LastName }) => {

  const { data, setFieldValue, setFieldError } = useContext(FormContext)

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, Nested, cleans)

	const classes = classNames('field text licence-number', {
		[className]: className,
		'active': active,
		'touched': touched,
		'has-error': error,
  })

  const licenceBlur = e => {
    const { value } = e.target

    try {
      novaScotiaLicence(value)
      nsLicenceAndLastName(value, LastName)
      validNsDateFromLicence(value)
      const dob = dobFromNsLicence(value)
      setFieldValue('date_of_birth', dob)
    } catch (e) {
      setFieldError(name, e.message)
    }

    onBlur({ target: { value } })
  }

  const dob = data['date_of_birth'] ? translateDate(parseISO(data['date_of_birth']), 'MMM dd, yyyy') : ''

	return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
			<Label label={label} name={name} />
			<div className="field-wrapper">
				<input
	        id={id || name}
	        name={name}
	        value={value || ''}
          onChange={onChange}
	        onFocus={onFocus}
          onBlur={licenceBlur}
	        type={type || 'text'}
	        placeholder="ABCDE000101123"
	      />
        <FieldError error={error} name={name} />
        {dob && <span className="help-copy">
            <Trans
                i18nKey="driversBirthdateHelpCopy"
                values={{ birthdate: dob }}
            />
          </span>}
			</div>
		</div>
	)
}

export default NsLicenceNumber