import { useContext } from 'react'
import AllVehicles from 'forms/fields/AllVehicles'
import { AccountContext, FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import PoliciesPk from 'forms/fields/PoliciesPk'
const QuoteGetStarted = ({ getStartedPoliciesLabel }) => {

  const { t } = useTranslation()
  const { vehicles } = useContext(AccountContext)
  const { setFieldValue } = useContext(FormContext)

  const setPolicy = value => {
    const vehicle = vehicles.find(v => v.vehicle_id === Number(value))
    setFieldValue('policy', vehicle.policy)
  }

  return (
    <fieldset>
      <PoliciesPk
        name="policy"
        label={getStartedPoliciesLabel}
        AutoOnly
      />
      <AllVehicles
        name="vehicle_id"
        label={t("Which vehicle would you be replacing?")}
        ChangeCallback={setPolicy}
        Stacked
      />
    </fieldset>
  )
}

export default QuoteGetStarted