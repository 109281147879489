import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Waypoint } from 'react-waypoint'
import BasePage from 'components/BasePage'
import BackToTop from 'components/BackToTop'
import TenantStr from 'components/TenantStr'
import TenantUrl from 'components/TenantUrl'
import HelpIndex from 'components/HelpIndex'
import { useTranslation, Trans } from 'react-i18next'

const Lnk = ({ to, label }) => <li><HashLink smooth to={to}>{label}</HashLink></li>

const Help = ({ tenant }) => {

  const { t } = useTranslation()
  const [showScroller, setShowScroller] = useState(false)

  return (
    <BasePage>
      <div className="container">
        <div>
          <HelpIndex>
            <Lnk to="#whysignup" label={t('Why should I sign up for an account?')} /> 
            <Lnk to="#howsignup" label={t('How do I sign up for an account?')} />
            <Lnk to="#passwordrequirements" label={t('What are the password requirements?')} />
            <Lnk to="#howlogin" label={t('How do I log in?')} />
            <Lnk to="#loginerror" label={t('I see an error when I try to log in')} />
            <Lnk to="#signuperror" label={t('I see an error when I try to sign up')} />
            <Lnk to="#forgotpassword" label={t('I forgot my password, how can I reset it?')} />
            <Lnk to="#noactivation" label={t("I didn't receive an activation email")} />
            <Lnk to="#noforgot" label={t("I didn't receive an email after requesting a password reset")} />
          </HelpIndex>
        </div>

        <Waypoint onEnter={() => setShowScroller(true)} onLeave={() => setShowScroller(false)}>
          <div>
            <hr />

            <h4 id="whysignup">{t('Why should I sign up for an account?')}</h4>

            <p>
              <Trans
                i18nKey="tenantCreateOnlineAcount"
                components={{ productName: <TenantStr ProductName />}}
              />
            </p>
            <p>{t('You can:')}</p>
            <ul className="content-list">
              <li>{t('Access your billing information')}</li>
              <li>{t('Change your Address')}</li>
              <li>{t('Add, Replace or Remove a Vehicle')}</li>
              <li>{t('Add or Remove a Driver')}</li>
              <li>{t('View and Print your Liability Slips')}</li>
            </ul>

            <hr />

            <h4 id="howsignup">{t('How do I sign up for an account?')}</h4>

            <p>{t('On the homepage you can click on Create an Account, or you can go to the Sign Up page.')}</p>
            <p>{t('Simply fill in the form by entering your email address, policy number, and password.')}</p>
            <p>{t('Please note that the email address and policy number must match what we have on file.')}</p>
            <p>{t('Your password must meet the requirements - at least 8 characters, at least 1 number, and at least 1 uppercase character.')}</p>
          
            <hr />

            <h4 id="passwordrequirements">{t('What are the password requirements?')}</h4>

            <p>{t('To create a password, it must meet the following requirements:')}</p>
            <ul>
              <li>{t('At least 8 characters')}</li>
              <li>{t('At least 1 number')}</li>
              <li>{t('At least 1 uppercase character')}</li>
            </ul>

            <hr />

            <h4 id="howlogin">{t('How do I log in?')}</h4>
            
            <p>{t('There is a log in box on the homepage, or you can visit the login page.')}</p>
            <p>{t('The email address needs to be the one we have on file, and the password is what was used to set up your account.')}</p>

            <hr />

            <h4 id="loginerror">{t('I see an error when I try to log in')}</h4>

            <p>
              <Trans
                i18nKey="forgotPasswordForm"
                components={{ passwordLink: <Link to="/forgot-password" />}}
              />
            </p>
            <p>
              <Trans
                i18nKey="checkPassword"
                components={{ passwordLink: <Link to="/forgot-password" />}}
              />
            </p>

            <hr />

            <h4 id="signuperror">{t('I see an error when I try to sign up')}</h4>

            <p>{t('If you are getting errors when trying to sign up, please ensure that the email address you are using is the one that we have on file. The policy number must also match what we have on file. Please also ensure that the password you are trying to use meets the password requirements.')}</p>

            <p>{t('Please also double check that you haven’t signed up already.')}</p> 

            <p>{t('If you are still experiencing issues, please give us a call and we will help you through it.')}</p>

            <hr />

            <h4 id="forgotpassword">{t('I forgot my password, how can I reset it?')}</h4>

            <p>{t('You can find a Forgot Password link on the homepage and login page.')}</p>
            <p>{t('Enter your email address and we’ll send you an email with a link to reset your password.')}</p>

            <hr />

            <h4 id="noactivation">{t("I didn't receive an activation email")}</h4>

            <p>
              <Trans
                i18nKey="maybeBlockingMessage"
                components={{ phone: <TenantUrl Phone />}}
              />
            </p>

            <hr />

            <h4 id="noforgot">{t("I didn't receive an email after requesting a password reset")}</h4>

            <p>
              <Trans
                i18nKey="maybeBlockingMessage"
                components={{ phone: <TenantUrl Phone /> }}
              />
            </p>
          </div>
        </Waypoint>
        <BackToTop visible={showScroller} />
      </div>
    </BasePage>
  )
}

export default Help
