class glassCoverageVisible {
    constructor(policies = null, data = null) {
        this.policies = policies
        this.data = data
        this.policy = this.policies.find(p => Number(p.id) === this.data['policy'])
        this.isQuote = this.data.type === 'vehicle_add_quote' || this.data.type === 'vehicle_replace_quote'
        this.isReduceCov = this.data.type === 'reduce_coverage'
        this.isReinstateCov = this.data.type === 'reinstate_coverage'

        this.errors = {
            policyNotFound: 'Policy not found',
            dataNotAB: 'Data is not Alberta',
            policyNotAB: 'Policy is not Alberta'
        }
    }

    checkDataProvince(){
        if(this.data['registered_province'] !== 'AB'){
            throw new Error(this.errors.dataNotAB)
        }
    }
    
    checkPolicyProvince(){
        if(this.policy.province !== 'AB'){
            throw new Error(this.errors.policyNotAB)
        }
    }


    show() {
        try {
            if(this.isQuote || this.isReduceCov || this.isReinstateCov){
                this.checkPolicyProvince()
            } else {
                this.checkDataProvince()
            }

            return { 
                show: true,
                message: null
            }
        } catch (e){
            return { 
                show: false,
                message: e.message
             }
        }
    
  
    }
      
}

export default glassCoverageVisible