import React from 'react'

export const AppContext = React.createContext({})
export const AccountContext = React.createContext({})
export const LineitemContext = React.createContext({})

// forms

export const FormContext = React.createContext({})
export const NestedFormContext = React.createContext({})
export const SupportContext = React.createContext({})