import { useState, useEffect, useCallback, useContext } from 'react'
import { getRequest } from 'utils/requests'
import { AccountContext, AppContext } from 'utils/context'

const useSupport = (fs, path) => {

  const { setDialogOpen } = useContext(AppContext)
  const { lineitemProvince } = useContext(AccountContext)

  const [snippets, setSnippets] = useState([])
  const [supportField, setSupportField] = useState('')

  const setSupportWithField = field => {
    setSupportField(field)
    setDialogOpen('form-support')
  }

  const getSupport = useCallback(() => {
    getRequest(`/api/support/groups/${fs}/`)
      .then(res => {
        const formName = path.replace('/account/requests/', '').replace(/\b(change|quote|question)\b/g, '').replace('/', '').replace('/:id/:fs', '').replace('-', '_')
        const filterForForm = res.snippets.filter(s => !s.form || s.form === formName)
        const filterForProvince = filterForForm.filter(s => !s.province || s.province === lineitemProvince)
        setSnippets(filterForProvince)
      })
      .catch(err => {
        setSupportField('')
        setSnippets([])
      }) // swallow this error, it's expected
  }, [fs, lineitemProvince, path])

  const getSnippets = () => {
    if (supportField) {
      return snippets.filter(f => f.slug === supportField)
    }
    return snippets
  }

  useEffect(() => {
    getSupport()
  }, [fs, getSupport])

  return {
    snippets,
    getSnippets,
    setSupportField,
    setSupportWithField,
  }
}

export default useSupport