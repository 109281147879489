import React, { useContext, useEffect, useState } from 'react'
import { addDays, startOfTomorrow } from 'date-fns'
import AllDrivers from 'forms/fields/AllDrivers'
import Text from 'forms/fields/Text'
import Tile from 'components/Tile'
import WarningIcon from 'utils/icons/Warning'
import { AccountContext, FormContext } from 'utils/context'
import DateChooser from 'forms/fields/DateChooser'
import { useTranslation } from 'react-i18next'

const GetStarted = () => {
  const { t } = useTranslation()
  const { policies} = useContext(AccountContext)
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  const [showDateWarning, setShowDateWarning] = useState(false)

  let hasAuto = false

  for (let i = 0; i < policies.length; i++) {
    if (policies[i].lob === 'auto') {
      hasAuto = true
      break
    } 
  }
  
  if (!hasAuto) {
    data['requester'] = 'other'
  }

  const requesterCallback = value => {
    if (value === 'other') {
      setFieldValue('requester_other', null)
      clearFieldError('requester_other')
    }
  }
  
  useEffect(() => {
    if (data['effective_date']) {
      let sameAsRenewalDate = false 
      const cancellationDate = new Date(data['effective_date']).toISOString().split("T")[0]

      policies.forEach((policy) => {
        const expirationDate = new Date(policy.expiration_date).toISOString().split("T")[0]
        
        if (cancellationDate === expirationDate) {
          sameAsRenewalDate = true
        }
      })
      if (sameAsRenewalDate) {
        setShowDateWarning(false)
      } else {
        setShowDateWarning(true)
      }
    }
  }, [data['effective_date']]) // eslint-disable-line

  return (
    <fieldset>
      {
        showDateWarning &&
        <Tile Warning Icon={WarningIcon} IconName="WarningIcon" SmallIcon>
          <small className="TileHeader Disclaimer">
            {t('Cancelling on any date, other than your renewal date, may result in a midterm cancellation penalty.')}
          </small>
        </Tile>
      }
      {
        hasAuto &&
        <AllDrivers
          name="requester"
          label={t('Who is making the inquiry?')}
          ChangeCallback={requesterCallback}
          Stacked
          other
        />
      }
      
      {
        (data["requester"] === "other" || hasAuto === false)&&
        <Text
          name="requester_other"
          label={(hasAuto) ? t('What is the name of the person making the inquiry?') : t('Who is making the inquiry?')}
        />
      }
      <DateChooser
        minDate={startOfTomorrow(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t('What date do you want your insurance cancelled?')}
        IncludeYear
      />
    </fieldset>
  )
}

export default GetStarted