import { useContext } from 'react'
import Tile from 'components/Tile'
import Link from 'components/Buttons/Link'
import PropertyIcon from 'utils/icons/Property'
import Address from 'components/Address'
import Divider from 'components/Divider'
import { AccountContext } from 'utils/context'
import { getPolicyValue } from 'account/utils/helpers'
import { useTranslation } from 'react-i18next'
import policyType from './policyType'

const HabPolicies = () => {
  
  const { t } = useTranslation()
  const { policies, locations } = useContext(AccountContext)
  
  return (
    <>
      {locations.length > 0 && <Divider Small />}
      {locations.map((l, i) => (
        <Tile key={i} Icon={PropertyIcon} IconName="PropertyIcon">
          <div className="TileHeader">
            <span className="uppercase subtle">
              {policyType(getPolicyValue(l.policy, policies, 'policy_type'))}
            </span>
            <Address key={l.id} location={l} className="secondary" />
          </div>
          <div className="TileBody">
            <Link to={`/account/${getPolicyValue(l.policy, policies, 'policy_number')}`} Pill Light Small ariaLabel=
            {t('View detail for policy {{policyNumber}}', {policyNumber: getPolicyValue(l.policy, policies, 'policy_number')})} DataTestId={`hab-policy--${i}`} >
              {getPolicyValue(l.policy, policies, 'policy_number') || t('Loading…')}
            </Link>
          </div>
        </Tile>
      ))}
    </>
  )
}

export default HabPolicies