import React from 'react'
import { HashLink } from 'react-router-hash-link'

import './style.scss'

const Popover = ({ copy, link, url }) => {
  return (
    <div className="Popover">
      <span>
        {copy} {link && url && <HashLink smooth to={url}>{link}</HashLink>}
      </span>
    </div>
  )
}

export default Popover