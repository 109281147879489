import { useContext } from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import RequestDetails from 'forms/fieldsets/general/RequestDetails'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'
import { AccountContext } from 'utils/context'

const General = () => {
  
  let { contact } = useContext(AccountContext)

  return (
    <MultiPageForm>
      <RequestDetails path="request-details" email={contact.email} />
      <PreferredContact path="preferred-contact" />
    </MultiPageForm>
  )
}

export default General