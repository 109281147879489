import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'
import { useTranslation } from 'react-i18next'

const Radio = ({ choices, name, label, HtmlLabel, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, className, HelpCopy, Other, Stacked, disabledChoices }) => {

  const { t } = useTranslation()
  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)
  
  choices = Other ? [...choices, ['other', t('Other')]] : choices

  
  if (!choices) {
    choices = [['yes', t('Yes')], ['no', t('No')]]
  }

  const classes = classNames('field radio', {
    [className]: className,
    'active': active,
    'touched': touched,
    'has-error': error,
    'stacked': choices.length > 2 || Stacked,
  })

  const radioChange = e => {
    let val = e.target.value
    if (val === 'true') {
      val = true
    } else if (val === 'false') {
      val = false
    }
    onChange({ target: { value: val } })
  }

  const labelClass = item => classNames({
    'checked': value == item, // eslint-disable-line
    'disabled': (disabledChoices && !disabledChoices[item])
  })

  return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
      <Label label={label} HtmlLabel={HtmlLabel} name={name} />
      <div className="field-wrapper">
        <div className="choices">
          {choices.map(ch =>
            <Label
              key={ch[1]}
              secondaryLabel
              htmlFor={name + ch[1]}
              className={labelClass(ch[0])} // eslint-disable-line
            >
            <input
              type="radio"
              id={name + ch[1]}
              name={name}
              onChange={radioChange}
              onFocus={onFocus}
              onBlur={onBlur}
              checked={value == ch[0]} // eslint-disable-line
              value={ch[0]}
              disabled={disabledChoices && !disabledChoices[ch[0]]}
            />
              <span>{ch[1]}</span>
            </Label>
          )}
        </div>
      </div>
      <FieldError error={error} name={name} />
      {HelpCopy && <div className="help-copy">{HelpCopy}</div>}
    </div>
  )
}

export default Radio
