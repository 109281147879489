import PoliciesPk from 'forms/fields/PoliciesPk'

const QuoteGetStarted = ({ getStartedPoliciesLabel }) => {
  return (
    <fieldset>
      <PoliciesPk
        name="policy"
        label={getStartedPoliciesLabel}
        AutoOnly
        DisplaySingle
      />
    </fieldset>
  )
}

export default QuoteGetStarted