import { useContext } from 'react'
import { AccountContext } from 'utils/context'
import { parseISO } from 'date-fns'
import Tile from 'components/Tile'
import { requestTypes } from 'utils/vars'
import QuestionAltIcon from 'utils/icons/QuestionAlt'
import DollarIcon from 'utils/icons/Dollar'
import AutoIcon from 'utils/icons/Auto'
import UserIcon from 'utils/icons/User'
import { idsToValues, translateDate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
const PreviousRequest = ({lineItem, requesterName}) => {
  const { t } = useTranslation()
  let { policies } = useContext(AccountContext)

  const quoteVehicles = lineItem.quote_vehicles
  
  const requestDetails = {
    billing_request: {
      details: t('Details:') + " " + lineItem.details,
      icon: DollarIcon,
    },
    vehicle_add_quote: {
      details: "",
      icon: AutoIcon,
    },
    vehicle_replace_quote: {
      details: "",
      replaced_vehicle: t('Replacing:')  + " " + (lineItem.full_vehicle_name ? lineItem.full_vehicle_name : t('Vehicle information is unavailable.')),
      icon: AutoIcon,
    },
    vehicle_add: {
      details: t('Vehicle:') + " " + lineItem.year + " " + lineItem.make + " " + lineItem.model,
      icon: AutoIcon,
    },
    vehicle_replace: {
      details: t('Vehicle:') + " " + lineItem.year + " " + lineItem.make + " " + lineItem.model,
      replaced_vehicle: t('Replacing:') + " " + (lineItem.full_vehicle_name ? lineItem.full_vehicle_name : t('Vehicle information is unavailable.')),
      icon: AutoIcon,
    },
    vehicle_remove: {
      details: t('Vehicle:') + " " + (lineItem.full_vehicle_name ? lineItem.full_vehicle_name : t('Vehicle information is unavailable.')),
      icon: AutoIcon,
    },
    driver_add: {
      details: t('Driver:') + " " + lineItem.first_name + " " + lineItem.last_name,
      icon: UserIcon,
    },
    driver_remove: {
      details: t('Driver:') + " " + (lineItem.full_name ? lineItem.full_name : t('Driver information is unavailable.')),
      icon: UserIcon,
    },
    address_change: {
      details: t('Address:') + " " + lineItem.street_number + " " + lineItem.street_name + (lineItem.unit_number ? " " + t('Unit:') + lineItem.unit_number : ""), 
      icon: AutoIcon,
    },
    payment_date: {
      details: t('Details:') + " " + lineItem.details,
      icon: DollarIcon,
    },
    payment_details: {
      details: t('Details:') + " " + lineItem.details,
      icon: DollarIcon,
    },
    payment_schedule: {
      details: "",
      icon: DollarIcon,
    },
    coverage_change: {
      details: t('Details:') + " " + lineItem.details,
      icon: DollarIcon,
    },
    reduce_coverage: {
      details: t('Vehicle:') + " " + (lineItem.full_vehicle_name ? lineItem.full_vehicle_name : t('Vehicle information is unavailable.')),
      icon: AutoIcon,
    },
    reinstate_coverage: {
      details: t('Vehicle:') + " " + (lineItem.full_vehicle_name ? lineItem.full_vehicle_name : t('Vehicle information is unavailable.')),
      icon: AutoIcon,
    },
    general_question: {
      details: t('Details:') + " " + lineItem.details,
      icon: QuestionAltIcon,
    },
    general_request: {
      details: t('Details:') + " " + lineItem.details,
      icon: QuestionAltIcon,
    },
    general_quote: {
      details: t('Details:') + " " + lineItem.details,
      icon: QuestionAltIcon,
    },
    general_change: {
      details: t('Details:') + " " + lineItem.details,
      icon: QuestionAltIcon,
    },
    policy_cancel: {
      details: "",
      icon: QuestionAltIcon,
    },
    policy_renew: {
      details: "",
      icon: QuestionAltIcon,
    }
  }

  const getPastTense = type => {
    if (requestTypes[type]) {
      return requestTypes[type].past_tense
    }
    return t('Unknown request type')
  }

  const getRequestDetails = type => {
    if (requestDetails[type]) {
      if(String(type) === "vehicle_add_quote" || String(type) === "vehicle_replace_quote"){
        return <div> {t('Vehicle(s):')}
        {quoteVehicles.map(v =>
          <div key={v.id}>
            {v.year + " " + v.make.toUpperCase() + " " + v.model}
          </div>
        )}
        </div>
      }
      if(String(type) === "policy_cancel"){
        return <div> {t('Policy:')} {idsToValues(lineItem.cancelling_policies, policies, "policy_id", "policy_number")}
        </div>
      }
      if(String(type) === "payment_date" ||
        String(type) === "payment_details" ||
        String(type) === "payment_schedule" ||
        String(type) === "coverage_change" ||
        String(type) === "general_change" ||
        String(type) === "general_request" ||
        String(type) === "general_question" ||
        String(type) === "billing_request"){
        return <>
        <div> Policy: {idsToValues(lineItem.requested_policies, policies, "policy_id", "policy_number")}
        </div>
        <div>{requestDetails[type].details}</div>
        </>
      }
      if(String(type) === "policy_renew"){
        return <div> {t('Policy:')} {idsToValues(lineItem.renewing_policies, policies, "policy_id", "policy_number")}
        </div>
      }
      return requestDetails[type].details
    }
    return t('Unknown request type')
  }

  const getRequestIcon = type => {
    if (requestDetails[type]) {
      return requestDetails[type].icon
    }
    return QuestionAltIcon
  }

  const getReplacedVehicle = type => {
    if (requestDetails[type]) {
      return requestDetails[type].replaced_vehicle ? requestDetails[type].replaced_vehicle : ""
    }
    return ""
  }
  
  return ( 
    <Tile Icon={getRequestIcon(lineItem.type)} IconName="RequestIcon">
      <span className="subtle float-right">
        {t('Submitted:')} {translateDate(parseISO(lineItem.modified), 'MMM d, yyyy')} <br/>
        {lineItem.effective_date ? t('Effective:') + " " + translateDate(parseISO(lineItem.effective_date), 'MMM d, yyyy') : ""}
      </span>

      <div className="TileHeader display-block">
        <b>{getPastTense(lineItem.type)}</b>
        {requesterName && <div>{t('Requester:')} {requesterName}</div>}
        <div>{lineItem.preferred_contact_detail ? t('Preferred method of contact:') + " " + lineItem.preferred_contact_detail : ""}</div>
        <div>{getRequestDetails(lineItem.type)}</div>
        <div>{getReplacedVehicle(lineItem.type)}</div>
      </div>
    </Tile>
  )
}

export default PreviousRequest
