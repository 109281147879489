import React, { useContext } from 'react'
import Text from 'forms/fields/Text'
import Radio from 'forms/fields/Radio'
import Select from 'forms/fields/Select'
import { AccountContext } from 'utils/context'
import { AppContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { parkedAtNightCarrierCodes } from 'utils/vars'
import { vehicleUse, parkedAtNightChoices } from 'forms/utils/vars'
import { zeroBusinessKms } from 'forms/utils/cleans'
import { useTranslation, Trans } from 'react-i18next'

const Usage = ({ prefix, defaultUsage, defaultAnnual, defaultDaily, defaultBusiness, usageLabel, DefaultParkedAtNight, defaultCommuteVehicle }) => {
  
  const { t } = useTranslation()
  const { data, setFieldValue, setFieldError, clearFieldError } = useContext(FormContext)
  const acct = useContext(AccountContext)
  const { tenant } = useContext(AppContext)

  const fieldName = name => prefix ? `${prefix}_${name}` : name

  const usageCallback = value => {
    if (value === 'pleasure') {
      setFieldValue(fieldName('daily_kms'), null)
      clearFieldError(fieldName('daily_kms'))
      setFieldValue(fieldName('business_annual_kms'), null)
      clearFieldError(fieldName('business_annual_kms'))
    } else if (value === 'commuting') {
      setFieldValue(fieldName('business_annual_kms'), null)
      clearFieldError(fieldName('business_annual_kms'))
      setFieldValue(fieldName('commute_vehcile'), null)
      clearFieldError(fieldName('commute_vehcile'))
    } else if (value === 'business') {
      clearFieldError(fieldName('daily_kms'))
      setFieldValue(fieldName('commute_vehcile'), null)
      clearFieldError(fieldName('commute_vehcile'))
    }
  }

  const usage = data[fieldName('usage')]

  const showPleasure = usage === 'pleasure' || usage === 'commuting' || usage === 'business'
  const showCommuting = usage === 'commuting' || usage === 'business'
  const showBusiness =  usage === 'business'
  
  const showCommuteUsage = usage === 'pleasure'

  const policy = acct.policies.find(p => p.id === data.policy)
  const showParkedAtNight = parkedAtNightCarrierCodes.has(policy.carrier_code)

  const dailyHelp = () => {
    if (showBusiness && data[fieldName('daily_kms')] === '0') {
      return t('Are you sure? This means that you do not drive to work or school every day.')
    }
    return null
  }
  
  const annualError = value => {
    if (value === '0') {
      setFieldError(
        fieldName('annual_kms'),
        <span>
          <Trans
            i18nKey="storeVehicleContact"
            components={{
              // eslint-disable-next-line
              a1: <a className = "Link" href={tenant.contact_us_url}/>
            }} 
          />
        </span>
      )
    }
  }

  const commuteError = value => {
    if (usage === 'commuting' && value === '0') {
      setFieldError(
        fieldName('daily_kms'),
        t('If this vehicle will not be used for commuting, please make another selection for vehicle usage above.')
      )
    }
  }

  return (
    <div>
      <Radio
        id={fieldName('usage')}
        name={fieldName('usage')}
        choices={vehicleUse}
        ChangeCallback={usageCallback}
        DefaultValue={defaultUsage}
        label={usageLabel}
      />
      {showPleasure  &&
        <Text
          id={fieldName('annual_kms')}
          name={fieldName('annual_kms')}
          label={t("Annual KMs")}
          DefaultValue={defaultAnnual}
          KmField
          maxLength={6}
          ChangeCallback={annualError}
        />
      }
      {showCommuteUsage  &&
        <Text
          id={fieldName('commute_vehicle')}
          name={fieldName('commute_vehicle')}
          DefaultValue={defaultCommuteVehicle}
          label={t("How do you get to work or school?")}
          maxLength={250}
        />
      }
      {showCommuting &&
        <Text
          id={fieldName('daily_kms')}
          name={fieldName('daily_kms')}
          label={t("KMs driven daily to work or school one way")}
          DefaultValue={defaultDaily}
          KmField
          HelpCopy={dailyHelp()}
          maxLength={6}
          ChangeCallback={commuteError}
        />
      }
      {showBusiness  &&
        <Text
          id={fieldName('business_annual_kms')}
          name={fieldName('business_annual_kms')}
          label={t("Business Annual KMs")}
          DefaultValue={defaultBusiness}
          KmField
          maxLength={6}
          cleans={[zeroBusinessKms]}
        />
      }
      {showParkedAtNight &&
        <Select
          name={fieldName('parked_overnight')}
          choices={parkedAtNightChoices}
          DefaultValue={DefaultParkedAtNight}
          label={t("Where will this vehicle be parked at night?")}
        />
      }
    </div>
  )
}

export default Usage