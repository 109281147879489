import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import OwnerDriverConfirm from 'forms/fieldsets/vehicle/OwnerDriverConfirm'
import UsageConfirm from 'forms/fieldsets/vehicle/UsageConfirm'
import CoverageConfirm from 'forms/fieldsets/vehicle/CoverageConfirm'
import VehicleQuoteInformationConfirm from 'forms/fieldsets/vehicle/VehicleQuoteInformationConfirm'
import QuoteEligibilityConfirm from './QuoteEligibilityConfirm'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import { provinceChoices } from 'utils/vars'

const VehicleAddQuoteConfirm = () => {

  const { data } = useContext(FormContext)

  const province = provinceChoices.find(ch => ch[0] === data['registered_province']) || ''

  return (
    <fieldset>
      <RequesterDetails isQuote />
      <VehicleQuoteInformationConfirm province={province[1]} />
      <OwnerDriverConfirm QuoteOnly />
      <UsageConfirm />
      <QuoteEligibilityConfirm />
      <CoverageConfirm />
      <PolicyChangesConfirm />
    </fieldset>
  )
}

export default VehicleAddQuoteConfirm