import { useContext } from 'react'
import Text from 'forms/fields/Text'
import Select from 'forms/fields/Select'
import Radio from 'forms/fields/Radio'
import { provinceChoices, conditionChoices } from 'utils/vars'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import { maxPrice } from 'forms/utils/cleans'
import { useTranslation } from 'react-i18next'

const VehicleDetails = () => {
  
  const { t } = useTranslation()
  const { policies } = useContext(AccountContext)
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  
  let pol = policies.find(p => Number(p.id) === data['policy']) || {}

  const conditionCallback = value => {
    if (value === 'used') {
      setFieldValue('kms_at_purchase', null)
      clearFieldError('kms_at_purchase')
    }
  }

  const showKms = data['vehicle_condition'] === 'new' || data['vehicle_condition'] === 'demo' 

  return (
    <fieldset>
      <Select
        name="registered_province"
        label={t("In what province is the vehicle registered?")}
        choices={provinceChoices}
        DefaultValue={pol.province}
      />
      <Text
        PriceField
        name="price_of_vehicle"
        label={t("What is the price of this vehicle (with tax, before trade-in)?")}
        cleans={[maxPrice]}
      />
      <Radio
        name="vehicle_condition"
        label={t("What is the condition of this vehicle?")}
        choices={conditionChoices}
        ChangeCallback={conditionCallback}
      />
      {showKms && <Text
        KmField
        name="kms_at_purchase"
        label={t("Vehicle KMs at time of purchase")}
        maxLength={6}
      />}
    </fieldset>
  )
}

export default VehicleDetails