import React, { useContext } from 'react'
import SimpleForm from 'forms/SimpleForm'
import Text from 'forms/fields/Text'
import DoublePassword from 'forms/fields/DoublePassword'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const ForgotConfirm = ({ match, history }) => {
  const { t } = useTranslation()
  const { showMaintenance } = useContext(AppContext)

  const confirmSuccess = res => {
    if (res.success) {
      history.push('/reset-success')
    }
  }

  return (
    <div>
      <div className="SitePage">
        <div className="container">
          <h2>{t('Choose a new password')}</h2>
          {showMaintenance ? (
            <p>{t('Password reset is currently disabled')}</p>
          ) : (
            <SimpleForm SubmitUrl="/api/users/password/reset/confirm/" SubmitLabel={t('Submit')} SuccessCallback={confirmSuccess}>
              <Text
                name="uid"
                DefaultValue={match.params.uid}
                Hidden
              />
              <Text
                name="token"
                DefaultValue={match.params.token}
                Hidden
              />
              <DoublePassword
                pw1="new_password1"
                pw2="new_password2"
              />
            </SimpleForm>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotConfirm
