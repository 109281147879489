import React, { useState, useContext } from 'react'
import SimpleForm from 'forms/SimpleForm'
import Link from 'components/Buttons/Link'
import Tile from 'components/Tile'
import BasePage from 'components/BasePage'
import Address from 'components/Address'
import Text from 'forms/fields/Text'
import DoublePassword from 'forms/fields/DoublePassword'
import UsersIcon from 'utils/icons/Users'
import LockIcon from 'utils/icons/Lock'
import PropertyIcon from 'utils/icons/Property'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

import './style.scss'

const Profile = () => {

  const { t } = useTranslation()
  const [passwordChanged, setPasswordChanged] = useState(false)
  const { contact } = useContext(AccountContext)

  const handleSuccess = res => {
    if (res && res.success) {
      setPasswordChanged(true)
    }
  }

  const address = {
    address_one: contact.address_one,
    address_two: contact.address_two,
    city: contact.city,
    province: contact.province,
    postal_code: contact.postal_code,
  }

  const hasAddress = contact.address_one && contact.province && contact.postal_code

  return (
    <BasePage>
      <div className="ProfilePage container">
        <Tile Icon={UsersIcon} IconName="UsersIcon" Loading={contact.email === undefined}>
          <div className="TileHeader">
            <h4 className="uppercase">
              {contact.first_name} {contact.last_name}
            </h4>
            <small className="lowercase">{contact.email}</small>  
          </div>
        </Tile>
        {hasAddress && (
          <Tile Icon={PropertyIcon} IconName="PropertyIcon" Loading={contact.email === undefined}>
            <div className="TileHeader">
              <Address location={address} className="ProfileAddress" /> 
            </div>
          </Tile>
        )}
        <Tile Header="Change Your Password" Icon={LockIcon} IconName="LockIcon">
          <div className="TileHeader">
            <strong>{t('Change Your Password')}</strong>
          </div>
          <div className="TileBody">
            {passwordChanged ? (
              <div>
                <p className="CardDialog Positive">{t('Password successfully changed')}</p>
                <p>
                  <Link to="/account" Pill>{t('Return to Account Summary')}</Link>
                </p>
              </div>
            ) : (
              <SimpleForm SubmitUrl="/api/users/password/change/" SubmitLabel={t('Submit')} SuccessCallback={handleSuccess}>
                <Text
                  name="old_password"
                  label={t('Current Password')}
                  type="password"
                  placeholder="•••••••••••"
                />
                <DoublePassword
                  pw1="new_password1"
                  pw2="new_password2"
                  New
                />
              </SimpleForm>
            )}
          </div>
        </Tile>
      </div>
    </BasePage>
  )
}

export default Profile