import React, { useContext } from 'react'
import CloseButton from 'components/CloseButton'
import SiteNav from './SiteNav'
import AuthedNav from './AuthedNav'
import { AppContext } from 'utils/context'
import useModal from 'utils/useModal'

import './style.scss'

const Nav = () => {

  const { authed } = useContext(AppContext)

  const { cls, closeModal, dialogOpen } = useModal('left', 'firstFocus')

  return dialogOpen === 'nav' ? (
    <nav className={cls}>
      <div className="ModalInner container">
        <div className="Nav">
          {authed ?
            <AuthedNav closeModal={closeModal} /> :
            <SiteNav closeModal={closeModal} />
          }
        </div>
      </div>
      <CloseButton onClick={closeModal} />
    </nav>
  ) : null
}


export default Nav