import { useContext } from 'react'
import Divider from 'components/Divider'
import LoadingTiles from 'components/LoadingTiles'
import AutoPolicies from 'account/AutoPolicies'
import HabPolicies from 'account/HabPolicies'
import { AccountContext } from 'utils/context' 

const AllPolicies = () => {

  let { driversLoading, vehiclesLoading, locationsLoading } = useContext(AccountContext)

  return (
    <div className="AllPolicies">
      {driversLoading || vehiclesLoading ? (
        <LoadingTiles />
      ) : (
        <AutoPolicies />
      )}
      {locationsLoading ? (
        <>
          <Divider Small />
          <LoadingTiles />
        </>  
      ) : (
        <HabPolicies />
      )}
    </div>
  )
}

export default AllPolicies