import { useState, useContext, useEffect, useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import { validVin, vehicleYear, vehicleMake } from 'forms/utils/helpers'
import { FormContext } from 'utils/context'
import { getAuthedRequest } from 'utils/requests'

const useVinLookup = () => {
  
  const { data, setFieldValue, clearAllErrors} = useContext(FormContext)
  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [models, setmodels] = useState([])

  const newLookup = value => {
    const clearAll = () => {
      setFieldValue('year', null)
      setFieldValue('make', null)
      setFieldValue('slug', null)
    }

    if (validVin(value)) {
      clearAllErrors()
      setLoading(true)
      getAuthedRequest(`/jasmine/vinlink/vin/${value}/`)
        .then(res => {
          try {
            setFieldValue('year', vehicleYear(res))
            setFieldValue('make', vehicleMake(res))
            setmodels(res.models)
            if (res.models.length === 1) {
              setFieldValue('slug', res.models[0][0])
              setFieldValue('model', res.models[0][1])
            } else {
              setFieldValue('slug', null)
              setFieldValue('model', null)
            }
            setError(false)
            setLoading(false)
          } catch (e) {
            clearAll()
            setError(true)
            setLoading(false)
          }
        })
        .catch(err => Sentry.captureException(err))
    } 
  }

  const oldLookup = useCallback(value => {

    if (validVin(value)) {
      getAuthedRequest(`/jasmine/vinlink/vin/${value}/`)
        .then(res => {
          try {
            if (res.models.length > 1) {
              setmodels(res.models)
            } 
          } catch (e) {
            console.log(e, 'An error has occured searching models.')
          }
        })
        .catch(err => Sentry.captureException(err))
    } 
    
  }, [])

  useEffect(()=>{
    if(data.make){
      oldLookup(data.vin)
    } 
  },[]) //eslint-disable-line

  return {
    loading,
    models,
    error,
    newLookup,
  }
}

export default useVinLookup