import Radio from 'forms/fields/Radio'
import AntiTheft from './AntiTheft'
import { useTranslation, Trans } from 'react-i18next'
const QuoteEligibility = () => {
  
  const { t } = useTranslation()
  const Winter = <><Trans i18nKey="winterTireQuestion" components={{ s: <strong /> }} /></> 

  return (
    <fieldset>
      <p className="fieldset-intro">{t('Let us know a little more about how you will be using this vehicle')}</p>
      <AntiTheft name="quote-eligibility"/>
      <Radio
        name="winter_tires"
        label={t("Do you have winter tires for this vehicle?")}
        HtmlLabel={Winter}
        choices={[[true, t('Yes')], [false, t('No')]]}
      />
    </fieldset>
  )
}

export default QuoteEligibility