import { useContext, useEffect, useState } from 'react'
import { FormContext, AccountContext } from 'utils/context'
import { getAuthedRequest } from 'utils/requests'
import { parkedAtNightCarrierCodes } from 'utils/vars'
import * as Sentry from '@sentry/browser'
import { vehicleUse } from './vars'
import i18n from 'i18n'

const useUsage = () => {
  const { data, setFieldValue } = useContext(FormContext)
  const { vehicles, policies } = useContext(AccountContext)
  
  const [showAskChanges, setShowAskChanges] = useState([])
  const [summaryLabels, setSummaryLabels] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAuthedRequest('/api/lineitems/')
      .then(res => {
        const current = res.find(li => li.id === data.id)
        if (current.vehicle_changes) {
          setFieldValue('vehicle_changes', current.vehicle_changes)
          setIsLoading(false)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [data.vehicle_changes]) // eslint-disable-line

  useEffect(() => {
    const showAskChanges = []
    const summaryLabels = []

    data.vehicle_changes.forEach((newVehicle) => {
      
      const oldVehicle = vehicles.find(v => v.vin === newVehicle.vin)

      const policy = policies.find(p => p.id === data.policy)

      const showPleasure = oldVehicle.vehicle_use === 'pleasure' || oldVehicle.vehicle_use === 'commuting' || oldVehicle.vehicle_use === 'business'
      const showCommuting = oldVehicle.vehicle_use === 'commuting' || oldVehicle.vehicle_use === 'business'
      const showBusiness =  oldVehicle.vehicle_use === 'business'
      const showParkedAtNight = parkedAtNightCarrierCodes.has(policy.carrier_code)

      let oldVehicleUse = vehicleUse.filter(arr => arr.includes(oldVehicle.vehicle_use)).flatMap(arr => arr)
      const summaryPoints = [oldVehicleUse[1]]
      
      let showAskChange = true

      if (showPleasure) {
        if ([oldVehicle.annual_distance].some(distance => distance === null)) {
          setFieldValue(`${newVehicle.id}_same_usage`, false)
          showAskChange = false
        } else {
          summaryPoints.push(i18n.t('Driving {{numberOfKms}} annual KMs', {numberOfKms: oldVehicle.annual_distance}))
        }
      }

      if (showCommuting) {
        if ([oldVehicle.annual_distance, oldVehicle.commute_distance].some(distance => distance === null)) {
          setFieldValue(`${newVehicle.id}_same_usage`, false)
          showAskChange = false
        } else {
          summaryPoints.push(i18n.t('Driving {{numberOfKms}} KMs each way to work or school daily', {numberOfKms: oldVehicle.commute_distance}))
        }
      }

      if (showBusiness) {
        if ([oldVehicle.annual_distance, oldVehicle.commute_distance, oldVehicle.bus_annual_distance].some(distance => distance === null)) {
          setFieldValue(`${newVehicle.id}_same_usage`, false)
          showAskChange = false
        } else {
          summaryPoints.push(i18n.t('Driving {{numberOfKms}} business annual KMs', {numberOfKms: oldVehicle.bus_annual_distance}))
        }
      }

      if (showParkedAtNight && oldVehicle.parked_overnight == null) {
        setFieldValue(`${newVehicle.id}_same_usage`, false)
        showAskChange = false
      }

      summaryLabels.push(summaryPoints)
      showAskChanges.push(showAskChange)
    })
    setSummaryLabels(summaryLabels)
    setShowAskChanges(showAskChanges)
  }, [data.vehicle_changes]) // eslint-disable-line
  
  return {showAskChanges, summaryLabels, isLoading}
}

export default useUsage