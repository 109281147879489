import React from 'react'

const PinkSlip = ({ className }) => <svg className={className || null} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <title>Pink Slip Icon</title>
  <path d="M401.637 245.912v14.86l-307.87.306v-14.86zM177.055 194.032h133.74v14.86h-133.74zM277.775 293.053h126.31v14.86h-126.31zM234.858 327.773h169.227v14.86H234.858zM91.454 325.763h74.3v14.86h-74.3zM92.024 294.483h89.16v14.86h-89.16z" />
  <path d="M463.525 149.452h-22.29v-22.29a7.43 7.43 0 00-7.43-7.43h-371.5a7.43 7.43 0 00-7.43 7.43v252.621a7.43 7.43 0 007.43 7.43h22.29v22.29a7.43 7.43 0 007.43 7.43h371.5a7.43 7.43 0 007.43-7.43v-252.62a7.43 7.43 0 00-7.43-7.43zM69.735 372.353v-237.76h356.64v237.76H69.735zm386.36 29.72H99.455v-14.86h334.35a7.43 7.43 0 007.43-7.43v-215.47h14.86v237.76z" />
  <path d="M202.635 164.312h111.45v14.86h-111.45zM172.915 164.312h14.86v14.86h-14.86zM204.054 292.96h45.242v14.86h-45.242z" />
  <g><path d="M187.81 326.433h21.022v14.86h-21.021z" /></g>
</svg>

export default PinkSlip
