import { useState, useContext, useEffect } from 'react'
import waiverDepreciationVisible from 'forms/utils/waiver-depreciation'
import glassCoverageVisible from 'forms/utils/glass-coverage'
import usageBasedVisible from 'forms/utils/usage-based'
import { FormContext, AccountContext } from 'utils/context'
import coverageFields from './coverageFields'

const useCoverage = () => {
  
  const { policies } = useContext(AccountContext)
  const { data } = useContext(FormContext)

  const showWaiver = new waiverDepreciationVisible(policies, data).show()
  const showGlassCov = new glassCoverageVisible(policies, data).show()
  const usageBased = new usageBasedVisible(policies, data).show()

  const [fields, setFields] = useState(coverageFields)

  useEffect(() => {
    setFields(coverageFields)
  },[data.coverage_coll, data.coverage_comp])

  useEffect(() => { 
    const removeField = fieldName => {
      const updatedFields = fields.filter(f => f.name !== fieldName)
      setFields(updatedFields)
    }

    if (!showWaiver.show) {
      if (fields.find(f => f.name === 'waiver_depreciation') !== undefined) {
        removeField('waiver_depreciation')
      }
    }

    if (!showGlassCov.show) {
      if (fields.find(f => f.name === 'glass_coverage') !== undefined) {
        removeField('glass_coverage')
      }
    }

    if (!usageBased.show) {
      if (fields.find(f => f.name === 'usage_based') !== undefined) {
        removeField('usage_based')
      }
    }

  }, [policies, data, showWaiver, showGlassCov, fields])  // eslint-disable-line

  return {
    fields,
  }
}

export default useCoverage