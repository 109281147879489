import Link from 'components/Buttons/Link'
import { useTranslation } from 'react-i18next'

const FourOhFour = () => {
  const { t } = useTranslation()

return (
  <div className="container">
    <h2>{t('Page not found')}</h2>
    <p>{t("We couldn't find that page.")}</p>
    <p>
      <Link to="/"Pill Accent>{t('Home Page')}</Link>
    </p>
  </div>
)}

export default FourOhFour