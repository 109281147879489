export const hasDriversAndVehicles = (vehicles, drivers) => {
  if (vehicles && drivers) {
    return vehicles.length > 0 && drivers.length > 0
  }
  return false
}

export const hasLocations = (locations) => {
  if (locations) {
    return locations.length > 0
  }
  return false
}

export const hasBilling = (billing) => {
  return Object.keys(billing).length > 0
}

export const getPolicyValue = (policyId, policies = [], key) => {
  if (policies.length > 0) {
    const policy = policies.find(p => p.id === policyId)
    if (policy && policy[key]) {
      return policy[key]
    }
  }
  return null
}

