import { useContext, useEffect } from 'react'
import Text from 'forms/fields/Text'
import Radio from 'forms/fields/Radio'
import MultiCheckbox from 'forms/fields/MultiCheckbox'
import { conditionChoices } from 'utils/vars'
import { maxPrice } from 'forms/utils/cleans'
import { AccountContext, FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const QuoteVehicleDetails = ({parentData}) => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError, clearAllErrors } = useContext(FormContext)
  const { drivers } = useContext(AccountContext)
    
  const driversToShow = drivers.filter(d => parentData.policy === d.policy)
  const choices = driversToShow.map(d => [d.driver_id, d.full_name])

  choices.push([0, t('Other')])

  const conditionCallback = value => {
    if (value === 'used') {
      setFieldValue('kms_at_purchase', null)
      clearFieldError('kms_at_purchase')
    }
  }
  
  const RegOwnersCallback = value => {
    if (value.includes(0)) {
      clearAllErrors()
      setFieldValue('registered_owner_other', null)
    }
  }
  
  useEffect(() => {
    if (data.registered_owners) {
      let ownerInPolicy = driversToShow.some(d => data.registered_owners[0] === d.driver_id)
      if (!ownerInPolicy) {
        setFieldValue('registered_owners', [])
      }
    }
  }, []) // eslint-disable-line

  const showKms = data['vehicle_condition'] === 'new' || data['vehicle_condition'] === 'demo'

  return (
    <>
      <MultiCheckbox
        name="registered_owners"
        label={t("Who will be the registered owner(s)?")}
        ChangeCallback={RegOwnersCallback}
        choices={choices}
      />
      {data['registered_owners'] && data['registered_owners'].includes(0) && (
        <Text
          name="registered_owner_other"
          label={t("Other")}
        />
      )}
      <Text
        PriceField
        name="price_of_vehicle"
        label={t("What is the estimated price of this vehicle (with tax, before trade-in)?")}
        cleans={[maxPrice]}
      />
      <Radio
        name="vehicle_condition"
        label={t("What is the condition of this vehicle?")}
        choices={conditionChoices}
        ChangeCallback={conditionCallback}
      />
      {showKms && <Text
        KmField
        name="kms_at_purchase"
        label={t("Vehicle KMs at time of purchase")}
        maxLength={6}
      />}
    </>
  )
}

export default QuoteVehicleDetails