import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext, AccountContext } from 'utils/context'
import { formatCurrency, formatNumber } from 'utils/helpers'
import { conditionChoices } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const VehicleInformationConfirm = () => {

  const { t } = useTranslation()
  const { drivers } = useContext(AccountContext)
  const { data } = useContext(FormContext)

  const vehicles = data['quote_vehicles'].length > 0 ? data['quote_vehicles'] : []

  const title = vehicles.length > 1 ? t('Quote Vehicles') : t('Quote Vehicle')
  
  const kms = item => {
    if (item.vehicle_condition === 'new' || item.vehicle_condition === 'demo') {
      return `${formatNumber(item.kms_at_purchase, '0,0')}km`
    }
    return null
  }

  let vehicleCondition = condition => {return conditionChoices.filter(arr => arr.includes(condition)).flatMap(arr => arr)}
  
  let driverToShow = []
  vehicles.map((x, i) => {
    return drivers.filter(d => x.registered_owners.some(j => j === d.driver_id))
  })[0].forEach(d=> driverToShow.push(d.full_name, " "))
  
  const driversToShow = (vehicle) =>{
    let driversToShow = []
    drivers.filter(d => vehicle.registered_owners.some(j => j === d.driver_id)).forEach((d, index)=> driversToShow.push(index ? ', ' : '', d.full_name))
    if(vehicle.registered_owners.includes(0)){
      driversToShow.push(driversToShow.length > 0 ? ", " : "", vehicle.registered_owner_other)
    }
    return driversToShow
  }
  
  return (
    <ConfirmCard title={title} slug="vehicle-lookup" id={data.id}>
      <ul>
        {vehicles.map((v, i) =>
          <li key={v.id}>
            <small className="uppercase">{v.year} {v.make}</small>
            <p className="secondary-d2 font-p2">
              {v.model_label || v.model}<br />
              <small className="grey-l1">
                {formatCurrency(v.price_of_vehicle, '$0,0')}
                {vehicleCondition(v.vehicle_condition)[1] && ', ' + vehicleCondition(v.vehicle_condition)[1]}{kms(v) && `, ${kms(v)}`} <br/>
                {t("Registered Owners:")} {driversToShow(v)}
              </small>
            </p>
          </li>
        )}
      </ul>
    </ConfirmCard>
  )
}

export default VehicleInformationConfirm