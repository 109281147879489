import { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import QuestionSet from './QuestionSet'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const Selector = () => {
  const { t } = useTranslation()
  const { requestType } = useParams()

  const { allowedRequestTypes, updateCurrentRequest } = useContext(AccountContext)

  const primaryLabel = () => {
    if (requestType === 'question') {
      return t('What kind of question are you asking?')
    } else if (requestType === 'quote') {
      return t('What would you like a quote for?')
    }
    return t('What change would you like to make?')
  }

  useEffect(() => {
    updateCurrentRequest({ requestType: requestType, lineitemUrl: null })
  }, []) // eslint-disable-line

  return (
    <div>
      <div className="field radio stacked">
        <label className="main-label">
          {primaryLabel()}
        </label>
        <div className="field-wrapper">
          <div className="choices">
            <QuestionSet questions={allowedRequestTypes()[requestType]} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Selector