import React from 'react'
import { Trans } from 'react-i18next'
import './style.css'

const PasswordCopy = () => {
  return (
    <small className="help-copy PasswordCopy">
      <Trans
        i18nKey="passwordCopy"
        components={{ s: <strong />, br: <br />}}
      />
    </small>
  )
}

export default PasswordCopy