import React from 'react'
import { parseISO, isBefore, isAfter, isValid } from 'date-fns'
import { dateFromString, translateDate } from 'utils/helpers' 
import { femaleMonth } from 'forms/utils/helpers' 
import { twoDigitYearToFour } from 'utils/helpers' 
import TenantUrl from 'components/TenantUrl'
import i18n from 'i18n'

export const lettersNumbersOnly = value => {
  if (value) {
    const alphanumeric = new RegExp('^([a-zA-Z0-9]+)$')
    if (!value.match(alphanumeric)) {
      let err = new Error(i18n.t('Must contain only numbers and letters'))
      throw err
    }
  }
}

export const zeroAnnualKms = value => {
  if (value) {
    if (value === '0') {
      let err = new Error(<span>If you plan on storing this vehicle, please <TenantUrl Contact/></span>)
      throw err
    }
  }
}

export const zeroBusinessKms = value => {
  if (value) {
    if (value === '0') {
      let err = new Error(i18n.t('If this vehicle is not used for business purposes, please make another selection for vehicle usage above.'))
      throw err
    }
  }
}

export const emailAddress = value => {
  if (!value.includes('@') || !value.includes('.')) {
    throw new Error(i18n.t('Invalid email address'))
  }
}

export const postalCode = value => {
  const first = 'abceghjklmnprstvxy'
  const second = 'abceghjklmnprstvwxyz'
  const postalRegex = new RegExp(`^[${first}]\\d[${second}]( )?\\d[${second}]\\d$`, 'i')
  if (!value.match(postalRegex)) {
    let err = new Error(i18n.t('Invalid postal code'))
    throw err
  }
}

export const maxPrice = value => {
  let err = new Error('Price appears to be invalid')
  var pattern = "(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$"
  const priceRegex = new RegExp(pattern, 'i')
  if (!value.match(priceRegex)) {
    throw err
  }
  let simpleValue = value.replace('$', '').replace(',', '')
  if (Number(simpleValue) > 1000000) {
    throw err
  }
}

export const phoneNumber = value => {
  let phoneRegex = new RegExp('^1?[ .-]?[(]?[0-9]{3}[)]?[ .-]?[0-9]{3}[ .-]?[0-9]{4}$')
  if (!value.match(phoneRegex)) {
    let err = new Error(i18n.t('Please input a valid phone number including the area code'))
    throw err
  }
}

/* password cleans */

export const uppercaseChars = value => {
  let upper = new RegExp(/[A-Z]/g)
  if (!value.match(upper)) {
    let err = new Error(i18n.t('Passwords must contain at least one uppercase character'))
    throw err
  }
}

export const number = value => {
  let num = new RegExp(/[0-9]/g)
  if (!value.match(num)) {
    let err = new Error(i18n.t('Passwords must contain at least one number'))
    throw err
  }
}

/* licence cleans */

export const albertaLicence = value => {
  let licRegex = new RegExp('^[0-9]{9}$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const newBrunswickLicence = value => {
  let licRegex = new RegExp('^(?:[0-9]{4}|[0-9]{7})$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const newfoundlandLicence = value => {
  let licRegex = new RegExp('^[A-Za-z][0-9]{9}$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const novaScotiaLicence = value => {
  let licRegex = new RegExp('^[a-zA-Z*]{5}[0-9]{9}$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const ontarioLicence = value => {
  let licRegex = new RegExp('^[A-Za-z][0-9]{14}$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const peiLicence = value => {
  let licRegex = new RegExp('^[0-9]{6}$')
  if (!value.match(licRegex)) {
    let err = new Error(i18n.t("Invalid driver's licence"))
    throw err
  }
}

export const licenceAndLastName = (licence, lastName) => {
  if (licence && lastName) {
    let licenceFirst = licence.charAt(0).toLowerCase()
    let lastNameFirst = lastName.charAt(0).toLowerCase()
    if (licenceFirst !== lastNameFirst) {
      let err = new Error(i18n.t('First character of licence should match the first letter of last name'))
      throw err
    }
  }
}

export const nsLicenceAndLastName = (licence, lastName) => {
  if (licence && lastName) {
    let licenceChars = licence.substring(0, 5).replace(/\*/g, '').toLowerCase()
    lastName = lastName.length >= 5 ? lastName.substring(0, 5).toLowerCase() : lastName.toLowerCase()
    if (licenceChars !== lastName) {
      let err = new Error(i18n.t('First 5 characters of licence should match the last name'))
      throw err
    }
  }
}

export const validDateFromLicence = value => {
  const date = value.substr(value.length - 6)
  const { year, month, day } = dateFromString(date)
  const finalDate = parseISO(`${year}-${femaleMonth(month)}-${day}`)
  if (!isValid(finalDate)) {
    let err = new Error(i18n.t('Licence Number appears to be incorrect'))
    throw err
  }
}

export const reverseDateFromString = (date) => {
  if (date) {
    const day = date.substring(0, 2)
    const month = date.substring(2, 4)
    const year = date.substring(4, 6)
    return { year: twoDigitYearToFour(year), month, day }
  }
}

export const validNsDateFromLicence = value => {
  let date = value.substring(5, 11)
  const { year, month, day } = reverseDateFromString(date)
  const finalDate = parseISO(`${year}-${month}-${day}`)
  if (!isValid(finalDate)) {
    let err = new Error(i18n.t('Licence Number appears to be invalid'))
    throw err
  }
}

export const validNlDateFromLicence = value => {
  const date = value.substr(1, 6)
  const { year, month, day } = dateFromString(date)
  const finalDate = parseISO(`${year}-${month}-${day}`)
  if (!isValid(finalDate)) {
    let err = new Error(i18n.t('Licence Number appears to be incorrect'))
    throw err
  }
}

export const validDate = value => {
  let dateRegex = /^\d{4}-\d{1,2}-\d{1,2}$/
  if (!value.match(dateRegex)) {
    let err = new Error('Invalid date')
    throw err
  }
  let date = new Date(value)
  if (!isValid(date)) {
    let err = new Error(i18n.t('Invalid date, please use YYYY-MM-DD '))
    throw err
  }
}

export const notFuture = value => {
  let date = new Date(value)
  let today = new Date()
  if (isAfter(date, today)) {
    let err = new Error(i18n.t('Cannot be in the future'))
    throw err
  }
}

export const notPast = value => {
  let date = new Date(value)
  let today = new Date()
  if (isBefore(date, today)) {
    let err = new Error(i18n.t('Cannot be in the past'))
    throw err
  }
}

export const afterMin = (date, minDate) => {
  if (isBefore(date, minDate)) {
    let err = i18n.t('mustBeAfter', {date: translateDate(minDate, 'MMM Do, yyyy')})
    throw new Error(err)
  }
}

export const beforeMax = (date, maxDate) => {
  if (isAfter(date, maxDate)) {
    let err = i18n.t('mustBeBefore', {date: translateDate(maxDate, 'MMM Do, yyyy')})
    throw new Error(err)
  }
}

/*Tag number clean*/
export const eightCharsTag = value => {
  if (typeof value === 'string') {
    if (value.length !== 8) {
      let err = new Error(i18n.t('Tag number must be eight characters'))
      throw err
    }
  }
}
