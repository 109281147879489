import React, { useContext } from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import GetStarted from 'forms/fieldsets/general/GetStarted'
import AbInformation from 'forms/fieldsets/driver/AbInformation'
import NbInformation from 'forms/fieldsets/driver/NbInformation'
import NlInformation from 'forms/fieldsets/driver/NlInformation'
import NsInformation from 'forms/fieldsets/driver/NsInformation'
import OnInformation from 'forms/fieldsets/driver/OnInformation'
import PeInformation from 'forms/fieldsets/driver/PeInformation'
import DriverLicensing from 'forms/fieldsets/driver/DriverLicensing'
import DriverHistory from 'forms/fieldsets/driver/DriverHistory'
import InsuranceHistory from 'forms/fieldsets/driver/InsuranceHistory'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import Consent from 'forms/fieldsets/driver/Consent'
import DriverAddConfirm from 'forms/fieldsets/driver/DriverAddConfirm'
import OwnerDriver from 'forms/fieldsets/vehicle/OwnerDriver'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './styles/driver-add.scss'

const DriverAdd = () => {
  
  const { t } = useTranslation()
  let { lineitemProvince, vehicles } = useContext(AccountContext)
  
  const getInfoFieldset = () => {
    switch (lineitemProvince) {
      case 'AB':
        return <AbInformation vehicles={vehicles} path="driver-information" />
      case 'NB':
        return <NbInformation vehicles={vehicles} path="driver-information" />
      case 'NL':
        return <NlInformation vehicles={vehicles} path="driver-information" />
      case 'NS':
        return <NsInformation vehicles={vehicles} path="driver-information" />
      case 'PE':
        return <PeInformation vehicles={vehicles} path="driver-information" />
      default:
        return <OnInformation vehicles={vehicles} path="driver-information" />
    }
  }

  return (
    <MultiPageForm Province={lineitemProvince}>
      <Requirements path="requirements" requestType="driver-add" />
      <GetStarted
        path="get-started"
        getStartedPoliciesLabel={t('To which policy would you like to add a driver?')}
        getStartedDateLabel={t('When would you like to add this driver to your policy?')}
      />
      {getInfoFieldset()}
      <DriverLicensing path="driver-licensing" province={lineitemProvince} />
      <DriverHistory path="driver-history" />
      <InsuranceHistory path="insurance-history" />
      <OwnerDriver path="driver-assignment" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('With the addition of another driver, the use of the vehicle(s) may also change. Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <Consent path="consent" />
      <DriverAddConfirm path="confirm" province={lineitemProvince} />
    </MultiPageForm>
  )
}

export default DriverAdd