import React, { useState, useEffect } from 'react'
import Popover from 'components/Popover'
import { postRequest } from 'utils/requests'
import { useTranslation } from 'react-i18next'
import './style.scss'

const PinksButton = ({ ids, hasPink, Right }) => {

  const { t } = useTranslation()
  const [url, setUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      try {
        if (hasPink) {
          const response = await postRequest('/api/pink-slips/', { vehicles: ids }, controller)
          setUrl(response.content.url)
          setLoading(false)
          controller = null
        } else {
          setLoading(false)
        }
      } catch (e) {
        console.log('PinksButton error: ', e)
      }
    })()
    return () => controller?.abort()
  }, [ids, hasPink]) // eslint-disable-line

  return hasPink ? (
    <div className={`PinksButton ${Right ? 'float-right' : ''}`} data-testid="has-pink">
      {loading ? <span>{t('Loading...')}</span> :
        <a className={`Link Pill Small Light`} href={url} target="_blank" rel="noopener noreferrer" data-ga-pink="single" aria-label={t('Download pink slip')}>
          {t('Pink Slip')}
        </a>
      }
    </div>
  ) : (
      <div className={`Link Pill Small Light FakeButton ${Right ? 'float-right' : ''}`} data-testid="no-pink">
        {t('Pink Slip')}
        <Popover copy={t('Pink slips not available')} link={t('Why not?')} url="/account/help#mypinkslips" />
      </div>
    )
}

export default PinksButton