import React from 'react'
import Button from 'components/Buttons/Button'
import Link from 'components/Buttons/Link'
import WarningIcon from 'utils/icons/Warning'
import * as Sentry from '@sentry/browser'
import i18n from 'i18n';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('caught by sentry in ErrorBoundary: ', error, errorInfo)
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary text-center">
          <WarningIcon />
          <h2 className="primary">{i18n.t('An error has occurred, please refresh or return to the home page')}</h2>
          <Button onClick={() => window.location.reload(false)} Small Pill DataTestId="refresh">{i18n.t('Refresh Now')}</Button>
          <Link to="/account" onClick={() => this.setState({ hasError: false })} Small Pill>{i18n.t('Policy Summary')}</Link>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary