import React from 'react'

import './style.scss'

const HelpIndex = ({ children }) => {
  return (
    <ul className="HelpIndex clearfix">
      {children}
    </ul>
  )
}

export default HelpIndex