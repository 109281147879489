import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { Waypoint } from 'react-waypoint'
import BasePage from 'components/BasePage'
import BackToTop from 'components/BackToTop'
import HelpIndex from 'components/HelpIndex'
import { useTranslation, Trans } from 'react-i18next'

const Lnk = ({ to, label }) => {

  return (
    <li>
      <HashLink smooth to={to}>{label}</HashLink>
    </li>
  )
}

const Help = () => {

  const [showScroller, setShowScroller] = useState(false)
  const { t } = useTranslation()

  return (
    <BasePage>
      <div className="SitePage container">
        <div>
          <HelpIndex>
            <Lnk to="#mypinkslips" label={t('I want to print my Pink Slips')} />
            <Lnk to="#passwordchange" label={t('I want to change my password')} />
            <Lnk to="#updateemail" label={t('I want to update my email address')} />
            <Lnk to="#policyinfo" label={t("I can't see my policy information")} />
            <Lnk to="#incorrectinfo" label={t('My policy information is incorrect')} />
            <Lnk to="#cantsubmit" label={t("Why can't I submit different types of requests?")} />
            <Lnk to="#cantchangeaddress" label={t("Why can't I change my address on a property policy?")} />
            <Lnk to="#infoforchange" label={t('Some of the information that you will require to complete a change online')} />
            <Lnk to="#whathappens" label={t('What happens after I submit a change online')} />
            <Lnk to="#existingdamage" label={t('I am adding/replacing a vehicle; why are you asking about existing damage?')} />
            <Lnk to="#vehiclemodified" label={t('I am adding/replacing a vehicle; why are you asking me if my new vehicle is modified?')} />
            <Lnk to="#wintertires" label={t('I am adding/replacing a vehicle; why are you asking about winter tires?')} />
          </HelpIndex>
        </div>
        
        <Waypoint onEnter={() => setShowScroller(true)} onLeave={() => setShowScroller(false)}>
          <div id="block">

            <hr />
            
            <h4 id="mypinkslips">{t('I want to print my Pink Slips')}</h4>
            <p>{t('You should see a link to your pink slips on your Policy Summary page, as well as your Policy Details page. Please note, in order to generate a Pink Slip the vehicle must carry liability insurance. If the vehicle has comprehensive only you will not be able to generate a pink slip.')}</p>
            <p>{t('If you are having trouble accessing your pink slip and you need your pink slips immediately, you can submit an online General Request or give us a call.')}</p>

            <hr />

            <h4 id="passwordchange">{t('I want to change my password')}</h4>
            <p>{t('If you visit your Profile page, you can change your password right there!')}</p>
            <p>{t('Next time you visit you can use your new password.')}</p>

            <hr />

            <h4 id="updateemail">{t('I want to update my email address')}</h4>
            <p>{t('If you need to update your email address, please submit a General Request online or you can give us a call. Once your email address is updated in our system, you will be able to log in with your new email address and same password.')}</p>

            <hr />

            <h4 id="policyinfo">{t("I can't see my policy information")}</h4>
            <p>{t('We’re sorry about that! If you can see some of your policy information, please submit a General Request and let us know. We may reach out out to you to confirm some of the details.')}</p>
            <p>{t('If you can’t see any policy information, please give us a call or email us to let us know.')}</p>

            <hr />

            <h4 id="incorrectinfo">{t('My policy information is incorrect')}</h4>
            <p>{t('We’re sorry about that! Please fill in a General Request or other change form and let us know what is incorrect. We may reach out to you to confirm some of the details.')}</p>

            <hr />

            <h4 id="cantsubmit">{t("Why can't I submit different types of requests?")}</h4>
            <p>{t('There could be a couple reasons why you can’t find a request online.')}</p>
            <p>{t('The first reason is that it depends on your personal situation, we do our best to ensure that you only see the things that you can complete online and not the things you cannot do. So for example, if you are currently insuring only 1 vehicle, you will not see an option to remove that vehicle. Once that vehicle is removed, the policy will cancel, and additional administrative actions must be carried out through your insurance company.')}</p>

            <hr />

            <h4 id="cantchangeaddress">{t("Why can't I change my address on a property policy?")}</h4>
            <p>{t('We want to make sure that your new property is insured as well as your current property. There are many questions that we want to ask you about your new home, and we think doing it over the phone will make it an easier process for you.')}</p>

            <hr />

            <h4 id="infoforchange">{t('Some of the information that you will require to complete a change online')}</h4>
            <p>{t('Insurance Company & Policy Number of Other Licensed Driver - If the other driver does not have their own auto insurance, they are required to be rated on your policy. If the other driver has their own insurance and is not a regular operator of your vehicle, their information will be taken for informational purposes only and they will not be rated on your policy. We will never use this information to solicit the other driver nor will we share this information with anyone other than your insurance provider.')}</p>
            <p>{t('Vehicle information (i.e. VIN, year, make, model, and trim) is required so that we can properly insure your vehicle. You can find your VIN: on your current insurance policy, on your vehicle (usually in the driver door and on your dashboard), or on your bill of sale or leasing agreement.')}</p>
            <p>{t('If your vehicle is leased or financed, we require the name and address of your financial institution. They are required to be listed on your insurance policy.')}</p>
            <p>{t('We require your licensing dates to make sure you are given the credit you deserve for your driving experience.')}</p>
            <p>{t('Licence suspension details are required because sometimes it can affect your insurance, but sometimes it doesn’t, so we want to get it right.')}</p>

            <hr />

            <h4 id="whathappens">{t('What happens after I submit a change online')}</h4>
            <p>{t('Once you submit a change online, we will review the details and make sure we have everything we need to properly service your insurance policy.')}</p>
            <p>{t('If we require additional information or clarification on something you submitted we will reach out to you. When submitting a change, we do ask you your communication preference, so we will start there when contacting you.')}</p>
            <p>
              <Trans
                i18nKey="emailToConfirmMessage"
                components={{s: <strong /> }}
              />
            </p>

            <hr />

            <h4 id="existingdamage">{t('I am adding/replacing a vehicle; why are you asking about existing damage?')}</h4>
            <p>{t('If we are not aware of such damage, it can slow down the claims process while insurance adjusters investigate how much damage was sustained in the loss and how much existed prior to the accident.')}</p>

            <hr />

            <h4 id="vehiclemodified">{t('I am adding/replacing a vehicle; why are you asking me if my new vehicle is modified?')}</h4>
            <p>{t('We want to make sure you’re properly covered and that you receive the maximum payout available in the event of a claim. If we are unaware of changes (i.e. aftermarket paint job) or modifications (i.e. wheelchair lift), your policy would only reflect a standard model. Therefore, a claim payout would not reflect the cost of the upgrades.')}</p>

            <hr />

            <h4 id="wintertires">{t('I am adding/replacing a vehicle; why are you asking about winter tires?')}</h4>
            <p>{t('Some insurance companies may offer a discount if all 4 tires on your vehicle are changed seasonally so that, during winter, the vehicle is equipped with tires designed specifically for driving on ice and snow.')}</p>
          </div>
        </Waypoint>
        <BackToTop visible={showScroller} />
      </div>
    </BasePage>
  )
}

export default Help