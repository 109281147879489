import React from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import ReduceCoverageGetStarted from 'forms/fieldsets/vehicle/ReduceCoverageGetStarted'
import ReduceReason from 'forms/fieldsets/vehicle/ReduceReason'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import CoverageReview from 'forms/fieldsets/vehicle/CoverageReview'
import CoverageWarning from 'forms/fieldsets/vehicle/CoverageWarning'
import ReduceCoverageConfirm from 'forms/fieldsets/vehicle/ReduceCoverageConfirm'
import { useTranslation } from 'react-i18next'


const ReduceCoverage = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="reduce-coverage" />
      <ReduceCoverageGetStarted path="get-started"/>
      <ReduceReason path="reduce-reason"/>
      <ChangedDetails path="policy-changes"
        helpCopy={t('Reducing coverage on a vehicle from a policy may result in a change in how you use your remaining vehicle(s). Please confirm or update your vehicle usage below.')}/>
      <PrincipalDrivers path="principal-drivers"
        helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}/>
      <CoverageReview path="coverage-review"/>
      <CoverageWarning path="warning-confirm"/>
      <ReduceCoverageConfirm path="confirm"/>
    </MultiPageForm>
  )
}

export default ReduceCoverage
