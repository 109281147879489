import Olark from 'utils/olark'

import './style.scss'

const OlarkWrapper = ({ tenant = null }) => {
  return tenant && tenant.olarkId ? (
    <Olark
      siteId={tenant.olarkId}
      systemConfig={{ group: tenant.olarkGroup }}
    />
  ) : null
}

export default OlarkWrapper