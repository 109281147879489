import React from 'react'

import './style.scss'

const LoadingSpinner = () => {
  return (
    <div className="LoadingSpinner">
      <span className="first" />
      <span className="second" />
      <span className="third" />
    </div>
  )
}

export default LoadingSpinner