import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { isLocal } from 'utils/helpers'
import enDefault from './locales/en/translation.json';
import frDefault from './locales/fr/translation.json';
import enCis from './locales/en/cis.json';
import frCis from './locales/fr/cis.json';
import enHubBpo from './locales/en/hub_bpo.json';
import frHubBpo from './locales/fr/hub_bpo.json';
import enIhs from './locales/en/ihs.json';
import frIhs from './locales/fr/ihs.json';
import enInova from './locales/en/inova.json';
import frInova from './locales/fr/inova.json';
import enSmartcov from './locales/en/smartcov.json';
import frSmartcov from './locales/fr/smartcov.json';
import enVertis from './locales/en/vertis.json';
import frVertis from './locales/fr/vertis.json';
import enEasy from './locales/en/easy.json';
import frEasy from './locales/fr/easy.json';

// import { i18nextPlugin } from 'translation-check';
// .use(i18nextPlugin)      // https://github.com/locize/translation-check


const allTranslations = {
  en: {
    translation: enDefault,
    cis: enCis,
    hub_bpo: enHubBpo,
    ihs: enIhs,
    inova: enInova,
    smartcov: enSmartcov,
    vertis: enVertis,
    easy: enEasy,
  },
  fr: {
    translation: frDefault,
    cis: frCis,
    hub_bpo: frHubBpo,
    ihs: frIhs,
    inova: frInova,
    smartcov: frSmartcov,
    vertis: frVertis,
    easy: frEasy,
  },
}


i18n                       // https://github.com/i18next/i18next
  .use(initReactI18next)   // https://github.com/i18next/react-i18next
  .use(LanguageDetector)   // https://github.com/i18next/i18next-browser-languageDetector
  .init({
    // i18next options
    debug: isLocal,
    resources: allTranslations,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'], // Also update "locales" settings in i18next-parser.config.js
    keySeparator: false, // Also update "keySeparator" settings in i18next-parser.config.js
    nsSeparator: '::', // Also update "namespaceSeparator" settings in i18next-parser.config.js
    pluralSeparator: '__', // Also update "pluralSeparator" settings in i18next-parser.config.js
    returnEmptyString: false, // If a translated value is empty string, then just show the translation key

    // Browser language detection options
    // https://github.com/i18next/i18next-browser-languageDetector#detector-options
    detection: {
      // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      // lookupQuerystring: 'lng',
      // lookupCookie: 'i18next',
      // lookupLocalStorage: 'i18nextLng',
      // lookupSessionStorage: 'i18nextLng',
      caches: [],
      // caches: ['localStorage'],
      // excludeCacheFor: ['cimode'],
      // cookieMinutes: 10,
      // cookieDomain: 'myDomain'
    },

  });


export default i18n;