import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { AccountContext, FormContext } from 'utils/context'
import { parkedAtNightConfirm } from 'forms/utils/vars'
import { Trans, useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/helpers'

const getFromVehicleOrData = (vehicle, data, prefix, field) => {
  if (data[`${prefix}_${field}`]) {
    return data[`${prefix}_${field}`]
  } else if (vehicle[field]) {
    return vehicle[field]
  }
  return null
}

const UsageBlock = ({ vehicle, prefix, data }) => {
  
  const { t } = useTranslation()

  let acct = useContext(AccountContext)

  let usage = getFromVehicleOrData(vehicle, data, prefix, 'usage')
  let annual = getFromVehicleOrData(vehicle, data, prefix, 'annual_kms')
  let daily = getFromVehicleOrData(vehicle, data, prefix, 'daily_kms')
  let business = getFromVehicleOrData(vehicle, data, prefix, 'business_annual_kms')
  let parked = getFromVehicleOrData(vehicle, data, prefix, 'parked_overnight')
  let commuteVehicle = getFromVehicleOrData(vehicle, data, prefix, 'commute_vehicle')

  const showCommuting = usage === 'commuting'
  const showBusiness = usage === 'business'
  const showPleasure = usage === 'pleasure'

  if (parked) {
    parked = parkedAtNightConfirm[parked]
  }

  const getVehicle = vin => {
    const single = acct.vehicles.find(v => v.vin === vin)
    return single ? `${single.full_vehicle_name}` : vin
  }
  
  return (
    <li>
      <h5>{getVehicle(vehicle.vin)}</h5>
      {usage && (
        <span className="UsageKms">
          {showCommuting && <Trans i18nKey="usageCommute"/>}
          {showBusiness && <Trans i18nKey="usageBusiness"/>}
          {showPleasure && <Trans i18nKey="usagePleasure"/>}
        </span>
      )}
      {annual && (
        <span className="UsageKms">
          <Trans i18nKey="usageKmsAnnual" values={{ numberOfKms: formatNumber(annual, '0,0') }}/>
        </span>
      )}
      {showCommuting && daily && (
        <span className="UsageKms">
          <Trans i18nKey="usageKmsDaily" values={{ numberOfKms: formatNumber(daily, '0,0')}} />
        </span>
      )}
      {showBusiness && business && (
        <span className="UsageKms">
          <Trans i18nKey="usageKmsBusiness" values={{ numberOfKms: formatNumber(business, '0,0')}} />
        </span>
      )}
      {parked && (
        <span className="UsageKms">
          {parked}
        </span>
      )}
      {commuteVehicle && (
        <span className="UsageKms">
          {t("Get to work or school using:")} {commuteVehicle}
        </span>
      )}
    </li>
  )
}

const PolicyChangesConfirm = () => {
  let { data } = useContext(FormContext)
  const { vehicles } = useContext(AccountContext)
  const { t } = useTranslation()
  
  const vehicleBeingReplaced = vehicles.find(v => v.vehicle_id === Number(data['vehicle_id']))
  
  return (
    <>
      <ConfirmCard title={t("Policy Changes")} slug="policy-changes" id={data.id}>
        <div className="UsageConfirm">
          <ul className="SimpleList">
            {data['vehicle_changes'].filter((vehicle) => vehicle.vin !== vehicleBeingReplaced?.vin).map(v => (
              <UsageBlock
                key={v.id}
                vehicle={v}
                prefix={v.id}
                data={data}
              />
            ))}
          </ul>
        </div>
      </ConfirmCard>
    </>
  )
}

export default PolicyChangesConfirm