import React, { useState, useContext } from 'react'
import CoverageItem from './CoverageItem'
import { standard, optional, accident, endorsement, coveragePackages, coverageCopy } from 'utils/vars'
import { AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const isPak = code => code.toLowerCase().includes('pak') 

const CoverageList = ({ vehicleId }) => {

  const { t } = useTranslation()
  const acct = useContext(AccountContext)

  const [activeTooltip, setActiveTooltip] = useState('')

  const vehicleCovs = acct.coverages.filter(c => {
    return c.vehicle === vehicleId
  })

  const coverageArray = () => {
    const final = []
    vehicleCovs.forEach(c => {
      if (isPak(c.coverage_code)) {
        let packageCodes = coveragePackages[c.coverage_code]
        if (packageCodes) {
          packageCodes.forEach(packageCode => {
            final.push({
              'coverage_code': packageCode,
              ...coverageCopy[packageCode],
            })
          })
        }
      } else {
        final.push({
          ...c,
          ...coverageCopy[c.coverage_code],
        })
      }
    })

    return final
  }

  const getList = list => {
    const final = []
    list.forEach(i => {
      const covItem = coverageArray().find(c => c && c.coverage_code === i)
      if (covItem) {
        final.push(covItem)
      }
    })
    return final
  }

  return (
    <div className="TileSection CoverageLists">
      {getList(standard).length > 0 && (
        <>
          <strong className="CoveragesTitle">{t('Standard Coverage')}</strong>
          <ul className={getList(standard).length === 1 ? 'zebra-list one-entry' : 'zebra-list'}>
            {getList(standard).map(i =>
              <CoverageItem
                key={i.coverage_code}
                item={i}
                activeTooltip={activeTooltip}
                setActiveTooltip={setActiveTooltip}
              />
            )}
          </ul>
        </>
      )}
      {getList(optional).length > 0 && (
        <>
          <strong className="CoveragesTitle">{t('Optional Coverage')}</strong>
          <ul className={getList(optional).length === 1 ? 'zebra-list one-entry' : 'zebra-list'}>
            {getList(optional).map(i =>
              <CoverageItem
                key={i.coverage_code}
                item={i}
                activeTooltip={activeTooltip}
                setActiveTooltip={setActiveTooltip}
              />
            )}
          </ul>
        </>
      )}
      {getList(accident).length > 0 && (
        <>
          <strong className="CoveragesTitle">{t('Optional Accident Benefits')}</strong>
          <ul className={getList(accident).length === 1 ? 'zebra-list one-entry' : 'zebra-list'}>
            {getList(accident).map(i =>
              <CoverageItem
                key={i.coverage_code}
                item={i}
                activeTooltip={activeTooltip}
                setActiveTooltip={setActiveTooltip}
              />
            )}
          </ul>
        </>
      )}
      {getList(endorsement).length > 0 && (
        <>
          <strong className="CoveragesTitle">{t('Endorsement')}</strong>
          <ul className={getList(endorsement).length === 1 ? 'zebra-list one-entry' : 'zebra-list'}>
            {getList(endorsement).map(i =>
              <CoverageItem
                key={i.coverage_code}
                item={i}
                activeTooltip={activeTooltip}
                setActiveTooltip={setActiveTooltip}
              />
            )}
          </ul>
        </>
      )}
    </div>
  )
}

export default CoverageList