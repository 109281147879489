import './style.scss'

const Divider = ({ Small }) => {
  return (
    <div className={Small ? 'Divider Small' : 'Divider'} aria-hidden={true}>
      <hr />
    </div>
  )
}

export default Divider