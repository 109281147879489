import MultiPageForm from 'forms/MultiPageForm'
import RequestType from 'forms/fieldsets/billing/RequestType'
import PreferredContact from 'forms/fieldsets/general/PreferredContact'

const BillingRequest = () => (
  <MultiPageForm>
    <RequestType path="request-type" />
    <PreferredContact path="preferred-contact" />
  </MultiPageForm>
)

export default BillingRequest