import React from 'react'
import Radio from 'forms/fields/Radio'
import { useTranslation } from 'react-i18next'

const Consent = () => {
  const { t } = useTranslation()
  return (
    <fieldset>
      <p className="fieldset-intro">
        <small>{t('We are required to verify the information by running reports on the driver information provided. Some insurers may require up to 25 years of personal information such as driving record and claims history. Should any information differ, it could cause a change in premium or eligibility.')}</small>
      </p>
      <Radio
        name="consent"
        label={t('Do you consent?')}
        choices={[
          [true, t('Yes, I consent')],
          [false, t('No, I do not consent')],
        ]}
        Stacked
      />
      <span className="help-copy">{t("We require consent from the policy holder, in order to add a driver to their auto policy. Once consent is provided, a team member will review reports on the driver's licence and insurance history. We do this in order to provide the correct rating for the driver being added to your policy, and to ensure you are receiving the correct insurance coverage.")}</span>
    </fieldset>
  )
}

export default Consent