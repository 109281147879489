import { useContext, useRef, useCallback, useState, useEffect } from 'react'
import { Formio } from 'formiojs';
import { Form } from '@formio/react'
import { isStage, isLocal } from 'utils/helpers'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './style.scss'

const Omnichannel = ({location, dynamic}) => {
  const { t } = useTranslation()
  const { tenant } = useContext(AppContext)

  const [loaded, setLoaded] = useState(false)
  const [validFormName, setValidFormName] = useState(false)
  const [formioUrl, setFormioUrl] = useState('')

  const formRef = useRef(null)

  let formName = 'letsconnect'
  let formioDomain = (isStage || isLocal) ? 'https://xyurztbdedkwmlv.form.io/':'https://njcrwmbyfaaqryy.form.io/'

  useEffect(() => {
    if (dynamic) {
      const query = new URLSearchParams(location.search)
      formName = query.get('form') || ''
      formName = formName.replace(/[^a-zA-Z0-9-]/gi, '')
      let dynamicUrl = `${formioDomain}${formName}`
  
      Formio.request(dynamicUrl).then(function() {
        setFormioUrl(dynamicUrl)
        setLoaded(true)
        setValidFormName(true)
      }).catch(function() {
        setLoaded(true)
        setValidFormName(false)
      });
    } else {
      setFormioUrl(`${formioDomain}${formName}`)
      setLoaded(true)
      setValidFormName(true)
    }
  }, []);

  const onFormReady = useCallback((form) => {
    formRef.current = form
  }, [])

  const changeHandler = (submission) => {
    const wrapper = document.querySelector('.Wrapper')
    if (submission.data.newClient === "Get a Quote") {
      wrapper.classList.add('nav-hidden')
    } else {
      wrapper.classList.remove('nav-hidden')
    }
  }

  const handleSubmit = () => {
    const wrapper = document.querySelector('.Wrapper')
    wrapper.classList.add('modal-active')
  }

  const next = ({ page }) => {
    const wrapper = document.querySelector('.Wrapper')
    if (page > 1 && page + 1 === formRef.current.pages.length) {
      wrapper.classList.add('submit-visible')
    } else {
      wrapper.classList.remove('submit-visible')
    }
  }

  return (
    <div className="container">
      {
        loaded
        ? (
            validFormName
            ? <Form
                src={formioUrl}
                onChange={changeHandler}
                onNextPage={next}
                formReady={onFormReady}
                onSubmit={handleSubmit}
              />
            : <div className="text-center">{t('Error loading form')}</div>
          )
        : <div className="text-center">{t('Loading')}</div>
      }
      
      <div className="formio-modal">
        <div>
          <div className="modal-header">
            <h3>{t('Success!')}</h3>
            <a href={tenant.brochure_url} className="CloseButton">
              <span className="sr-only">{t('Close')}</span>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 15.3a1.2 1.2 0 1 1-1.7 1.7L12 13.7 8.7 17A1.2 1.2 0 0 1 7 15.3l3.3-3.3L7 8.7A1.2 1.2 0 0 1 8.7 7l3.3 3.3L15.3 7A1.2 1.2 0 1 1 17 8.7L13.7 12l3.3 3.3z" />
              </svg>
            </a>
          </div>
          <p>{t('Your form has been submitted')}</p>
          <div style={{ textAlign: 'right' }}>
            <a href={tenant.brochure_url} className="Btn Pill">{t('Go Back')}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Omnichannel
