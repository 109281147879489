import TenantUrl from 'components/TenantUrl'
import WarningIcon from 'utils/icons/Warning'
import { Trans } from 'react-i18next'

const JasmineError = ({ type }) => {

  const addressOrVehicle = type === 'VIN' ? 
  <Trans i18nKey='notFoundVehicle' 
  values={{vin: type}}
  components={{phone: <TenantUrl Phone />, email: <TenantUrl Email />}}/> : 
  <Trans i18nKey='notFoundAddress' 
  values={{address: type}}
  components={{phone: <TenantUrl Phone />, email: <TenantUrl Email />}}/>
  
  return (
    <div className="form-error">
      <WarningIcon />
      {addressOrVehicle}
    </div>
  )
}

export default JasmineError