import { useContext } from 'react'
import { parseISO, isValid, isAfter } from 'date-fns'
import Select from 'forms/fields/Select'
import TriDate from 'forms/fields/TriDate'
import { cancellationChoices } from 'forms/utils/vars'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'


const InsuranceHistorySubForm = ({ parentData }) => {

  const { t } = useTranslation()
  const { data, setFieldError } = useContext(FormContext)

  const checkDates = (value, name) => {
    const cancellation = data['cancellation_date']
    const reinstatement = data['insurance_reinstatement_date']
    if(reinstatement){
      if (isValid(parseISO(cancellation)) && isValid(parseISO(reinstatement))) {
        if (!isAfter(parseISO(reinstatement), parseISO(cancellation))) {
          setFieldError(name, t('Reinstatement date must be after cancellation date'))
        }
      }
    }
  }

  return (
    <>
      <Select
        name="cancellation_reason"
        choices={cancellationChoices}
        label={t('Reason for cancellation')}
      />
      <TriDate
        name="cancellation_date"
        label={t('Cancellation Date')}
        BlurCallback={checkDates}
      />
      <TriDate
        name="insurance_reinstatement_date"
        label={t('Reinstatement Date')}
        BlurCallback={checkDates}
        HelpCopy={t("Please leave the reinstatement questions blank if you do not have an active policy.")}
      />
    </>
  )
}

export default InsuranceHistorySubForm
