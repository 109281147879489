import { useContext } from 'react'
import BillingTextArea from 'forms/fields/BillingTextArea'
import Policies from 'forms/fields/Policies'
import { FormContext } from 'utils/context'
import BOLBankingHelpImage from 'utils/images/BOL_banking_help.png'
import BOLBankingHelpImageFR from 'utils/images/BOL_banking_help_FR.png'
import { useTranslation } from 'react-i18next'
const RequestType = () => {

  const { t, i18n } = useTranslation()
  const { data } = useContext(FormContext)
  return (
    <fieldset>
      <Policies
        name="requested_policies"
        label={t('Which of your policies are you inquiring about?')}
        AllowMultiple
      />
      <BillingTextArea
        name="details"
        InquiryType={data['type']}
      />
      <img alt={t('Void Cheque')} src={i18n.language === 'fr' ? BOLBankingHelpImageFR : BOLBankingHelpImage} />
    </fieldset>
  )
}

export default RequestType