import { useContext } from 'react'
import TenantStr from 'components/TenantStr'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import { fr } from 'date-fns/locale'
import { parseISO, format } from 'date-fns'
import Link from 'components/Buttons/Link'

const TranslationsTest = () => {
  const { tenant } = useContext(AppContext)
  const { t, i18n } = useTranslation();

  const isInova = tenant && tenant.slug === 'inova'

  const hasFax = tenant.phone_numbers &&
                 tenant.phone_numbers.default &&
                 tenant.phone_numbers.default.fax
  
  const policyHolderName = 'Human'
  const policyCount = 1

  const errorKeyTranslations = {
    invalid_policy_number: t('testing::Invalid policy number'),
    licence_date_order_error: t('testing::Dates provided must be consecutive'),
  }
  const resp = {data: {error_code: 'licence_date_order_error'}} // Mock backend resp

  const getPolicyNumber = (x) => `POLICY${x}` // Mock function that returns a policy number

  return (
    <div className="SitePage">
      <div className="container">
        
        <h2>{t("testing::Translation examples page!")}</h2>
        
        <p>{t('testing::Add "?lng=en" or "?lng=fr" to the end of your url to swap languages')}</p>

        <p>
          <Trans
            i18nKey="testing::testPageSubHeader1"
            components={{ productName: <TenantStr ProductName />, s: <strong /> }}
          />
        </p>
        
        <p>
          <Trans
            i18nKey="testing::testPagePolicyCount"
            count={policyCount}
            values={{ name: policyHolderName }}
          />
        </p>
        <p>
          <Trans
            i18nKey="testing::testPagePolicyCount"
            count={5}
            values={{ name: "Pistachio" }}
          />
        </p>
        <p>
          <Trans
            i18nKey="testing::testPagePolicyCount"
            count={1000000}
            values={{ name: "Steve" }}
          />
        </p>

        {tenant && tenant.slug === 'smartcov' && <p>{t("testing::This is tenant specific copy!")}</p>}

        {isInova && (
          <p className="InlineButtons">
            <Trans
              i18nKey="testing::testPageInovaLetsConnect"
              components={{
                // eslint-disable-next-line
                a1: <a className="Btn Pill Small" href="https://youtu.be/dQw4w9WgXcQ" target="_blank" rel="noreferrer" />,
                // eslint-disable-next-line
                a2: <a className="Btn Pill Small" href={`/i18n?lng=${i18n.language}`} />,
              }}
            />
          </p>
        )}

        {i18n.language === 'en' && <p>English component</p>}
        {i18n.language === 'fr' && <p>French component</p>}

        <p>
          Using date-fns locale: {format(parseISO('2014-02-11T11:30:30'), 'MMM dd, yyyy', {locale: fr})}
        </p>

        <p>
          Translating API error keys: {errorKeyTranslations[resp.data.error_code] || t('testing::Generic error message')}
        </p>
        
        <p>
          Link with dynamic aria-label:
          <Link to={`/account/${getPolicyNumber(1)}`} Pill Small ariaLabel={t('testing::View detail for policy {{policyNumber}}', {policyNumber: getPolicyNumber(1)})}>
            {getPolicyNumber(1) || t('testing::Loading...')}
          </Link>
        </p>

        <address>
          <strong><TenantStr LegalName /></strong><br />
          <TenantStr Hours /><br />
          <TenantStr Address /><br />
          <TenantStr City />, <TenantStr Province /> <TenantStr PostalCode />
        </address>
        <address>
          <strong>{t("testing::Phone:")}</strong> <TenantStr Phone /><br />
          {hasFax && <span><strong>{t("testing::Fax:")}</strong> <TenantStr Fax /></span>}
          {!isInova && (
            <>
              <br />
              <TenantStr Email />
            </>
          )}
        </address>
      </div>
    </div>
  )
}

export default TranslationsTest
