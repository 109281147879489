import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import GetStarted from 'forms/fieldsets/vehicle/VehicleAddGetStarted'
import VehicleLookup from 'forms/fieldsets/vehicle/VehicleLookup'
import OwnerDriver from 'forms/fieldsets/vehicle/OwnerDriver'
import VehicleDetails from 'forms/fieldsets/vehicle/VehicleDetails'
import VehicleUsage from 'forms/fieldsets/vehicle/VehicleUsage'
import Eligibility from 'forms/fieldsets/vehicle/Eligibility'
import CoverageNew from 'forms/fieldsets/vehicle/CoverageNew'
import LeasedOrFinanced from 'forms/fieldsets/vehicle/LeasedOrFinanced'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import VehicleAddConfirm from 'forms/fieldsets/vehicle/VehicleAddConfirm'
import { useTranslation } from 'react-i18next'

const VehicleAdd = () => {
const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="vehicle-add" />
      <GetStarted path="get-started" />
      <VehicleLookup path="vehicle-lookup" />
      <OwnerDriver path="owner-and-driver" />
      <VehicleDetails path="vehicle-details" />
      <VehicleUsage path="vehicle-usage" />
      <Eligibility path="eligibility" />
      <CoverageNew path="coverage" />
      <LeasedOrFinanced path="financing-details" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('When you add a vehicle you may find that your use of your existing vehicles changes as well. Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <VehicleAddConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default VehicleAdd