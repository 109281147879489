import { useContext } from 'react'
import { AppContext } from 'utils/context'

const TenantComponent = ({ element, elementName }) => {

  const { tenant } = useContext(AppContext)

  if (tenant && tenant.components && tenant.components[elementName]) {
    return tenant.components[elementName]
  } else {
    return element
  }

}

export default TenantComponent