import React, { useContext, useEffect } from 'react'
import { FormContext } from 'utils/context'
import Radio from 'forms/fields/Radio'
import { driversDisable } from '../vehicle/OwnerDriver'

const PrincipalDriver = ({prefix, defaultValue, driversChoices, callBack, vehicleId}) => {

  const { clearAllErrors } = useContext(FormContext)
  const { results }  = useContext(driversDisable)
  
  const fieldName = name => prefix ? `${prefix}_${name}` : name

  const changeCallBack = (val, name, id) => {
    clearAllErrors()
    if(callBack){
      callBack(vehicleId, val)
    }
  }
  
  useEffect(() => {
    if(callBack && defaultValue){
      callBack(vehicleId, defaultValue)
    }
  }, []) //eslint-disable-line
 
  return (
    <fieldset>
      <Radio
        id={fieldName('driver_id')}
        name={fieldName('driver_id')}
        choices={driversChoices}
        DefaultValue={driversChoices.length === 1 ? driversChoices[0][0] : defaultValue}
        ChangeCallback={changeCallBack}
        Stacked
        disabledChoices={results ? results[vehicleId] : null}
      />
    </fieldset>
  )
}

export default PrincipalDriver
