import React from 'react'
import classNames from 'classnames'
import LoadingSpinner from 'components/LoadingSpinner'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'

const Text = ({ id, name, type, label, placeholder, autocomplete, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, HelpCopy, className, Hidden, KmField, PriceField, cleans, Optional, HalfLeft, HalfRight, Loading, maxLength, minLength }) => {

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, cleans, Optional, maxLength, minLength)

	const classes = classNames('field text', {
		[className]: className,
		'active': active,
		'touched': touched,
		'has-error': error,
    'hidden': Hidden,
    'HalfLeft': HalfLeft,
    'HalfRight': HalfRight,
    'PriceField': PriceField,
    'KmField': KmField,
  })
  
  const getHelp = () => {
    if (typeof HelpCopy === 'function') {
      return <HelpCopy />
    } else {
      return <span className="help-copy">{HelpCopy}</span>
    }
  }

	return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
      <Label label={label} name={name} Optional={Optional} />
			<div className="field-wrapper">
        {PriceField && <span className="Price">$</span>}
				<input
	        id={id || name}
	        name={name}
	        value={value || ''}
	        onChange={onChange}
	        onFocus={onFocus}
	        onBlur={onBlur}
	        type={type || 'text'}
	        placeholder={placeholder}
	        autoComplete={autocomplete}
	      />
        {KmField && <span className="Kms">km</span>}
      	<FieldError error={error} name={name} />
        {Loading && (
          <div className="LoadingHolder">
            <LoadingSpinner />
          </div>
        )}
        {HelpCopy && getHelp()}
			</div>
		</div>
	)
}

export default Text