import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const NoMatch = ({ children, Heading }) => {
  const { t } = useTranslation()
  return (
    <div className="NoMatch">
      <div className="container text-center">
        <h2>{Heading || t('Page not found')}</h2>
        {children}
      </div>
    </div>
  )
}

export default NoMatch