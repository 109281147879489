import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import RequestDisclaimer from 'components/RequestDisclaimer'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import UsageConfirm from 'forms/fieldsets/vehicle/UsageConfirm'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import ConfirmCard from 'forms/ConfirmCard'
import { vehicleReinstateReasons, } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const ReinstateCoverageConfirm = () => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  
  let reason = data['reinstate_reason'] === 'other' ? data['reinstate_reason_other'] : vehicleReinstateReasons.find(r => r[0] === data['reinstate_reason'])[1] 
 let hasDamagedVehicle = data['existing_damage']
 let dmgedVehicleDetails = data['damaged_vehicle_details']

 let hasUnreportedClaim = data['has_unreported_claim']
 let claimDetails = data['damaged_vehicle_details']
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Reinstate Reason")} slug="reinstate-reason" id={data.id}>
        <ul className="InfoList">
          <li>
            <small>{t('Vehicle')}</small>
            {data.full_vehicle_name}
          </li>
          <li>
            <small>{t('Reinstate reason')}</small>
            {reason}
          </li>
          {hasDamagedVehicle &&
            <li>
              <small>{t('Damaged vehicle details')}</small>
              {dmgedVehicleDetails}
            </li>
          }
          {hasUnreportedClaim &&
            <li>
              <small>{t('Claim details')}</small>
              {claimDetails}
            </li>
          }
        </ul>
      </ConfirmCard>
      <UsageConfirm/>
      <PolicyChangesConfirm/>
    </fieldset>
  )
}

export default ReinstateCoverageConfirm
