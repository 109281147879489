import React, { useState, useEffect, useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import { HashLink } from 'react-router-hash-link'
import Tile from 'components/Tile'
import PinksIcon from 'utils/icons/Pinks'
import { postRequest } from 'utils/requests'
import { useTranslation } from 'react-i18next'

import './style.scss'

const PolicyPinks = ({ vehicles }) => {

  const { t } = useTranslation()
  const [url, setUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showWarning, setShowWarning] = useState(false)

  const fetchPinks = useCallback(() => {
    
    const ids = vehicles
      .filter(v => v.pink_slip_available && v.id)
      .map(x => x.id)

    if (ids.length > 0) {
      if (ids.length < vehicles.length) {
        setShowWarning(true)
      }
      postRequest('/api/pink-slips/', { vehicles: ids })
        .then(res => {
          setUrl(res.content.url)
          setLoading(false)
        })
        .catch(err => {
          Sentry.captureException(err)
        })
    }

  }, [vehicles])

  useEffect(() => {
    fetchPinks()
  }, []) // eslint-disable-line
  return !loading && url && vehicles.length > 1 ? (
    <Tile White className="PolicyPinks" Icon={PinksIcon} IconName="PinksIcon">
      <div className="TileHeader">
          <strong>{t('Download All Pink Slips')}</strong>
      </div>
      <div className="TileBody">
        <p>
          <a className="Btn Pill Accent DownloadAll" href={url} target="_blank" rel="noopener noreferrer" data-ga-pink="policy">
            {t('All Pink Slips')}
          </a>
        </p>
        {showWarning ? (
          <small>{t('We were not able to show pink slips for some of your vehicles.')} <HashLink to="/account/help#mypinkslips">{t('More info')}</HashLink>
          </small>
        ) : (
          <small>{t('This will download pink slips for all your vehicles on the policy')}</small>
        )}
      </div>
    </Tile>
  ) : null
}

export default PolicyPinks