import TrashIcon from 'utils/icons/Trash'
import Button from 'components/Buttons/Button'

import './style.scss'

const ArrayItems = ({ items = [], title = '', titleMap = null, onDelete, Placeholder }) => {

  const getTitle = val => {
    if (titleMap) {
      const item = titleMap.find(x => x[0] === val)
      if (item && item[2]) {
        return item[2]
      } else if (item && item[1]) {
        return item[1]
      }
    }
    return val
  }

  return (
    <div id="array-items" className="ArrayItems">
      {items.length > 0 ? items.map((x, i) =>
        <div key={i} className="ArrayItem">
          {getTitle(x[title])}
          <Button onClick={e => onDelete(e, x.id)} DataTestId="delete">
            <TrashIcon />
          </Button>
        </div>
      ) : <i className="ArrayPlaceholder">{Placeholder}</i>}
    </div>
  )
}

export default ArrayItems