import React, { useContext } from 'react'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './style.scss'

const CloseButton = ({ onClick }) => {
  const { t } = useTranslation()
  const app = useContext(AppContext)
  return (
    <button className="CloseButton" onClick={onClick ? onClick : () => app.setDialogOpen(false)}>
      <span className="sr-only">{t('Close')}</span>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 15.3a1.2 1.2 0 1 1-1.7 1.7L12 13.7 8.7 17A1.2 1.2 0 0 1 7 15.3l3.3-3.3L7 8.7A1.2 1.2 0 0 1 8.7 7l3.3 3.3L15.3 7A1.2 1.2 0 1 1 17 8.7L13.7 12l3.3 3.3z" />
      </svg>
    </button>
  )
}

export default CloseButton