import React from 'react'
import Usage from 'forms/fieldsets/vehicle/Usage'
import { useTranslation } from 'react-i18next'
const VehicleUsage = () => {
  const { t } = useTranslation()
  return (
    <fieldset>
      <Usage
        usageLabel={t('How will this vehicle primarily be used?')}
      />
    </fieldset>
  )
}

export default VehicleUsage