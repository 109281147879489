import { useContext } from 'react'
import { parseISO, isValid, isAfter } from 'date-fns'
import Select from 'forms/fields/Select'
import TriDate from 'forms/fields/TriDate'
import { reasonChoices } from 'forms/utils/vars'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const DriverHistorySubForm = ({ parentData }) => {

  const { t } = useTranslation()
  const { data, setFieldError } = useContext(FormContext)

  const checkDates = (value, name) => {
    const suspension = data['suspension_date']
    const reinstatement = data['reinstatement_date']
    if (isValid(parseISO(suspension)) && isValid(parseISO(reinstatement))) {
      if (!isAfter(parseISO(reinstatement), parseISO(suspension))) {
        setFieldError(name, t('Reinstatement date must be after suspension date'))
      }
    }
  }

  return (
    <>
      <Select
        name="reason"
        choices={reasonChoices}
        label={t('Reason for Suspension')}
      />
      <TriDate
        name="suspension_date"
        label={t('Suspension Date')}
        BlurCallback={checkDates}
      />
      <TriDate
        name="reinstatement_date"
        label={t('Reinstatement Date')}
        BlurCallback={checkDates}
      />
    </>
  )
}

export default DriverHistorySubForm
