import Radio from 'forms/fields/Radio'
import Select from 'forms/fields/Select'
import Text from 'forms/fields/Text'
import TriDate from 'forms/fields/TriDate'
import { useContext } from 'react'
import { FormContext } from 'utils/context'
import { antiTheftDeviceTypes, antiTheftDeviceManufacturers, engravingManufacturers } from 'utils/vars'
import { eightCharsTag } from 'forms/utils/cleans'
import { useTranslation, Trans } from 'react-i18next'

const AntiTheft = ( name ) => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  
  const AntiTheftDevice = <><Trans i18nKey="antiTheftDevice" components={{ s: <strong /> }} /></>
  const VehicleEngraving = <><Trans i18nKey="vehicleEngraving" components={{ s: <strong /> }} /></>
  
  const antiTheftCallback = value => {
    if (value === false) {
      setFieldValue('anti_theft_device_type', null)
      clearFieldError('anti_theft_device_type')
      setFieldValue('anti_theft_device_manufacturer', null)
      clearFieldError('anti_theft_device_manufacturer')
      setFieldValue('anti_theft_tag_number', null)
      clearFieldError('anti_theft_tag_number')
      setFieldValue('anti_theft_installation_date', null)
      clearFieldError('anti_theft_installation_date')
    }
    if (value !== 'tag'){
      setFieldValue('anti_theft_tag_number', null)
      clearFieldError('anti_theft_tag_number')
    }
    if (value !== 'other'){
      setFieldValue('anti_theft_device_manufacturer_other', null)
      clearFieldError('anti_theft_device_manufacturer_other')
    }
  }
  const engravingCallback = value => {
    if (value === false) {
      setFieldValue('engraving_manufacturer', null)
      clearFieldError('engraving_manufacturer')
      setFieldValue('engraving_tag_number', null)
      clearFieldError('engraving_tag_number')
      setFieldValue('engraving_installation_date', null)
      clearFieldError('engraving_installation_date')
    }
    if (value !== 'tag'){
      setFieldValue('engraving_tag_number', null)
      clearFieldError('engraving_tag_number')
    }
    if (value !== 'other'){
      setFieldValue('engraving_manufacturer_other', null)
      clearFieldError('engraving_manufacturer_other')
    }
  }
  
  const antiTheftDeviceOtherCallback = (value, name) => {
    if (value !== 'other'){
      setFieldValue('anti_theft_device_type_other', null)
      clearFieldError('anti_theft_device_type_other')
    }
  }
  
  const showAntiTheft = data["anti_theft_device"]
  const showAntiTheftTag = data["anti_theft_device_manufacturer"] === 'tag'
  const showEngraving = data["vehicle_engraving"]
  const showEngravingTag = data["engraving_manufacturer"] === 'tag'
  
  const showAntiTheftDeviceOther = data["anti_theft_device_type"] === 'other'
  const showAntiTheftManufacturerOther = data["anti_theft_device_manufacturer"] === 'other'
  const showEngravingManufacturerOther = data["engraving_manufacturer"] === 'other'
  
  return(
    <div id={`${name}-anti-theft`} data-testid={name}>
      <Radio
        name="anti_theft_device"
        label={t("Does this vehicle have an anti-theft device?")}
        HtmlLabel={AntiTheftDevice}
        choices={[[true, t('Yes')], [false, t('No')]]}
        ChangeCallback = {antiTheftCallback}
      />
      {showAntiTheft && <Select
        name="anti_theft_device_type"
        label={t("Anti-theft device type")}
        choices={antiTheftDeviceTypes}
        ChangeCallback={antiTheftDeviceOtherCallback}
      />}
      {showAntiTheftDeviceOther && <Text
        name="anti_theft_device_type_other"
        label={t("Please enter your anti theft device type")}
      />}
      {showAntiTheft && <Select
        name="anti_theft_device_manufacturer"
        label={t("Anti-theft device manufacturer")}
        choices={antiTheftDeviceManufacturers}
        ChangeCallback={antiTheftCallback}
      />}
      {showAntiTheftManufacturerOther && <Text
        name="anti_theft_device_manufacturer_other"
        label={t("Please enter your anti theft device manufacturer")}
      />}
      {showAntiTheft && <TriDate
        name="anti_theft_installation_date"
        label={t("When was the anti-theft device installed?")}
        maxDate={new Date()}
        minDate={new Date(data.year - 1, 0)}
      />}
      {showAntiTheftTag && <Text
        AntiTheftTagField
        name="anti_theft_tag_number"
        label={t("What is the Tag number of the installed anti-theft device?")}
        cleans={[eightCharsTag]}
      />}
      <Radio
        name="vehicle_engraving"
        label={t("Does this vehicle have an etching/engraving?")}
        HtmlLabel={VehicleEngraving}
        choices={[[true, t('Yes')], [false, t('No')]]}
        ChangeCallback = {engravingCallback}
      />
      {showEngraving && <Select
        name="engraving_manufacturer"
        label={t("Etching/Engraving manufacturer")}
        choices={engravingManufacturers}
        ChangeCallback={engravingCallback}
      />}
      {showEngravingManufacturerOther && <Text
        name="engraving_manufacturer_other"
        label={t("Please enter your etching/engraving manufacturer")}
      />}
      {showEngraving && <TriDate
        name="engraving_installation_date"
        label={t("When was the etching/engraving installed?")}
        maxDate={new Date()}
        minDate={new Date(data.year - 1, 0)}
      />}
      {showEngravingTag && <Text
        EngravingTagField
        name="engraving_tag_number"
        label={t("What is the Tag number of the installed etching/engraving?")}
        cleans={[eightCharsTag]}
      />}
    </div>
  )
}

export default AntiTheft