import React from 'react'
import { HashLink } from 'react-router-hash-link'
import WarningIcon from 'utils/icons/Warning'
import { Trans } from 'react-i18next'
import './style.scss'

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -70;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const ErrorFlash = ({ errors, formError }) => {

  const firstError = Object.keys(errors)

  const to = `#${firstError[0]}-field`
  
  return firstError.length > 0 ? (
    <div className="ErrorFlash">
      <WarningIcon />
      <span>
        <Trans
          i18nKey="reviewErrorMsg"
          components={{ hashlink: <HashLink className="Link" smooth to={to} scroll={scrollWidthOffset} /> }}
        />
      </span>
    </div>
  ) : null
}

export default ErrorFlash