import { useContext } from 'react'
import Link from 'components/Buttons/Link'
import Tile from 'components/Tile'
import RequestPinks from './RequestPinks'
import PencilPaddedIcon from 'utils/icons/PencilPadded'
import DollarIcon from 'utils/icons/Dollar'
import QuestionAltIcon from 'utils/icons/QuestionAlt'
import PawIcon from 'utils/icons/Paw'
import { AccountContext } from 'utils/context'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const Home = () => {
  const { t } = useTranslation()
  const { allowedRequestTypes } = useContext(AccountContext)
  const { tenant } = useContext(AppContext)

  const showQuestions = Object.keys(allowedRequestTypes()['question']).length > 0
  const showQuotes = Object.keys(allowedRequestTypes()['quote']).length > 0
  const showChanges = Object.keys(allowedRequestTypes()['change']).length > 0
  
  const isSmartCov = tenant && tenant.slug === 'smartcov'

  return (
    <div className="NewRequest" data-testid="new-requests">
      {showQuestions &&
      <Tile Icon={QuestionAltIcon} IconName="QuestionAltIcon" LinkUrl="/account/requests/question" TestId="ask-a-question">
        <div className="TileHeader">
          <h4 className="uppercase">{t('Ask a Question')}</h4>
          <small>{t('Have a question about your policies, coverage, or payment details?')}</small>  
        </div>
      </Tile>}
      {showQuotes &&
      <Tile Icon={DollarIcon} IconName="DollarIcon" LinkUrl="/account/requests/quote" TestId="get-a-quote">
        <div className="TileHeader">
          <h4 className="uppercase">{t('Get a Quote')}</h4>
          <small>{t('You can get a quote to add or replace a vehicle on your policy.')}</small>  
        </div>
      </Tile>}
      {showChanges &&
      <Tile Icon={PencilPaddedIcon} IconName="PencilPaddedIcon" LinkUrl="/account/requests/change" TestId="make-a-change">
        <div className="TileHeader">
          <h4 className="uppercase">{t('Make a Change')}</h4>
          <small>{t('Make changes to drivers, vehicles, or your payment information here.')}</small>  
        </div>
      </Tile>}
      {!showQuestions && !showQuotes && !showChanges && (
        <div>
          <h2>{t('No supported policy types found')}</h2>
          <p>{t('Service requests are currently not available for any of your policies.')}</p>
          <Link to="/account" Pill>{t('Return to Summary')}</Link>
        </div>
      )}
      <RequestPinks />
      {isSmartCov &&
      <Tile Icon={PawIcon} IconName="PawIcon" LinkUrl={{pathname: "https://www.hubsmartcoverage.ca/other-insurance/"}} LinkTarget="_blank" TestId="view-other-offers">
        <div className="TileHeader">
          <h4 className="uppercase">{t('View Other Offers')}</h4>
          <small>{t('smartcov::Explore other SmartCoverage offers such as Pet Insurance, Life Insurance and other insurance needs.')}</small>  
        </div>
      </Tile>}
    </div>
  )
}

export default Home