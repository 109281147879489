import i18n from "i18n"

const policyType = (policy_type) => {
  const HOME_POLICY_TYPES = {
    'condo' : i18n.t('condo Policy'),
    'homeowner': i18n.t('homeowner Policy'),
    'manual homeowner': i18n.t('manual homeowner Policy'),
    'manual property': i18n.t('manual property Policy'),
    'manual seasonal': i18n.t('manual seasonal Policy'),
    'manual tenants': i18n.t('manual tenants Policy'),
    'property': i18n.t('property Policy'),
    'rented condo': i18n.t('rented condo Policy'),
    'rented dwelling': i18n.t('rented dwelling Policy'),
    'seasonal': i18n.t('seasonal Policy'),
    'secondary': i18n.t('secondary Policy'),
    'secondary homeowners': i18n.t('secondary homeowners Policy'),
    'tenants': i18n.t('tenants Policy'),
    'watercraft': i18n.t('watercraft Policy'),
  }
  
  return HOME_POLICY_TYPES[policy_type]
}

export default policyType