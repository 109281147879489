import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

import './style.scss'

const Impersonate = () => {

  const [impersonating, setImpersonating] = useState(false) 

  let api = window.location.hostname
  let proto = window.location.port === '3000' ? ':8000' : ''

  useEffect(() => {
    const isImpersonating = Cookies.get('impersonated')
    setImpersonating(isImpersonating === 'True')
    return () => Cookies.remove('impersonated')
  }, [])

  return impersonating ? (
    <a href={`https://${api}${proto}/impersonate/stop/`} className="Button Pill Impersonate">
      <span className="StopText">Stop Impersonating</span>
    </a>
    ) : null
  }

export default Impersonate