import { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { idsToValues } from 'utils/helpers'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
const PolicyRenewalConfirm = () => {
  const { t } = useTranslation()
  let { policies, vehicles, drivers } = useContext(AccountContext)
  let { data } = useContext(FormContext)

  let showAutoDiscounts = false;
  let showHabDiscounts= false;

  for (let i = 0; i < data['renewing_policies'].length; i++) {
    const policyId = data['renewing_policies'][i]
    const lob = policies.find(policy => policyId === policy.policy_id).lob

    if (lob === "auto") {
      showAutoDiscounts = true
    } else if (lob === "hab") {
      showHabDiscounts = true
    }

    if (showAutoDiscounts && showHabDiscounts) break
  }
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails hideRequester requester={data['requester']}  requesterOther={data['requester_other']} />
      <ConfirmCard title={t("Request Details")} slug="request-details" id={data.id}>
        <ul>
          <li>
            <small>{t('Requester name')}</small>
            {(data['requester']) === "other" ? data['requester_other'] : drivers.find(driver => driver.driver_id.toString() === data['requester']).full_name}
          </li>
          <li>
            <small>{t('Policy for review')}</small>
            {idsToValues(data['renewing_policies'], policies, "policy_id", "policy_number")}
          </li>
        </ul>
      </ConfirmCard>
      <ConfirmCard title={t("Review Details")} slug="discount-review" id={data.id}>
        <ul>
          <li>
            <small>{t('Do you or your spouse have a vehicle or a property insured by another company and are interested in saving 20%?')}</small>
            {data['combine_policies'] ? t('Yes') : t('No')} 
          </li>
          { 
            showAutoDiscounts &&
            <>
              <li>
                <small>{t('Would you be willing to download an app that rewards good driving behaviours for savings of up to 30%?')}</small>
                {data['download_app'] ? t('Yes') : t('No')}
              </li>
              <li>
                <small>{t('Winter Tire')}</small>
                {data['winter_tires'] ? t('Yes') : t('No')}
              </li>
            </>
          }
          {
            data['winter_tires'] === true &&
            <li>
              <small>{t('Vehicles that have winter tires')}</small>
              {idsToValues(data['winter_tires_vehicles'], vehicles, "vehicle_id", "full_vehicle_name")}
            </li>
          }
          {
            showAutoDiscounts &&
            <>
              <li>
                <small>{t('Retiree')}</small>
                {data['retiree'] ? t('Yes') : t('No')}
              </li>
              <li>
                <small>{t('Out-of-Town Student')}</small>
                {data['out_of_town_student_school'] ? t('Yes') : t('No')}
              </li>
            </>
          }

          {
            data['out_of_town_student'] === true &&
            <>
              <li>
                <small>{t("Name of child's post-secondary institution")}</small>
                {data['out_of_town_student_school']}
              </li>
              <li>
                <small>{t("Address of child's post-secondary institution")}</small>
                {data['out_of_town_student_address']}
              </li>
            </>
          }

          {
            showHabDiscounts &&
            <>
              <li>
                <small>{t('Non-Smoker?')}</small>
                {data['non_smoker'] ? t('Yes') : t('No')}
              </li>
              <li>
                <small>{t('Water Damage Prevention?')}</small>
                {data['water_damage_prevention'] ? t('Yes') : t('No')}
              </li>
              <li>
                <small>{t('Monitored Alarm?')}</small>
                {data['monitored_alarm'] ? t('Yes') : t('No')}
              </li>
              <li>
                <small>{t('Mortgage Free?')}</small>
                {data['mortgage_free'] ? t('Yes') :t('No')}
              </li>
            </>
          }
          <li>
            <small>{t('Have you moved, bought a new car, got married, reduced your driving habits or done anything else that may have an impact on your monthly payments?')}</small>
            {data['lifestyle_changes'] ? t('Yes') : t('No')}
          </li>
          {
            data['lifestyle_changes'] &&
            <li>
              <small>{t('Explanation for lifestyle changes')}</small>
              {data['lifestyle_changes_explanation']}
            </li>
          }
            
        </ul>
      </ConfirmCard>
    </fieldset>
  )
}

export default PolicyRenewalConfirm