import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import SimpleForm from 'forms/SimpleForm'
import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import LogoutButton from 'components/LogoutButton'
import TermsConditions from 'components/TermsConditions'
import Text from 'forms/fields/Text'
import { emailAddress } from 'forms/utils/cleans'
import { AppContext } from 'utils/context'
import { loginError } from './vars'
import { useTranslation, Trans } from 'react-i18next'

const Login = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { tenant, showMaintenance, authed, setAuthed } = useContext(AppContext)

  const loginSuccess = res => {
    if (res.user_id) {
      Cookies.set('user_id', res.user_id)
      setAuthed(true)
    }
  }

  const loginNotActive = (err, setFormError) => {
    if (err.error === 'Account is not active') {
      history.push('/resend-activation')
    } else if (err.error === 'Could not authenticate user') {
      setFormError(loginError)
    }
  }

  const isPc = tenant && tenant.slug === 'cis'

  return (
    <div className="SitePage">
      <div className="container">
        {authed ? (
          <div>
            <h2>{t('You are currently logged in.')}</h2>
            <Link to="/account" Pill>{t('Account')}</Link>
            <LogoutButton />
          </div>
        ) : (
          <div>
            <h2>
              <Trans
                i18nKey="loginToTenantMsg"
                components={{ productName: <TenantStr ProductName /> }}
              />
            </h2>
            <p>
              <Trans
                i18nKey="signUpNowMsg"
                components={{ productName: <TenantStr ProductName />, signupLink: <Link to="/signup" /> }}
              />
            </p>
            <p>
              <Trans
                i18nKey="sendEmailMsg"
                components={{ passwordLink: <Link to="/forgot-password"/> }}
              />
            </p>
            <p>
              <Trans
                i18nKey="unableToLoginMsg"
                components={{
                  // eslint-disable-next-line
                  a1: <a className = "Link" href={tenant.contact_us_url}/>
                }} 
              />
            </p>
            <div>
              {showMaintenance ? (
                <p>{t('Login is currently disabled')}</p>
              ) : (
                <SimpleForm SubmitUrl="/api/users/login/" SubmitLabel={t('Log In')} SubmitClassName="Light float-right" SuccessCallback={loginSuccess} ErrorCallback={loginNotActive} SecondaryButton={<Link className="float-right" to="/forgot-password" Transparent Pill>{t('Forgot Password')}</Link>}>
                  <Text
                    name="email"
                    label={t('Email Address')}
                    cleans={[emailAddress]}
                    autocomplete="username"
                  />
                  <Text
                    name="password"
                    label={t('Password')}
                    type="password"
                    autocomplete="current-password"
                  />
                </SimpleForm>
              )}
            </div>
          </div>
        )}
        {isPc && <TermsConditions />}
      </div>
    </div>
  )
}

export default Login
