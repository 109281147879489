import { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import Link from 'components/Buttons/Link'
import LoadingSpinner from 'components/LoadingSpinner'
import Tile from 'components/Tile'
import BasePage from 'components/BasePage'
import PaymentInfo from './PaymentInfo'
import PolicyDropdown from './PolicyDropdown'
import { AccountContext } from 'utils/context'
import WarningIcon from 'utils/icons/Warning'
import { useTranslation, Trans } from 'react-i18next'
import './style.scss'

const MakeAPayment = () => {

  const { t } = useTranslation()
  const { push, location } = useHistory()
  const { policies } = useContext(AccountContext)
  
  const handleChange = e => {
    const { value } = e.target
    push(`/account/make-a-payment/${value}`)
  }

  useEffect(() => {
    if (policies.length === 1) {
      if (location.pathname === '/account/make-a-payment') {
        push(`/account/make-a-payment/${policies[0].policy_number}`)
      }
    }
  }, [policies, location.pathname, push])
  
  return policies.length > 0 ? (
    <BasePage className="MakeAPayment">
      <div className="container">
        <Tile className="MakePaymentTile" Warning SmallIcon Icon={WarningIcon} IconName="WarningIcon">
          <div className="TileHeader">
            <strong>{t('Before you make a payment')}</strong>
          </div>
          <div className="TileBody">
            <p>
              <Trans i18nKey="uncertainPaymentMsg" components={{requestLink: <Link to="/account/requests" />}} />
            </p>
            <br/>
            <p>{t('Please note that if your policy is pending cancellation, making a payment does not guarantee reinstatement of your policy.')}</p>
            <br/>
            <p>{t('If you are on a Pre-Authorized Chequing payment plan, and choose to make a payment, if you are close to your regularly scheduled payment, it will likely still come out of your bank account.')}</p>
          </div>
        </Tile>
        {policies.length > 1 && (
          <Switch>
            <Route path="/account/make-a-payment/:policyNumber">
              <PolicyDropdown policies={policies} handleChange={handleChange} />
            </Route>
            <Route path="/account/make-a-payment">
              <PolicyDropdown policies={policies} handleChange={handleChange} />
            </Route>
          </Switch>
        )}
        <Switch>
          <Route path="/account/make-a-payment/:policyNumber">
            <PaymentInfo policies={policies} />
          </Route>
        </Switch>
      </div>
    </BasePage>
  ) : <div className="LoadingHolder"><LoadingSpinner /></div>
}

export default MakeAPayment