import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

const BasePage = ({ children, className }) => {

  let location = useLocation()

  const clsArray = location.pathname.replace('/account', '').replace('/', '').split('/')

  const cls = classNames('BasePage', clsArray, {
    [className]: className,
  })

  return (
    <div className={cls}>
      {children}
    </div>
  )
}

export default BasePage