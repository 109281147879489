import { useState, useContext } from 'react'
import CoverageRadio from 'forms/fields/CoverageRadio'
import Tile from 'components/Tile'
import WarningIcon from 'utils/icons/Warning'
import useCoverage from 'forms/utils/useCoverage'
import { Trans, useTranslation } from 'react-i18next'
import TenantStr from 'components/TenantStr'
import IconStar from 'utils/icons/Star'
import { FormContext, AccountContext } from 'utils/context'

const Coverage = ({prefix}) => {

  const { t } = useTranslation()
  const [activeCov, setActiveCov] = useState('')
  const { policies } = useContext(AccountContext)
  const { data } = useContext(FormContext)
  const { fields } = useCoverage()

  let policy = policies.find(p => p.id === data.policy)
  let inAlberta = policy.province === "AB"

  const fieldName = name => prefix ? `${prefix}_${name}` : name

  return (
    <fieldset>
      <p className="fieldset-intro">{t('Please select the insurance coverage you would like on your vehicle.')}</p>
      <h3>
        <Trans
          i18nKey="recommendedCovMsg" 
          components={{ tenantName: <TenantStr LegalName />, icon: <IconStar/>, u: <u/>}} 
        />
      </h3>
      <div style={{fontSize: 'smaller'}}>{t('Other coverage options may be available to you. If you have questions, or to customize coverage specific to your needs, please connect with one of our Brokers.')}</div>
      <br/>
      {fields.map(field => (
        <CoverageRadio
          key={field.name}
          id={prefix ? fieldName(field.name) : field.name}
          name={prefix ? fieldName(field.name) : field.name}
          label={field.label}
          choices={field.choices}
          setActiveCov={setActiveCov}
          activeCov={activeCov}
          helpCopy={(inAlberta && field.helpAb) ? field.helpAb : (field.help ? field.help : null)}
          fieldName={field.name}
          warningTxt={field.warningTxt ? field.warningTxt : null}
        />
      ))}
      <Tile Warning Icon={WarningIcon} IconName="WarningIcon" SmallIcon>
        <small>{t('Coverage is subject to eligibility, we will contact you if any of your requested coverage changes.')}</small>
      </Tile>
    </fieldset>
  )
}

export default Coverage