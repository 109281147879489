import i18n from 'i18n';

const lang = i18n.language === 'fr' ? 'fr' : 'en'

const aviva = {
  link: `https://www.aviva.ca/${lang}/payment-broker/`,
  required: [
    'Account Number for payment (not Policy number)',
    'Named Insured',
    'Amount',
  ],
}

const econ = {
  link: 'https://www.economical.com/en/make-a-payment',
  required: [
    'Account Number (not policy number)',
    'Policy Number',
    'Amount',
    'VISA/MC/AMEX',
    'No VISA Debit or MC Debit',
  ]
}

const gc = {
  link: 'https://apps.intactinsurance.com/on/secure/payment-ontario.html',
  required: [
    'Policy Number',
    'Policy Holder (Named Insured)',
    'Amount',
    'VISA/MC',
  ]
}

const gore = {
  link: 'https://www.goremutual.ca/pay-by-cc/',
  required: [
    'Account Number',
    'Policy Number',
  ]
}

const pem = {
  link: `https://consumer.pembridge.com/paymybill/?lang=${lang}`,
  required: [
    'Policy Number',
    'Policy Effective Date',
    'Amount',
    'VISA/MC',
  ]
}

const paf = {
  link: `https://consumer.pafco.ca/paymybill/StepOne.aspx?lang=${lang}`,
  required: [
    'Policy Number',
    'Policy Holder (Named Insured)',
    'Amount',
    'VISA/MC',
  ]
}

const rsa = {
  link: `https://rsagroup.rsaebusiness.ca/j_eselect/app/pay?execution=e1s1&lang=${lang}`,
  required: [
    'Policy Number',
    'Postal Code',
    'VISA/MC/AMEX',
  ]
}

const wawa = {
  link: i18n.language === 'fr' ? 'https://www.wawanesa.com/canada/payments/myaccount.html?language_id=102' : 'https://www.wawanesa.com/canada/payments/myaccount.html',
  required: [
    'Policy Number',
    'Last Name',
  ]
}

const west = {
  link: `https://westernassurance.rsaebusiness.ca/j_eselect/app/pay;jsessionid=3FcT59scY5TGe+YdwR4hNmsW.prod1-webeselect?execution=e1s1&lang=${lang}`,
  required: [
    'Policy Number',
    'Last Name',
  ]
}

const paymentInfo = {
  ECON: econ,
  ELN2: aviva,
  ELT: aviva,
  ELTM: aviva,
  GA: aviva,
  GA2: aviva,
  SAY: aviva,
  SR:  aviva,
  TRG: aviva,
  TRG2: aviva,
  GC: gc,
  GC3: gc,
  GORE: gore,
  PEM:  pem,
  PAF:  paf,
  ROY:  rsa,
  WAWA: wawa,
  WEST: west,
}

export default paymentInfo