import React, { useContext } from 'react'
import { formatDistance, parseISO } from 'date-fns'
import Tile from 'components/Tile' 
import Button from 'components/Buttons/Button'
import Link from 'components/Buttons/Link'
import { AccountContext } from 'utils/context'
import { requestTypes } from './vars'
import FormIcon from 'utils/icons/Form'
import { useTranslation, Trans } from 'react-i18next'
import { fr } from 'date-fns/locale'
import i18n from 'i18n'


const Existing = () => {
  const { t } = useTranslation()
  const { currentRequest, lineitem, deleteLineitem, resumeLink, submitting } = useContext(AccountContext)
  const lastModified = lineitem.modified ?
    (i18n.language === 'fr' ? formatDistance(parseISO(lineitem.modified), new Date(), {locale: fr}) : formatDistance(parseISO(lineitem.modified), new Date())) : ''
  const pastTenseStr = currentRequest.lineitemUrl ?
    requestTypes[currentRequest.requestType][currentRequest.lineitemUrl].past_tense : ''

  return (
    <div className="ExistingRequest container">
      <Tile Icon={FormIcon} IconName="FormIcon">
        <div className="TileHeader">
          <strong>{t('Request in Progress')}</strong>
        </div>
        <div className="TileBody">
          <strong>{pastTenseStr}</strong><br />
          <small>
            <Trans
              i18nKey="lastMotified"
              values={{ time: lastModified }}
            />
          </small>
        </div>
        <div className="TileBody">
          <Link to={resumeLink()} Pill Small>{t('Resume')}</Link>{' '}
          <Button
            onClick={() => deleteLineitem(lineitem.id)}
            Pill
            Small
            Transparent
            Loading={submitting}
            Disabled={submitting}
            DataTestId="delete-and-new"
          >{t('Delete and start new')}</Button>
        </div>
      </Tile>
    </div>
  )
}

export default Existing