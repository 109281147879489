import GlobalMessage from './GlobalMessage'
import useGlobalMessage from 'utils/useGlobalMessage'

const GlobalMessages = ({page}) => {

  const {globalMessages} = useGlobalMessage()

  return (
    <div className='globalMessages'>
      {globalMessages.map((message, i) => {
        return <GlobalMessage key={i} id={i} message={message} page={page}/>
      })}
    </div>
  )
}

export default GlobalMessages