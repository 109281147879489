export const DollarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="outerCircle" d="M50 1.89A48.11 48.11 0 1 1 1.89 50 48.16 48.16 0 0 1 50 1.89M50 0a50 50 0 1 0 50 50A50 50 0 0 0 50 0z" />
    <path className="primaryColor" d="M62.31 57.62a10 10 0 0 1-2.63 7 11.27 11.27 0 0 1-6.82 3.6v4.62a.81.81 0 0 1-.84.84h-3.56a.83.83 0 0 1-.6-.24.82.82 0 0 1-.25-.6v-4.67a16.32 16.32 0 0 1-3.36-.82 16.34 16.34 0 0 1-4.63-2.44 14 14 0 0 1-1.22-1 4.48 4.48 0 0 1-.46-.47.79.79 0 0 1-.05-1.08l2.71-3.56a.79.79 0 0 1 .61-.31.66.66 0 0 1 .63.24l.05.05a13.75 13.75 0 0 0 6.41 3.29 9 9 0 0 0 1.95.21A6.37 6.37 0 0 0 54 61.16a3.69 3.69 0 0 0 1.63-3.22 2.71 2.71 0 0 0-.4-1.4 4.71 4.71 0 0 0-.88-1.1 7.08 7.08 0 0 0-1.54-1c-.7-.35-1.28-.64-1.74-.84s-1.15-.49-2.11-.86l-1.62-.66c-.4-.16-.94-.39-1.63-.7s-1.24-.58-1.65-.82-.91-.55-1.48-.94a9.32 9.32 0 0 1-1.42-1.12A17 17 0 0 1 40 47.22a6.46 6.46 0 0 1-.94-1.53 10.73 10.73 0 0 1-.56-1.75 9.32 9.32 0 0 1 2.36-8.44 12.11 12.11 0 0 1 6.75-3.5v-4.79a.86.86 0 0 1 .85-.84H52a.84.84 0 0 1 .61.24.82.82 0 0 1 .24.61v4.65a14.56 14.56 0 0 1 2.92.6 17.36 17.36 0 0 1 2.29.88 12.74 12.74 0 0 1 1.68 1c.53.37.87.62 1 .76l.4.37a.76.76 0 0 1 .13 1l-2.14 3.85a.71.71 0 0 1-.61.42.81.81 0 0 1-.71-.19l-.39-.31a11.63 11.63 0 0 0-1-.7 12.26 12.26 0 0 0-1.54-.84 11 11 0 0 0-2-.69 8.8 8.8 0 0 0-2.25-.3 6.86 6.86 0 0 0-4.1 1.14A3.57 3.57 0 0 0 45.22 43a3 3 0 0 0 .78 1.13 10.85 10.85 0 0 0 1 .87 10 10 0 0 0 1.47.82c.67.3 1.2.54 1.6.71s1 .41 1.85.72 1.64.63 2.13.83 1.16.51 2 .92a17.19 17.19 0 0 1 2 1.13 15.93 15.93 0 0 1 1.63 1.31 6.94 6.94 0 0 1 1.4 1.68 9.72 9.72 0 0 1 .83 2 8.76 8.76 0 0 1 .4 2.5z" />
  </svg>
)

export const AutoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="secondaryColor" d="M26.29 49.64h47.58v9.37H26.29z" />
    <path className="primaryColor" d="M75.9 51.62v9.71a.78.78 0 0 1-.81.81h-2.43v3.24a4.86 4.86 0 1 1-9.71 0v-3.24h-25.9v3.24a4.86 4.86 0 0 1-8.3 3.44 4.68 4.68 0 0 1-1.42-3.44v-3.24h-2.42a.78.78 0 0 1-.58-.23.79.79 0 0 1-.23-.58v-9.71A5.66 5.66 0 0 1 29.77 46h.71l2.66-10.6a7.53 7.53 0 0 1 7.15-5.59h19.42a7.54 7.54 0 0 1 7.16 5.59L69.53 46h.71a5.65 5.65 0 0 1 5.66 5.62zM35.05 56.9a4 4 0 1 0-2.86 1.19 3.9 3.9 0 0 0 2.86-1.19zm2.1-11h25.7l-2.25-9a.94.94 0 0 0-.35-.44.92.92 0 0 0-.53-.24H40.29a.93.93 0 0 0-.53.24.9.9 0 0 0-.35.44zm33.52 11a4 4 0 1 0-2.86 1.19 3.9 3.9 0 0 0 2.86-1.19z" />
    <path className="outerCircle" d="M50 1.89A48.11 48.11 0 1 1 1.89 50 48.16 48.16 0 0 1 50 1.89M50 0a50 50 0 1 0 50 50A50 50 0 0 0 50 0z" />
  </svg>
)

export const LiabilityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="outerCircle" d="M50 1.89A48.11 48.11 0 1 1 1.89 50 48.16 48.16 0 0 1 50 1.89M50 0a50 50 0 1 0 50 50A50 50 0 0 0 50 0z" />
    <path className="secondaryColor" d="M37.38 44.64H60.6v19.01H37.38z" />
    <path className="primaryColor" d="M54.57 44.78h11.1v21.54a1.95 1.95 0 0 1-2 2H36.29a1.95 1.95 0 0 1-2-2V33.68a1.95 1.95 0 0 1 2-2h16.32v11.14a1.95 1.95 0 0 0 1.96 1.96zm3.26 4.57V48a.63.63 0 0 0-.65-.65H42.82a.63.63 0 0 0-.65.65v1.31a.64.64 0 0 0 .18.47.63.63 0 0 0 .47.18h14.36a.63.63 0 0 0 .65-.61zm0 5.22v-1.31a.63.63 0 0 0-.65-.65H42.82a.63.63 0 0 0-.47.18.64.64 0 0 0-.18.47v1.31a.63.63 0 0 0 .65.65h14.36a.63.63 0 0 0 .65-.65zm0 5.22v-1.31a.63.63 0 0 0-.65-.65H42.82a.63.63 0 0 0-.65.65v1.31a.63.63 0 0 0 .65.65h14.36a.64.64 0 0 0 .47-.18.63.63 0 0 0 .18-.47zm6.44-18.35a4.32 4.32 0 0 1 .57.73h-9.62v-9.63a4.68 4.68 0 0 1 .73.57z" />
  </svg>
)

export const HelpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path className="outerCircle" d="M50 1.89A48.11 48.11 0 1 1 1.89 50 48.16 48.16 0 0 1 50 1.89M50 0a50 50 0 1 0 50 50A50 50 0 0 0 50 0z" />
    <ellipse className="chatCircles" cx="39.7" cy="44.67" rx="18.26" ry="14.3" strokeMiterlimit="10" strokeWidth="3.009" />
    <ellipse className="chatCircles" cx="63.48" cy="55.47" rx="13.79" ry="10.8" strokeMiterlimit="10" strokeWidth="3.009" />
    <circle className="secondaryColorDot" cx="58.09" cy="55.47" r="1.6" />
    <circle className="secondaryColorDot" cx="63.48" cy="55.47" r="1.6" />
    <circle className="secondaryColorDot" cx="68.87" cy="55.47" r="1.6" />
    <path className="primaryColor" d="M24.16 53.87l3.62 3.33-5.66 1.49 2.04-4.82M71.29 65.43l3.91-2.98.48 5.83-4.39-2.85" />
    <path fill="none" className="secondaryColorStroke" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.777" d="M30.44 40.46h4.06M30.44 47.51h14M39.11 40.46h8.29" />
  </svg>
)

export const QuestionIcon = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="M50 1.89A48.11 48.11 0 1 1 1.89 50 48.16 48.16 0 0 1 50 1.89M50 0a50 50 0 1 0 50 50A50 50 0 0 0 50 0z"/><path fill="none" d="M30.44 40.46h4.06m-4.06 7.05h14m-5.33-7.05h8.29"/><path d="M44.18 62.273v-1.895c0-12.603 14.418-14.17 14.418-25.042 0-5.272-4.449-9.473-10.793-9.473-3.625 0-6.671 1.399-8.323 2.391-1.071.575-2.142.575-2.802-.496l-.659-.987c-.824-1.071-.739-2.059.411-2.802 1.979-1.32 6.181-3.462 11.616-3.462 9.39 0 16.477 6.016 16.477 14.581 0 13.426-14.418 14.83-14.745 25.538l-.085 1.979c-.084 1.155-.659 1.731-1.894 1.731h-1.567c-1.31 0-2.054-.743-2.054-2.063Zm-.412 12.771c0-1.32.66-2.058 1.979-2.058h2.47c1.319 0 1.979.738 1.979 2.058v2.391c0 1.319-.66 2.058-1.979 2.058h-2.47c-1.319 0-1.979-.739-1.979-2.058v-2.391Z"/></svg>
)