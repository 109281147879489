import { useContext } from 'react'
import Link from 'components/Buttons/Link'
import { AppContext } from 'utils/context'
import { useTranslation } from 'react-i18next'
import './style.scss'

const MakeARequest = ({ hasRequest }) => {

  const { authed } = useContext(AppContext)
  const { t } = useTranslation()

  return authed ? (
      <Link to="/account/requests" Pill className={hasRequest ? 'MakeARequest Existing' : 'MakeARequest'} DataTestId="make-request">
        {t('Make a Request')}
      </Link>
  ) : null
}

export default MakeARequest