import React from 'react'
import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import GetStarted from 'forms/fieldsets/general/GetStarted'
import AddressLookup from 'forms/fieldsets/address/AddressLookup'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import OtherDrivers from 'forms/fieldsets/general/OtherDrivers'
import AddressChangeConfirm from 'forms/fieldsets/address/AddressChangeConfirm'
import { useTranslation } from 'react-i18next'
import './styles/address-change.scss'

const AddressChange = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="address-change" />
      <GetStarted
        path="get-started"
        getStartedPoliciesLabel={t('Which of your policies would you like to change the address for?')}
        getStartedDateLabel={t('When is your move in date?')}
      />
      <AddressLookup path="address-lookup" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('When your address changes, how much you use your vehicle usually changes as well, especially if you are commuting. Please confirm or update your vehicle usage below.')}
      />
      <OtherDrivers path="other-drivers" />
      <AddressChangeConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default AddressChange