import React from 'react'
import EmailSent from 'utils/icons/EmailSent'
import { useTranslation } from 'react-i18next'
const ForgotSent = () => {
  const { t } = useTranslation()
  return (
    <div className="SitePage">
      <div className="container">
        <h2>{t('An email is on its way')}</h2>
        <div className="EmailAnimation"><EmailSent /></div>
        <p>{t("Your password reset instructions are on their way. Please check your email for further instructions. If the email doesn't appear in your inbox, look in your spam folder or contact us.")}</p>
      </div>
    </div>
  )
}

export default ForgotSent
