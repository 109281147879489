import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { driverRemoveReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const DriverRemoveConfirm = () => {

  const { t } = useTranslation()
  let { drivers } = useContext(AccountContext)
  let { data } = useContext(FormContext)

  let reason = driverRemoveReasons.find(r => r[0] === data['remove_reason'])
  let d = drivers.find(d => d.driver_id == data['driver_id']) // eslint-disable-line
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails excludeDriver={d} />
      <ConfirmCard title={t("Driver to Remove")} slug="remove-reason" id={data.id}>
        <ul>
          <li>
            {d && `${d.full_name}`}
          </li>
          <li>
            <small>{t('Reason')}</small>
            {reason[1]}
          </li>
        </ul>
      </ConfirmCard>
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default DriverRemoveConfirm