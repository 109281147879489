import React, { useContext } from 'react'
import Usage from 'forms/fieldsets/vehicle/Usage'
import { AccountContext } from 'utils/context'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const VehicleUsage = () => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  const { vehicles } = useContext(AccountContext)

  const vehicleToReplace = vehicles.find(v => v.vehicle_id === Number(data.vehicle_id)) || {}

  return (
    <fieldset>
      <Usage
        usageLabel={t('How will this vehicle primarily be used?')}
        defaultUsage={vehicleToReplace['vehicle_use']}
        defaultAnnual={vehicleToReplace['annual_distance']}
        defaultDaily={vehicleToReplace['commute_distance']}
        defaultBusiness={vehicleToReplace['bus_annual_distance']}
      />
    </fieldset>
  )
}

export default VehicleUsage