const enabledCarriers = {
  'SR': new Set(['ON']),
  'TRG': new Set(['ON']),
  'TRG2': new Set(['ON']),
  'SAY': new Set(['ON']),
  'GA': new Set(['ON']),
  'GA2': new Set(['ON']),
  'HAL': new Set(['ON']),
  'HAL3': new Set(['ON']),
  'WU': new Set(['BC']),
}

class usageBasedVisible {
  constructor(policies = null, data = null) {
    this.policies = policies
    this.data = data
    this.policy = this.policies.find(p => Number(p.id) === this.data['policy'])
    this.isEnabledForm = this.data.type === 'vehicle_add' || this.data.type === 'vehicle_replace' || this.data.type === 'reinstate_coverage'

    this.errors = {
      generic: 'Could not determine usage based',
      form: 'Usage based: unavailable on this form',
      carrier: 'Usage based: unavailable on this policy\'s carrier',
      province: 'Usage based: unavailable on this policy\'s province and carrier',
    }
  }

  show() {
    try {
      if (this.isEnabledForm) {
        if (this.policy.carrier_code in enabledCarriers) {
          if (!enabledCarriers[this.policy.carrier_code].has(this.policy.province)) {
            throw new Error(this.errors.province)
          }
        } else {
          throw new Error(this.errors.carrier)
        }
      } else {
        throw new Error(this.errors.form)
      }

      return {
        show: true,
        message: null
      }
    } catch (e) {
      return {
        show: false,
        message: e.message
      }
    }


  }

}

export default usageBasedVisible