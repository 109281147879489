import React, { useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Switch, Route, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { AccountContext } from 'utils/context'
import Link from 'components/Buttons/Link'
import ErrorFlash from 'forms/ErrorFlash'
import NoMatch from 'components/NoMatch'
import FormSupport from 'forms/FormSupport'
import { FormContext } from 'utils/context'
import useSupport from 'forms/utils/useSupport'
import useForm from 'forms/utils/useForm'
import { SupportContext } from 'utils/context'
import { requestTypes } from 'requests/Main/vars'
import MultiPageFormNav from './MultiPageFormNav'
import FormBoundary from 'forms/utils/FormBoundary'
import WarningIcon from 'utils/icons/Warning'
import api from 'utils/requests'
import { useTranslation } from 'react-i18next'
import './style.scss'


const MultiPageForm = ({ children }) => {
  
  const { t } = useTranslation()
  const { push } = useHistory()
  const { fs, id } = useParams()
  const { path } = useRouteMatch()

  const [debug, setDebug] = useState(false)

  const { currentRequest, lineitem, updateLineitem, clearCurrentLineitem } = useContext(AccountContext)

  const { snippets, getSnippets, setSupportField, setSupportWithField } = useSupport(fs, path)

  const { data, setFieldValue, clearFieldValue, errors, setFieldError, clearFieldError, clearAllErrors, formError, setFormError, handleFormErrors, formLoading, setFormLoading } = useForm(lineitem)

  const renderChild = Child => {
    if (Child.props.path === fs) {
      return <Route path={path} render={() => Child} />
    }
  }

  const lastPage = () => {
    const { requestType, lineitemUrl } = currentRequest
    if (requestType && lineitemUrl) {
      return requestTypes[requestType][lineitemUrl]['final']
    }
    return ''
  }

  const finalSubmit = () => {
    setFormLoading(true)
    let additionalBody = {}
    if (data.requester_name === 'other') {
      additionalBody.requester_name_other = data.requester_name_other
    }
    api.post('/requests/', {
      requester_name: data.requester_name,
      preferred_contact_method: data.preferred_contact_method,
      preferred_contact_detail: data.preferred_contact_detail,
      lineitems: [data.id],
      ...additionalBody
    })
      .then(() => {
        clearCurrentLineitem()
        setFormLoading(false)
        push(`/account/requests/${currentRequest.lineitemUrl}/received`)
      })
      .catch(err => {
        setFormLoading(false)
        handleFormErrors(err.response.data)
      })
  }

  const handleSubmit = (e = null) => {
    e && e.preventDefault()
    if (fs === 'confirm') {
      finalSubmit()
    } else {
      updateLineitem(data, fs)
        .then(() => finalSubmit())
        .catch(err => {
          setFormLoading(false)
          handleFormErrors(err.response.data)
        })
    }
  }

  useEffect(() => {
    setDebug(Cookies.get('debug'))
  }, []) // eslint-disable-line

  return (
    <FormBoundary>
      <SupportContext.Provider value={{ snippets, getSnippets, setSupportField, setSupportWithField }}>
        <FormContext.Provider value={{ data, setFieldValue, clearFieldValue, errors, setFieldError, clearFieldError, clearAllErrors, formError, setFormError, handleFormErrors, formLoading, setFormLoading }}>
          <div className="FormWrapper" id="subform-anchor">
            <ErrorFlash errors={errors} formError={formError} />
            <form onSubmit={handleSubmit} id="main-form">
              <Switch>
                {React.Children.map(children, ch => renderChild(ch))}
                <Route>
                  <NoMatch>
                    <Link to="/account" Pill Small Outline>
                      {t('Return to dashboard')}
                    </Link>
                  </NoMatch>
                </Route>
              </Switch>
              {formError && <div className="form-error"><WarningIcon />{formError}</div>}
              <FormSupport />
            </form>
            {debug && (
              <div style={{ width: '100%', overflowX: 'scroll' }}>
                <pre>{JSON.stringify(data, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
              </div>
            )}
          </div>
          <MultiPageFormNav
            id={id}
            fs={fs}
            formData={data}
            handleSubmit={handleSubmit}
            handleFormErrors={handleFormErrors}
            setFieldValue={setFieldValue}
            lastPage={lastPage() === fs}
            formLoading={formLoading}
          />
        </FormContext.Provider>
      </SupportContext.Provider>
    </FormBoundary>
  )
}

export default MultiPageForm