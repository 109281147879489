import { useEffect } from 'react'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'

const SlideToggle = ({ name, label, HtmlLabel, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, className, HelpCopy, HelpAnchor, Hidden, MainLabel }) => {

  if (DefaultValue !== undefined) {
    if (typeof DefaultValue !== 'boolean') {
      // do not set booleans with "true" or "false", only {true} or {false}
      throw new Error('DefaultValue for SlideToggle should be null or boolean')
    }
  }

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)

  if (value !== undefined && value !== null) {
    if (typeof value !== 'boolean') {
      // do not set booleans with "true" or "false", only {true} or {false}
      throw new Error('value for SlideToggle should be null or boolean')
    }
  }

  const classes = classNames('field slide-toggle', {
    [className]: className,
    'active': active,
    'touched': touched,
    'checked': value === true || value === 'true',
    'has-error': error,
    'hidden': Hidden,
  })

  useEffect(() => {
    if (DefaultValue === undefined) {
      if (value === null) {
        onChange({ target: { value: false } })
      }
    }
  }, []) // eslint-disable-line

  return (
    <div className={classes} data-testid={name}>
      {MainLabel && <Label name={name}>{MainLabel}</Label>}
      <div className="field-wrapper">
        <Label name={name} secondaryLabel htmlFor={name} className={value ? 'checked': null}>
          <input
            id={name}
            name={name}
            aria-label={label}
            value={value || false}
            checked={value || false}
            onChange={() => onChange({ target: { value: !value } })}
            onFocus={onFocus}
            onBlur={onBlur}
            type="checkbox"
          /> <span className="slide-label">{HtmlLabel || label}</span>
        </Label>
      </div>
      <FieldError error={error} name={name} />
      {HelpCopy && <span className="help-copy">{HelpCopy}</span>}
    </div>
  )
}

export default SlideToggle