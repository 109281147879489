import WarningIcon from 'utils/icons/Warning'

const FieldError = ({ error, name }) => {

  let hasError = false
  let err = null

  if (error && typeof error === 'string') {
    hasError = true
    err = <span>{error}</span>
  } else if (error && typeof error === 'object') {
    hasError = true
    err = error
  }

  return hasError ? (
    <div className={hasError ? 'field-error visible' : 'field-error'} data-testid={`${name}-error`}>
      <WarningIcon />
      {err}
    </div>
  ) : null
}

export default FieldError