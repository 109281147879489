import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import RequestDisclaimer from 'components/RequestDisclaimer'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import ConfirmCard from 'forms/ConfirmCard'
import { vehicleReduceReasons, vehicleReduceDuration, vehicleReduceAmount } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const ReduceCoverageConfirm = () => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)

  let reason = data['reduce_reason'] === 'other' ? data['reduce_reason_other'] : vehicleReduceReasons.find(r => r[0] === data['reduce_reason'])[1] 
  let duration = vehicleReduceDuration.find(d => d[0] === data['reduction_duration'])
  let amount = vehicleReduceAmount["other_carriers"].find(a => a[0] === data['requested_deductible'])
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Reduce Reason")} slug="reduce-reason" id={data.id}>
        <ul className="InfoList">
          <li>
            <small>{t('Vehicle')}</small>
            {data.full_vehicle_name}
          </li>
          <li>
            <small>{t('Reduce reason')}</small>
            {reason}
          </li>
          <li>
            <small>{t('Duration of reduction')}</small>
            {duration[1]}
          </li>
          <li>
            <small>{t('Requested deductible amount')}</small>
            {amount[1]}
          </li>
        </ul>
      </ConfirmCard>
    </fieldset>
  )
}

export default ReduceCoverageConfirm
