import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { antiTheftDeviceTypes, antiTheftDeviceManufacturers, engravingManufacturers } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const EligibilityConfirm = () => {
  
  const { t } = useTranslation()
  const form = useContext(FormContext)

  const yes = <strong className="primary-d1">{t("Yes")}</strong>
  const no = <i className="primary">{t("No")}</i>
  
  const hasAntiTheft = form.data['anti_theft_device']
  const hasAntiTheftTag = form.data["anti_theft_device_manufacturer"] === 'tag'
  const hasEngraving = form.data['vehicle_engraving']
  const hasEngravingTag = form.data["engraving_manufacturer"] === 'tag'
  
  const showAntiTheftDeviceOther = form.data["anti_theft_device_type"] === 'other'
  const showAntiTheftManufacturerOther = form.data["anti_theft_device_manufacturer"] === 'other'
  const showEngravingManufacturerOther = form.data["engraving_manufacturer"] === 'other'
  
  const getNames = (name, antiTheftArrays) => {
    const key = antiTheftArrays.findIndex(innerArray => innerArray.includes(name));
    return antiTheftArrays[key][1]
  }
  
  return (
    <ConfirmCard title={t("Eligibility")} slug="eligibility" id={form.data.id}>
      <ul className="SplitList zebra-list">
        <li className="clearfix">
          <span>{t('Vehicle has anti-theft device')}</span>
          <span>{hasAntiTheft? yes: no}</span>
        </li>
        {hasAntiTheft && <li className="clearfix">
          <span>{t('Anti-theft device type')}</span>
          <span>{getNames(form.data['anti_theft_device_type'], antiTheftDeviceTypes)}</span>
        </li>}
        {showAntiTheftDeviceOther && <li className="clearfix">
          <span>{t('Manually entered anti-theft device type')}</span>
          <span>{form.data['anti_theft_device_type_other']}</span>
        </li>}
        {hasAntiTheft && <li className="clearfix">
          <span>{t('Anti-theft device manufacturer')}</span>
          <span>{getNames(form.data['anti_theft_device_manufacturer'], antiTheftDeviceManufacturers)}</span>
        </li>}
        {showAntiTheftManufacturerOther && <li className="clearfix">
          <span>{t('Manually entered anti-theft device manufacturer')}</span>
          <span>{form.data['anti_theft_device_manufacturer_other']}</span>
        </li>}
        {hasAntiTheftTag && <li className="clearfix">
          <span>{t('Anti-theft device Tag number')}</span>
          <span>{form.data['anti_theft_tag_number']}</span>
        </li>}
        {hasAntiTheft && <li className="clearfix">
          <span>{t('Anti-theft device installation date')}</span>
          <span>{form.data['anti_theft_installation_date']}</span>
        </li>}
        <li className="clearfix">
          <span>{t('Vehicle has etching/engraving')}</span>
          <span>{hasEngraving? yes: no}</span>
        </li>
        {hasEngraving && <li className="clearfix">
          <span>{t('Etching/Engraving manufacturer')}</span>
          <span>{getNames(form.data['engraving_manufacturer'], engravingManufacturers)}</span>
        </li>}
        {showEngravingManufacturerOther && <li className="clearfix">
          <span>{t('Manually entered etching/engraving manufacturer')}</span>
          <span>{form.data['engraving_manufacturer_other']}</span>
        </li>}
        {hasEngravingTag && <li className="clearfix">
          <span>{t('Etching/Engraving Tag number')}</span>
          <span>{form.data['engraving_tag_number']}</span>
        </li>}
        {hasEngraving && <li className="clearfix">
          <span>{t('Etching/Engraving installation date')}</span>
          <span>{form.data['engraving_installation_date']}</span>
        </li>}
        <li className="clearfix">
          <span>{t('Existing or unrepaired damage')}</span>
          <span>{form.data['existing_damage'] ? yes : no}</span>
        </li>
        <li className="clearfix">
          <span>{t('Vehicle has winter tires')}</span>
          <span>{form.data['winter_tires'] ? yes : no}</span>
        </li>
        <li className="clearfix">
          <span>{t('Carry passengers for compensation')}</span>
          <span>{form.data['carry_passengers_for_compensation'] ? yes : no}</span>
        </li>
        <li className="clearfix">
          <span>{t('Special use of the vehicle')}</span>
          <span>{form.data['carry_special_use'] ? yes : no}</span>
        </li>
        <li className="clearfix">
          <span>{t('Vehicle modified or customized')}</span>
          <span>{form.data['vehicle_modified'] ? yes : no}</span>
        </li>
        <li className="clearfix">
          <span>{t('Vehicle rebuilt or salvaged')}</span>
          <span>{form.data['rebuilt_salvaged'] ? yes : no}</span>
        </li>
      </ul>
    </ConfirmCard>
  )
}

export default EligibilityConfirm