import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import paymentInfo from './info'
import { useTranslation, Trans } from 'react-i18next'
const PaymentInfo = ({ policies }) => {
  
  const { t } = useTranslation()
  const { policyNumber } = useParams()
  
  const pol = policies.find(p => p.policy_number === policyNumber)
  const info = paymentInfo[pol.carrier_code]

  return info ? (
    <div className="RequirementList">
      <p>
        <Trans
          i18nKey="yourCarrierMsg"
          components={{ value: pol.carrier, s: <strong /> }}
        />
      </p>
      <p>{t('Information you may need:')}</p>
      <ul className="Required">
        {info.required.map((r, i) => <li key={i}>{r}</li>)}
      </ul>
      <p>
        <a href={info.link} className="Btn Pill" target="_blank" rel="noreferrer">{t('Make a Payment Now')}</a>
      </p>
    </div>
  ) : (
    <div>
      <p>
        <Trans i18nKey="billingRequestMsg" components={{requestLink: <Link to="/account/requests" />}} /></p>
    </div>
  )
}

export default PaymentInfo