import React, { useContext } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { FormContext } from 'utils/context'
import { emailAddress, phoneNumber } from 'forms/utils/cleans'
import { useTranslation } from 'react-i18next'

const PreferredContact = ({ email = '' }) => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)

  const methodChoices = [
    ['email', t('Email')],
    ['phone', t('Phone Call')],
    ['text', t('Text Message')],
  ]

  const changeCb = (value) => {
    clearAllErrors()
    if (value === 'email') {
      setFieldValue('preferred_contact_detail', email)
    } else {
      const detail = data['preferred_contact_detail']
      if (detail === email) {
        setFieldValue('preferred_contact_detail', '')
      } else {
        setFieldValue('preferred_contact_detail', detail)
      }
    }
  }

  const detailLabel = data['preferred_contact_method'] === 'email' ? t('Email Address') : t('Phone Number')
  const detailCleans = data['preferred_contact_method'] === 'email' ? [emailAddress] : [phoneNumber]

  return (
    <div>
      <Radio
        name="preferred_contact_method"
        label={t('If we have any questions for you about this request, what is the best way to contact you?')}
        DefaultValue="email"
        choices={methodChoices}
        ChangeCallback={changeCb}
      />
      <Text
        label={detailLabel}
        name="preferred_contact_detail"
        DefaultValue={email}
        cleans={detailCleans}
      />
    </div>
  )
}

export default PreferredContact