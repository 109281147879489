import { useContext } from 'react'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import FieldError from './FieldError'
import { FormContext, SupportContext, AccountContext } from 'utils/context'
import i18n from 'i18n'
import IconStar from 'utils/icons/Star'

const CoverageRadio = ({ choices, name, label, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, setActiveCov, activeCov, helpCopy, warningTxt, fieldName }) => {

  const { policies } = useContext(AccountContext)
  const { snippets } = useContext(SupportContext)
  const { data } = useContext(FormContext)

  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)

  const classes = classNames('field coverage-radio', {
    'active': active,
    'touched': touched,
    'has-error': error,
  })

  const radioChange = e => {
    let val = e.target.value
    if (val === 'true') {
      val = true
    } else if (val === 'false') {
      val = false
    }
    onChange({ target: { value: val } })
  }

  const labelClass = item => classNames({
    'checked': value == item, // eslint-disable-line
  })

  const snippet = snippets.find(sn => sn && sn.slug === fieldName)

  const getValue = value => {
    if (value === null || value === undefined) {
      return <span className="not-set">--</span>
    }
    const choice = choices.find(ch => ch[0] === value)
    if (choice[0] === 'true' || choice[0] === true) {
      return <span className="included">✓</span>
    } else if (choice[0] === 'false' || choice[0] === false) {
      return <span className="not-included">⨯</span>
    }
    return choice ? choice[1] : value
  }

  const handleClick = () => { 
    setActiveCov(activeCov === name ? '' : name)
  }

  const getTranslatedBody = snippet => {
    return i18n.language === 'fr' ? snippet.french_body : snippet.body
  }

  const recommendedCov = coverage => {
    let policy = policies.find(p => p.id === data.policy)
    let inAlberta = policy.province === "AB"

    switch (coverage) {
      case 'coverage_liability':
        return '2000000'
      case 'coverage_coll':
      case 'coverage_comp':
        if (inAlberta){
          return '500'
        } else {
          return '1000'
        }
      default:
        return true
    }
  }

  const helpCopyTxt = () => {
    let policy = policies.find(p => p.id === data.policy)
    let inAlberta = policy.province === "AB"

    if (inAlberta && helpCopy) {
      return <div className="help-copy"><p>{helpCopy}</p></div>
    } else if (snippet){
      return <div className="help-copy" dangerouslySetInnerHTML={{__html: getTranslatedBody(snippet)}} />
    } else if (helpCopy){
      return <div className="help-copy"><p>{helpCopy}</p></div>
    }
  }

  return (
    <div id={`${name}-field`} className={classes} onClick={activeCov === name ? null : handleClick} data-testid={name}>
      <div className="field-wrapper">
        <div className="coverage-label">
          <strong>{label}</strong><strong>{getValue(value)}</strong>
        </div>
        <div className={activeCov === name ? 'expanded-area expanded' : 'expanded-area'}>
          <div className="coverage-choices">
            {choices.map(ch =>
              <Label
                key={ch[1]}
                secondaryLabel
                htmlFor={name + ch[1]}
                className={labelClass(ch[0])} // eslint-disable-line
              >
                <input
                  type="radio"
                  id={name + ch[1]}
                  name={name}
                  onChange={radioChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  checked={value == ch[0]} // eslint-disable-line
                  value={ch[0]}
                />
                <span>{ch[1]}</span>
                {recommendedCov(fieldName) === ch[0] && <IconStar/>}
              </Label>
            )}
          </div>
          {value === false && <b className="warning-txt">{warningTxt}</b>}
          {helpCopyTxt()}
        </div>
      </div>
      <FieldError error={error} name={name} />
    </div>
  )
}

export default CoverageRadio
