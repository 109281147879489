import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import { vehicleRemoveReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const VehicleRemoveConfirm = () => {

  const { t } = useTranslation()
  let form = useContext(FormContext)
  let acct = useContext(AccountContext)

  const removedVehicle = acct.vehicles.find(v =>
    String(v.vehicle_id) == form.data['vehicle_id']  // eslint-disable-line
  ) || {}

  let reason = vehicleRemoveReasons.find(r => r[0] === form.data['remove_reason'])[1] 
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Vehicle to Remove")} slug="remove-reason" id={form.data.id}>
        <ul>
          <li>
            {removedVehicle.full_vehicle_name}
          </li>
          <li>
            <small>{t('Reason for Removing')}</small>
            {reason}
          </li>
        </ul>
      </ConfirmCard>
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default VehicleRemoveConfirm