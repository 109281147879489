import React, { useContext } from 'react'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import { FormContext } from 'utils/context'
import { vehicleRemoveReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'

const RemoveReason = () => {
  
  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)

  let showMovingTo = data['remove_reason'] === 'moving_insurance'

  const reasonChange = value => {
    if (value !== 'moving_insurance') {
      setFieldValue('moving_to_carrier', null)
      setFieldValue('moving_to_policy_number', null)
      clearAllErrors()
    }
  }

  return (
    <fieldset>
      <Radio
        name="remove_reason"
        label={t("Why are you removing this vehicle?")}
        choices={vehicleRemoveReasons}
        ChangeCallback={reasonChange}
      />
      {showMovingTo && (
        <>
          <Text name="moving_to_carrier" label={t("Insurance Carrier")} />
          <Text name="moving_to_policy_number" label={t("Policy Number")} />
        </>
      )}
    </fieldset>
  )
}

export default RemoveReason