import React from 'react'
import Radio from 'forms/fields/Radio'
import { useTranslation } from 'react-i18next'
const CoverageWarning = () => {
  const { t } = useTranslation()
  return (
    <fieldset>
      <p className="fieldset-intro">
      <small>{t('Please confirm that you understand that this change cancels all coverage related to the use and operation of this vehicle. You understand that you cannot drive, use or move your vehicle for any reason, even in an emergency situation. If you use or operate the vehicle there will be no coverage for any damage to your vehicle and more importantly, there is no liability coverage if you cause injury to another person or damage to their vehicle or property. Finally, you are aware that in the event of a hit and run causing damage to your vehicle, there is no coverage. You agree that before using or operating this vehicle for any reason you must first speak with one of our insurance brokers to reinstate the required coverage.')}</small>
      </p>
      <Radio
        name="warning_confirm"
        label={t("Do you confirm?")}
        choices={[
          [true, t('Yes, I confirm')]
        ]}
        Stacked
      />
    </fieldset>
  )
}

export default CoverageWarning
