import React, {useState, useLayoutEffect} from 'react'
import ReactDOM from 'react-dom'

const SubForm = React.memo(({ children, anchorElem = document.getElementById('subform-anchor')}) => {
  
  const [dimensions, setDimensions] = useState({ top: 0, left: 0, width: 0 })

  useLayoutEffect(() => {
    let observer = null

    if (anchorElem) {

      observer = new ResizeObserver(function(entries) {
        const box = anchorElem.getBoundingClientRect()
        
        setDimensions({
          top: box.height + box.top + window.scrollY,
          left: box.x,
          width: box.width,
        })
      })

      observer.observe(document.body)
      observer.observe(anchorElem)

    }

    return () => {
      if (observer !== null) {
        observer.disconnect()
      }
    };

  }, [anchorElem])

  const domEl = document.getElementById('subform-root')

  if (!domEl) return null
  
  
  return ReactDOM.createPortal(
    <div className="SubForm" style={{
      top: dimensions.top + 20,
      left: dimensions.left,
      width: dimensions.width,
    }}>
      {children}
    </div>,
    domEl
  )
})

export default SubForm