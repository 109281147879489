import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import Radio from 'forms/fields/Radio'
import Text from 'forms/fields/Text'
import TextArea from 'forms/fields/TextArea'
import { vehicleReinstateReasons } from 'forms/utils/vars'
import PolicyDrivers from 'forms/fields/PolicyDrivers'
import { useTranslation } from 'react-i18next'

const ReinstateReason = () => {

  const { t } = useTranslation()
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)

  let showOtherText = data['reinstate_reason'] === 'other'
  let showDamagedText = data['existing_damage'] === true
  let showUnreportedText = data['has_unreported_claim'] === true
  let showOtherDriver = data['principal_driver'] == 0  // eslint-disable-line
  


  const reasonChange = value => {
    if (value !== 'other') {
      setFieldValue('reinstate_reason_other', null)
      clearAllErrors()
    }
  }

  const driverChangeCallback = value => {
    if (value !== '0') {
      setFieldValue('principal_driver_other', null)
      clearAllErrors()
    }
  }
  return (
    <fieldset>
      <Radio
        name="reinstate_reason"
        label={t("Why are you reinstating coverage for this vehicle?")}
        choices={vehicleReinstateReasons}
        ChangeCallback={reasonChange}/>
      {showOtherText && (
        <Text name="reinstate_reason_other" label={t("Other Reason")} />
      )}
      <Radio
        name="existing_damage"
        label={t("Any existing/unrepaired damages on vehicle?")}
        choices={[[true, t('Yes')], [false, t('No')]]}/>
      {showDamagedText && (
        <div>
          <div className="help-copy">{t('We may need you to send in clear photos of the damage for review.')}</div>
          <TextArea name="damaged_vehicle_details" label={t("Please give details of the damage on the vehicle.")} />
          <Radio
          name="rebuilt_salvaged"
          label={t("Has this vehicle been rebuilt or salvaged?")}
          choices={[[true, t('Yes')], [false, t('No')]]}/>
        </div>
      )}
      <Radio
        name="has_unreported_claim"
        label={t("Is there an unreported claim?")}
        choices={[[true, t('Yes')], [false, t('No')]]}/>
      {showUnreportedText && (
        <div>
          <div className="help-copy">{t('We may need you to send in clear photos of the damage for review.')}</div>
          <TextArea name="unreported_claim_details" label={t("Please give details of the claim.")} />
        </div>
      )}
      <PolicyDrivers
        name="principal_driver"
        label={t("Who will be the primary driver of this vehicle?")}
        ChangeCallback={driverChangeCallback}
        IncludeNotListed
        Stacked
      />
      {showOtherDriver && (
        <Text name="principal_driver_other" label={t("Other Driver")} />
      )}
    </fieldset>
  )
}

export default ReinstateReason
