import TextArea from 'forms/fields/TextArea'
import { useTranslation } from 'react-i18next'

const BillingTextArea = ({ InquiryType }) => {

  const { t } = useTranslation()

  const copy = InquiryType === 'payment_details' ?
    t('Please describe the payment details you would like to update') :
    t('Please provide more information below')

  return (
    <TextArea
      name="details"
      label={copy}
      SupportCopy={t('We require approximately 7 - 10 business days notice to make the changes. Please ensure you have the funds in your current account for your next regularly scheduled payment.')}
      HelpCopy={t('If you are paying by Credit Card DO NOT provide this information, someone will be in contact to collect updated details. If you are paying through your bank account, please include your account number, transit number, institution number, name(s) of the account holder(s) and effective date of the change.')}
    />
  )
}

export default BillingTextArea