import { useState, useEffect } from 'react'
import { getRequest } from 'utils/requests'

const useGlobalMessage = (initState = []) => {
  const [globalMessages, setGlobalMessages] = useState(initState)

  useEffect(() => {
    getGlobalMessages()
  }, []) // eslint-disable-line

  const getGlobalMessages = async () => {
    await getRequest('/api/globalmessages/')
      .then(res => {
        setGlobalMessages(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return {
    globalMessages
  }
}

export default useGlobalMessage
