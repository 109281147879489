import React, { useContext, useEffect } from 'react'
import Radio from 'forms/fields/Radio'
import DuoDate from 'forms/fields/DuoDate'
import ArrayRadio from 'forms/fields/ArrayRadio'
import CancellationItems from './CancellationItems'
import SubForm from 'forms/SubForm'
import SimpleForm from 'forms/SimpleForm'
import InsuranceHistorySubForm from './InsuranceHistorySubForm'
import { FormContext } from 'utils/context'
import { delRequest } from 'utils/requests'
import { useTranslation } from 'react-i18next'

const InsuranceHistory = () => {
  
  const { t } = useTranslation()
  const { data, setFieldValue, setFieldError, clearFieldError } = useContext(FormContext)
  
  const driver_name = data['first_name']

  const NoneCallback = value => {
    if (value === 0) {
      if (data['cancellations'].length > 0) {
        setFieldError('has_cancellations', t('Please remove all cancellations first'))
        setFieldValue('has_cancellations', '1')
      }
    }
  }

  const deleteCancellation = (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    const request = delRequest(`/api/lineitems/cancellations/${id}/`)
    fetch(request)
      .then(res => {
        const next = [...data['cancellations']].filter(x => x.id !== id)
        setFieldValue('cancellations', next)
        clearFieldError('has_cancellations')
      })
      .catch(console.log)
  }

  const addSuccess = res => {
    clearFieldError('has_cancellations')
    setFieldValue('cancellations', [
      ...data['cancellations'],
      res,
    ])
  }
  
  const cancellationCallback = value => {
    if (value === false) {
      if (data['cancellations'].length > 0) {
        setFieldError('has_cancellations', t('Please remove all cancellations first'))
        setFieldValue('has_cancellations', '1')
        setFieldValue('other_insurance', true)
      } else {
        setFieldValue('first_listed', null)
        clearFieldError('first_listed')
        setFieldValue('currently_listed', null)
        clearFieldError('currently_listed')
        setFieldValue('start_date', null)
        clearFieldError('start_date')
        setFieldValue('has_cancellations', '0')
        clearFieldError('has_cancellations')
      }
    }
  }
  useEffect(() => {
    if (data.cancellations.length > 0) {
      setFieldValue('has_cancellations', '1')
    }
  }, []) // eslint-disable-line
  
  const showOtherInsurance = data['other_insurance'] === true

  return (
    <fieldset className="InsuranceHistory">
      
      <Radio
        name="other_insurance"
        label={t('Was {{driver_name}} ever listed as a driver on an insurance policy in Canada or the US?', {driver_name: driver_name})}
        choices={[[true, t('Yes')], [false, t('No')]]} 
        ChangeCallback={cancellationCallback}
      />
      {showOtherInsurance && 
        <DuoDate
          name='first_listed'
          label={t('When was {{driver_name}} first listed as a driver on an insurance policy in Canada or US?', {driver_name: driver_name})}
          HelpCopy={t('Some insurance companies need to know how long a driver has been insured without interruption (i.e. without gaps in insurance coverage, such as a cancellation) as a primary or occasional driver, with one or more companies.')}
        />
      }
      {showOtherInsurance && 
        <Radio
          name="currently_listed"
          label={t('Is {{driver_name}} currently listed as a driver on an insurance policy in Canada or US?', {driver_name: driver_name})}
          choices={[[true, t('Yes')], [false, t('No')]]} 
          HelpCopy={t('Some insurance companies need to know if a driver is currently insured as a primary or occasional driver. Please indicate if this driver is currently covered under an existing auto insurance policy. This includes being currently listed on a parent\'s, spouse\'s, or partner\'s insurance policy.')}
        />
      }
      {showOtherInsurance && 
        <DuoDate
          name='start_date'
          label={t('What is the start date of {{driver_name}}\'s current or most recent insurance policy?', {driver_name: driver_name})}
          HelpCopy={t('If you have been with this company for multiple years, please pick the initial start date of your policy and not your latest renewal date.')}
        />
      }
      {showOtherInsurance && 
        <ArrayRadio
          name="has_cancellations"
          label={t('Has an insurance company cancelled {{driver_name}}\'s policy in the last 10 years?', {driver_name: driver_name})}
          ArrayToggleValue='1'
          ChangeCallback={NoneCallback}
          choices={[[1, t('Yes')], [0, t('No')]]}
          helpCopy={t('A policy may have been cancelled due to a missed payment, non-disclosure, misrepresentation or for underwriting reasons.')}
        >
          <CancellationItems
            items={data['cancellations']}
            title="cancellation_reason"
            onDelete={deleteCancellation}
            Placeholder={t('Please provide details on the driver below')}
          />
          <SubForm>
            <SimpleForm SubmitUrl="/api/lineitems/cancellations/" SubmitLabel={t('Submit')} SuccessCallback={addSuccess} InitialData={{ owner: data.id }} AllowCancel>
              <InsuranceHistorySubForm parentData={data} />
            </SimpleForm>
          </SubForm>
        </ArrayRadio>
      }
    </fieldset>
  )
}

export default InsuranceHistory
