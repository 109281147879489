import React, { useContext } from 'react'
import { AccountContext } from 'utils/context'
import Drivers from 'forms/fields/Drivers'
import { useTranslation } from 'react-i18next'

const AllDrivers = ({ value, exclude, other, ...props }) => {
  
  const { t } = useTranslation()
  
  let { drivers } = useContext(AccountContext)

  let finalChoices = []

  drivers.forEach(d => {
    finalChoices.push([d.driver_id, `${d.full_name}`])
  })

  if (exclude && exclude.id) {
    finalChoices = finalChoices.filter(ch => ch[0] !== exclude.id)
  }

  if (other) finalChoices.push(['other', t('Other')])
  
  return (
    <Drivers
      {...props}
      value={value}
      choices={finalChoices}
    />
  )
}

export default AllDrivers