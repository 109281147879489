import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext, AccountContext } from 'utils/context'
import waiverDepreciationVisible from 'forms/utils/waiver-depreciation'
import usageBasedVisible from 'forms/utils/usage-based'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/helpers'
import coverageFields from 'forms/utils/coverageFields'

const CoverageConfirm = () => {

  const { t } = useTranslation()
  const { policies } = useContext(AccountContext)
  const { data } = useContext(FormContext)

  const waiver = new waiverDepreciationVisible(policies, data).show()
  const usageBased = new usageBasedVisible(policies, data).show()

  const yes = <span className="check-mark">✔︎</span>
  const no = <span className="cross-mark">x</span>

  const policy = policies.find(p => Number(p.id) === Number(data['policy']))

  const glassCovShow = data['registered_province'] === 'AB' || (policy.province === 'AB' && data['registered_province'] === undefined)

  const coverageChoice = (cov, choice) => {
    return coverageFields.find(c => c.name === cov).choices.filter(ch => ch.includes(choice)).flatMap(arr => arr)[1]
  }

  return (
    <ConfirmCard title={t("Coverage")} slug="coverage" id={data.id}>
      <ul className="SplitList zebra-list">
        <li className="clearfix">
          <span>{t('Liability')}</span> <span>{formatCurrency(data['coverage_liability'], '$0,0')}</span> 
        </li>
        <li className="clearfix">
          <span>{t('Collision')}</span> <span>{coverageChoice('coverage_coll', data['coverage_coll'])}</span>
        </li>
        <li className="clearfix">
          <span>{t('Comprehensive')}</span> <span>{coverageChoice('coverage_comp', data['coverage_comp'])}</span>
        </li>
        <li className="clearfix">
          <span>{t('Loss of Use')}</span> {data['endorsement_loss_of_use'] ? yes : no}
        </li>
        <li className="clearfix">
          <span>{t('Non-owned Autos')}</span> {data['endorsement_non_owned_autos'] ? yes : no}
        </li>
        <li className="clearfix">
          <span>{t('Accident')}</span> {data['endorsement_accident'] ? yes : no}
        </li>
        <li className="clearfix">
          <span>{t('Conviction')}</span> {data['endorsement_conviction'] ? yes : no}
        </li>
        {waiver.show && (
          <li className="clearfix">
            <span>{t('Waiver of Depreciation')}</span> {data['waiver_depreciation'] ? yes : no}
          </li>
        )}
        {glassCovShow && (
          <li className="clearfix">
            <span>{t('Limited Glass Coverage')}</span> {data['glass_coverage'] ? yes : no}
          </li>
        )}
        {usageBased.show && (
          <li className="clearfix">
            <span>{t('Usage Based')}</span> {data['usage_based'] ? yes : no}
          </li>
        )}
      </ul>
    </ConfirmCard>
  )
}

export default CoverageConfirm