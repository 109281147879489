import { useState } from 'react'
import classNames from 'classnames'
import { BiError, BiErrorCircle } from 'react-icons/bi'
import './style.scss'
import { useTranslation } from 'react-i18next'

const GlobalMessage = ({message, page}) => {

  const { t, i18n } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const [showMsg, setShowMsg] = useState(true)

  let title = ''
  let body = ''

  const classes = classNames('alert', {
    [message.type]: message.type,
  })

  if (message.pages === 'login' && page !== '/' && page !== '/account')
    return null

  if (i18n.language === 'fr'){
    title = message.french_title
    body = message.french_message
  } else {
    title = message.title
    body = message.message
  }

  return ( showMsg && 
    <div className={classes}> 
      <div className='messageWrapper'>
      
        {message.type === 'warning' ? <BiError /> : <BiErrorCircle />}
        <strong> {title}</strong>

        <div className='closebtn' onClick={() => setShowMsg(false)}>&times;</div>

        {showMore ? <div id={`alertDescription${message.id}`} className='alertDescription' dangerouslySetInnerHTML={{__html: body}}></div> : ''}

        <div id={`showMoreBtn${message.id}`} className='showMoreBtn' onClick={() => setShowMore(!showMore)}>
          {showMore ? t('Hide') : t('Read more')}
        </div>
        
      </div>
    </div>
  )     
}
export default GlobalMessage

