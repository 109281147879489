import React, { useContext } from 'react'
import SimpleForm from 'forms/SimpleForm'
import Button from 'components/Buttons/Button'
import Link from 'components/Buttons/Link'
import TenantStr from 'components/TenantStr'
import Text from 'forms/fields/Text'
import { emailAddress } from 'forms/utils/cleans'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
const ForgotPassword = ({ history }) => {

  const { t } = useTranslation()
  const { showMaintenance, authed, logout } = useContext(AppContext)

  const resetSuccess = res => {
    history.push('/check-email')
  }

  const handleLogout = () => logout()

  return (
    <div className="SitePage">
      <div className="container">
        {authed ? (
          <div>
            <h2>{t('You are logged in')}</h2>
            <Link to="/account">{t('View your account')}</Link>
            <Button onClick={handleLogout} DataTestId="logout">{t('Log out now')}</Button>
          </div>
        ) : (
          <div>
            <h2>{t('Forgot Password')}</h2>
            <p>
              <Trans
                i18nKey="cantRememberPassword"
                components={{ productName: <TenantStr ProductName /> }}
              />
            </p>
            <p className="inline-links" >
              <Trans
                i18nKey="onlyReceiveEmail"
                components={{ productName: <TenantStr ProductName />, signupLink: <Link to="/signup" />}}
              />
            </p>

            {showMaintenance ? (
              <p>{t('Password reset is currently disabled')}</p>
            ) : (
              <SimpleForm SubmitUrl="/api/users/password/reset/" SubmitLabel={t('Send Email')} SuccessCallback={resetSuccess}>
                <Text
                  name="email"
                  placeholder="user@email.ca"
                  cleans={[emailAddress]}
                />
              </SimpleForm>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
