import Tile from 'components/Tile'
import ListIcon from 'utils/icons/List'
import {
  VehicleAddHelp, VehicleReplaceHelp, VehicleRemoveHelp, DriverAddHelp, DriverRemoveHelp, AddressChangeHelp,
} from './RequirementsHelp'
import { useTranslation } from 'react-i18next'

const VehicleRemove = () => {
const { t } = useTranslation()
  return (
    <ul>
      <li>{t('The reason you are removing this vehicle')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const DriverRemove = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>{t('The reason you are removing this driver')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const AddressChange = ({ multipleAuto = false }) => {
  const { t } = useTranslation()
  return (
    <ul>
      {multipleAuto && <li>{t('Policy number')}</li>}
      <li>{t('Your new address, including postal code')}</li>
      <li>{t('The date you are moving to your new address')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const DriverAdd = ({ multipleAuto = false }) => {
  const { t } = useTranslation()
  return (
    <ul>
      {multipleAuto && <li>{t('Policy number')}</li>}
      <li>{t('Personal details of the driver (name, date of birth, etc.)')}</li>
      <li>{t("Driver's licence number")}</li>
      <li>{t("Driver's licensing dates")}</li>
      <li>{t('Licence suspension details, if applicable')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const VehicleAdd = ({ multipleAuto = false }) => {
  const { t } = useTranslation()
  return (
    <ul>
      {multipleAuto && <li>{t('Policy number')}</li>}
      <li>{t('Vehicle information (i.e. VIN, year, make, model, and trim)')}</li>
      <li>{t('Name and address of the financial institution if the vehicle is financed/leased')}</li>
      <li>{t('Date you are picking up the vehicle')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const VehicleAddQuote = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>{t("Your quote will be processed as of today's date.")}</li>
      <li>{t('If you decide to make any changes to your policy after receiving this quote, you will be asked to confirm the effective date of the change.')}</li>
      <li>{t('We will contact you to provide a quote after you submit this request, at which point you will be given the option of adding the vehicle to your policy.')}</li>
    </ul>
  )
}

const VehicleReplace = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>{t('New vehicle information (i.e. VIN, year, make, model, and trim)')}</li>
      <li>{t('Name and address of the financial institution if the vehicle is financed/leased')}</li>
      <li>{t('The day you are picking up the new vehicle')}</li>
      <li>{t('The day that you are losing possession of your old vehicle')}</li>
      <li>{t('Insurance Company and Policy Number of other licensed drivers that have frequent access to your vehicle')}</li>
    </ul>
  )
}

const VehicleReplaceQuote = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>{t("Your quote will be processed as of today's date.")}</li>
      <li>{t('If you decide to make any changes to your policy after receiving this quote, you will be asked to confirm the effective date of the change.')}</li>
      <li>{t('We will contact you to provide a quote after you submit this request, at which point you will be given the option of adding the vehicle to your policy.')}</li>
    </ul>
  )
}

const CoverageChange = () => (
  <ul>
    <li>coming soon.</li>
  </ul>
)

const ReduceCoverage = () => {
  const { t } = useTranslation()
  return (
    <ul>
      <li>{t('Approximate duration for the coverage reduction.')}</li>
      <li>{t('Address for where the vehicle is being parked.')}</li>
      <li>{t('If the vehicle is leased, we will require written approval from leasing company to reduce coverage on the vehicle.')}</li>
    </ul>
  )
}

const ReinstateCoverage = () => {
  const { t } = useTranslation()
  return (
    <div>{t('Any potential credit resulting from this change will be calculated based on the number of days that coverage was reduced, coverage must be reduced for a minimum of 45 -60 days (timeframe varies by insurance company).')}</div>
  )
}

const PolicyCancel = () => {
  const { t } = useTranslation()
return (
  <ul>
    <li>{t('The reason for cancelling.')}</li>
    <li>{t('Insurance company and policy number of new provider if applicable.')}</li>
  </ul>
  )
}

const Requirements = ({ requestType }) => {
  const { t } = useTranslation()
  let Request = null
  let HelpCopy = null
  let Title = <strong>{t("What You'll Need")}</strong>

  switch (requestType) {
    case 'vehicle-remove':
      Request = <VehicleRemove />
      HelpCopy = <VehicleRemoveHelp />
      break
    case 'driver-remove':
      Request = <DriverRemove />
      HelpCopy = <DriverRemoveHelp />
      break
    case 'address-change':
      Request = <AddressChange />
      HelpCopy = <AddressChangeHelp />
      break
    case 'vehicle-add':
      Request = <VehicleAdd />
      HelpCopy = <VehicleAddHelp />
      break
    case 'vehicle-add-quote':
      Request = <VehicleAddQuote />
      Title = <strong>{t('What You Need to Know')}</strong>
      break
    case 'vehicle-replace':
      Request = <VehicleReplace />
      HelpCopy = <VehicleReplaceHelp />
      break
    case 'vehicle-replace-quote':
      Request = <VehicleReplaceQuote />
      Title = <strong>{t('What You Need to Know')}</strong>
      break
    case 'driver-add':
      Request = <DriverAdd />
      HelpCopy = <DriverAddHelp />
      break
    case 'coverage-change':
      Request = <CoverageChange />
      break
    case 'reduce-coverage':
      Request = <ReduceCoverage />
      break
    case 'reinstate-coverage':
      Request = <ReinstateCoverage />
      Title = <strong>{t('Please note')}</strong>
      break
    case 'policy-cancel':
      Request = <PolicyCancel />
      break
    default:
      Request = <span>{t('Unknown request type')}</span>
      HelpCopy = <span>{t('Unknown help copy')}</span>
  }

  return Request ? (
    <div className="RequestContainer container">
      <Tile className="RequirementList" Icon={ListIcon} IconName="ListIcon">
        <div className="TileHeader">
          {Title}
        </div>
        <div className="TileBody">
          {Request}
        </div>
      </Tile>
      {HelpCopy}
    </div>
  ) : null
}

export default Requirements