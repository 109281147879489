import { useState, useEffect, useContext } from 'react'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const useField = (name, DefaultValue = null, ChangeCallback, FocusCallback, BlurCallback, cleans, Optional, maxLength, minLength) => {
  
  const { t } = useTranslation()

  const { data, setFieldValue, errors, setFieldError, clearFieldError, formError, setFormError } = useContext(FormContext)

	const [touched, setTouched] = useState(false)
  const [active, setActive] = useState(false)

  const onChange = e => {
    let val = e.target.value
    if (errors[name]) {
      clearFieldError(name)
    }
    setFieldValue(name, val)
    if (formError) setFormError('')
    if (ChangeCallback) {
      ChangeCallback(val, name)
    }
    if(maxLength && typeof val === 'string' && val.length > maxLength){
      setFieldError(name, t('Cannot be more than {{maxLength}} characters', {maxLength: maxLength}))
    }
    if(minLength && typeof val === 'string' && val.length < minLength){
      setFieldError(name, t('Must be at least {{minLength}} characters', {minLength: minLength}))
    }
  }

  const onFocus = e => {
    const val = e.target.value
    setActive(true)
  	setTouched(true)
    if (FocusCallback) FocusCallback(val, name)
  }

  const onBlur = e => {
    const val = e.target.value
    if (val === '' || val === '-1') {
      if (!Optional) {
        setFieldError(name, t('This field is required'))
      }
    } else if (cleans) {
      cleans.forEach(clean => {
        try {
          clean(val)
        } catch (e) {
          setFieldError(name, e.message)
        }
      })
    }
    if (BlurCallback) BlurCallback(val, name)
    setActive(false)
  }

  useEffect(() => {
    if ((data[name] === null || data[name] === undefined) && DefaultValue !== undefined && DefaultValue !== null) {
      if (data[name] !== DefaultValue) {
        setFieldValue(name, DefaultValue)
        if (ChangeCallback) {
          ChangeCallback(DefaultValue)
        }
        setTouched(true)
      }
    }
  }, []) // eslint-disable-line

  let fieldValue = data[name]
  const fieldError = errors[name]

  return {
    value: fieldValue,
    active,
    touched,
    onChange,
    onFocus,
    onBlur,
    error: fieldError,
    setFieldError,
    clearFieldError,
  }
}

export default useField
