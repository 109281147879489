import { useState, useContext } from 'react'
import errorLookup from 'utils/errorLookup'
import { AccountContext, AppContext } from 'utils/context'

const useForm = (InitialData = {}) => {
  
  const [data, setData] = useState(InitialData)
  const { lineitemProvince } = useContext(AccountContext)
  const { tenant } = useContext(AppContext)
  const [errors, setErrors] = useState({})
  // const [errors, setErrors] = useState({
  //   radio: 'some error!',
  //   radio_stacked: 'some error!',
  //   toggle: 'some error!',
  //   toggle_two: 'some error!',
  //   email: 'some error!',
  //   datepicker: 'some error!',
  //   select: 'some error!',
  //   textarea: 'some error!',
  // })
  const [formError, setFormError] = useState('')
  const [formLoading, setFormLoading] = useState(false)

  const setFieldValue = (name, value) => {
    setData(prevState => {
      let next = Object.assign({}, prevState)
      next[name] = value
      return next
    })
  }

  const clearFieldValue = (name) => {
    setData(prevState => {
      let next = Object.assign({}, prevState)
      delete next[name]
      return next
    })
  }

  const clearFormData = () => setData(InitialData || {})

  const setFieldError = (name, error) => {
    setErrors(prevState => {
      let next = Object.assign({}, prevState)
      let setError = errorLookup(error, lineitemProvince, tenant)
      next[name] = setError ? setError : error
      return next
    })
  }

  const clearFieldError = field => {
    setErrors(prevState => {
      let next = Object.assign({}, prevState)
      delete next[field]
      return next
    })
  }

  const clearAllErrors = () => {
    setErrors({})
    setFormError('')
  }

  const handleFormErrors = errors => {
    if (errors.error) {
      let error = errorLookup(errors.error, lineitemProvince, tenant)
      setFormError(error ? error : errors.error)
    } else if (errors.non_field_errors) {
      let error = errorLookup(errors.non_field_errors[0], lineitemProvince, tenant)
      setFormError(error ? error : errors.non_field_errors[0])
    } else {
      Object.keys(errors).forEach(e =>
        setFieldError(e, errors[e][0])
      )
    }
  }
  
  return {
    data,
    setData,
    setFieldValue,
    clearFieldValue,
    clearFormData,
    errors,
    setFieldError,
    clearFieldError,
    clearAllErrors,
    formError,
    setFormError,
    handleFormErrors,
    formLoading,
    setFormLoading,
  }
}

export default useForm