// a list of every possible SR, segregated by parent request type
// the `slug` entry is required for scenarios where the front end
// presents one type of request but the back end and pivotal
// handle it as another type of request

import i18n from 'i18n';

export const requestTypes = {
  question: {
    'general-request': {
      label: i18n.t('General Question'),
      slug: 'general_request',
      final: 'preferred-contact',
      past_tense: i18n.t('Asked a general question'),
    },
    'billing-request': {
      label: i18n.t('Billing Question'),
      slug: 'billing_request',
      final: 'preferred-contact',
      past_tense: i18n.t('Asked a billing question'),
    },
    'payment-schedule': {
      label: i18n.t('Request My Payment Schedule'),
      slug: 'payment_schedule',
      final: 'preferred-contact',
      past_tense: i18n.t('Requested payment schedule'),
    },
    'policy-renew': {
      label: i18n.t('Review my Renewal'),
      slug: 'policy_renew',
      final: 'confirm',
      past_tense: i18n.t('Reviewed my Renewal'),
    },
    'question-other': {
      label: i18n.t('Other'),
      slug: 'general_question',
      // url: 'general-question',
      final: 'preferred-contact',
      past_tense: i18n.t('Asked a question'),
    },
  },
  quote: {
    'vehicle-add-quote': {
      label: i18n.t('Quote for a New Vehicle'),
      slug: 'vehicle_add_quote',
      final: 'confirm',
      past_tense: i18n.t('Quote for a new vehicle'),
    },
    'vehicle-replace-quote': {
      label: i18n.t('Quote for a Replacement Vehicle'),
      slug: 'vehicle_replace_quote',
      final: 'confirm',
      past_tense: i18n.t('Quote for a replacement vehicle'),
    },
    'quote-other': {
      label: i18n.t('Other'),
      slug: 'general_quote',
      final: 'preferred-contact',
      // url: 'general-quote',
      past_tense: i18n.t('Other quote'),
    },
  },
  change: {
    'vehicle-add': {
      label: i18n.t('Add a Vehicle'),
      slug: 'vehicle_add',
      final: 'confirm',
      past_tense: i18n.t('Added a vehicle'),
    },
    'vehicle-replace': {
      label: i18n.t('Replace a Vehicle'),
      slug: 'vehicle_replace',
      final: 'confirm',
      past_tense: i18n.t('Replaced a vehicle'),
    },
    'vehicle-remove': {
      label: i18n.t('Remove a Vehicle'),
      slug: 'vehicle_remove',
      final: 'confirm',
      past_tense: i18n.t('Removed a vehicle'),
    },
    'driver-add': {
      label: i18n.t('Add a Driver'),
      slug: 'driver_add',
      final: 'confirm',
      past_tense: i18n.t('Added a driver'),
    },
    'driver-remove': {
      label: i18n.t('Remove a Driver'),
      slug: 'driver_remove',
      final: 'confirm',
      past_tense: i18n.t('Removed a driver'),
    },
    'address-change': {
      label: i18n.t('Change my Address'),
      slug: 'address_change',
      final: 'confirm',
      past_tense: i18n.t('Changed address'),
    },
    'payment-date': {
      label: i18n.t('Update my Payment Date'),
      slug: 'payment_date',
      final: 'preferred-contact',
      past_tense: i18n.t('Updated payment date'),
    },
    'payment-details': {
      label: i18n.t('Update my Payment Details'),
      slug: 'payment_details',
      final: 'preferred-contact',
      past_tense: i18n.t('Updated payment details'),
    },
    'coverage-change': {
      label: i18n.t('Change my Coverage'),
      slug: 'coverage_change',
      final: 'preferred-contact',
      past_tense: i18n.t('Changed coverage'),
    },
    'reduce-coverage': {
      label: i18n.t('Reduce my Vehicle Coverage'),
      slug: 'reduce_coverage',
      final: 'confirm',
      past_tense: i18n.t('Requested to reduce vehicle coverage'),
    },
    'reinstate-coverage': {
      label: i18n.t('Reinstate my Vehicle Coverage'),
      slug: 'reinstate_coverage',
      final: 'confirm',
      past_tense: i18n.t('Requested to reinstate vehicle coverage'),
    },
    'policy-cancel': {
      label: i18n.t('Cancel my Policy'),
      slug: 'policy_cancel',
      final: 'confirm',
      past_tense: i18n.t('Cancelled my Policy'),
    },
    'change-other': {
      label: i18n.t('Other'),
      slug: 'general_change',
      // url: 'general-change',
      final: 'preferred-contact',
      past_tense: i18n.t('Other change'),
    },
  }
}