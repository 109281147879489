import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import Tile from 'components/Tile'
import Text from 'forms/fields/Text'
import Select from 'forms/fields/Select'
import JasmineError from 'forms/fieldsets/general/JasmineError'
import useVinLookup from 'forms/utils/useVinLookup'
import AutoIcon from 'utils/icons/Auto'
import { useTranslation } from 'react-i18next'

const VehicleLookup = () => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)
  const { loading, models, error, newLookup } = useVinLookup() 

  const lookUp = value => newLookup(value.toUpperCase())

  return (
    <fieldset>
      <Text
        name="vin"
        label={t("Enter your new vehicle's VIN, we'll look up the year, make and model")}
        Loading={loading}
        ChangeCallback={lookUp}
      />
      {!error && models.length > 1 && (
        <Select
          name="model"
          label={t("Vehicle Model")}
          choices={models.map(m => [m[1], m[1]])}
        />
      )}
      {error ?
        <JasmineError type="VIN" /> :
        data.year && data.make ? (
          <Tile className="VehicleToAdd" Icon={AutoIcon} IconName="AutoIcon">
            <div className="TileHeader">
              <small className="uppercase">{data.year} {data.make}</small>
              <p className="secondary-d2 font-p2">
                {data.model && data.model !== '-1'? data.model : t('Please select a model above')}
              </p>
            </div>
          </Tile>
        ) : null}
    </fieldset>
  )
}

export default VehicleLookup