import React, { useContext } from 'react'
import Tile from 'components/Tile'
import { AppContext } from 'utils/context'
import AutoIcon from 'utils/icons/Auto'
import PropertyIcon from 'utils/icons/Property'
import { AccountContext } from 'utils/context'
import { Trans } from 'react-i18next'
import './style.scss'

const Auto = ({ href, label, slug,  carrier, postal_code, email, phone, contact_uuid }) => {

  const { tenant } = useContext(AppContext)
  
  const isPC = slug === 'cis'
  const hubMonoline = slug === 'hub_bpo'
  const carrierLink = carrier ? `&carrier=${carrier}` : ''
  const postalCodeLink = postal_code ? `&postal_code=${postal_code}` : ''
  const emailLink = email ? `&email=${email}` : ''
  const phoneLink = phone ? `&phone=${phone}` : ''
  const uuid = contact_uuid ? `&quoteuuid=${contact_uuid}` : ''
  const link = isPC ? href : href + carrierLink + postalCodeLink + emailLink + phoneLink + uuid + `&lock_contact=1`

  return (
    <Tile Icon={AutoIcon} IconName="AutoIcon">
      <div className="TileHeader">
        {hubMonoline ? (
          <p className="zero-margin">
            <Trans
              i18nKey="noAutoPoliciesMessage"
              components={{
                // eslint-disable-next-line
                a1: <a className = "Link" href={tenant.contact_us_url}/>
              }} 
            />
          </p>
        ) : (
          <a href={link} target="_blank" rel="noopener noreferrer" className="primary-l1">
              <Trans i18nKey="intertestInOtherProducts" components={{ s: <small className="uppercase subtle" />, p: <p className="accent-d2 uppercase font-p2 zero-margin" /> }} />
          </a>
        )}
      </div>
      {hubMonoline ? null : (
        <div className="TileBody">
          <a href={link} rel="noopener noreferrer" target="_blank" className="Link QuoteLink">
            <Trans
              i18nKey="quoteOnline"
              values={{ websiteUrl: label }}
            />
          </a>
        </div>
      )}
    </Tile>
  )
}

const Hab = ({ href, label, slug, carrier, postal_code, email, phone, contact_uuid }) => {

  const { tenant } = useContext(AppContext)
  const isPC = slug === 'cis'
  const hubMonoline = slug === 'hub_bpo'
  const carrierLink = carrier ? `&carrier=${carrier}` : ''
  const postalCodeLink = postal_code ? `&postal_code=${postal_code}` : ''
  const emailLink = email ? `&email=${email}` : ''
  const phoneLink = phone ? `&phone=${phone}` : ''
  const uuid = contact_uuid ? `&quoteuuid=${contact_uuid}` : ''
  const link = isPC ? href : href + carrierLink + postalCodeLink + emailLink + phoneLink + uuid + `&lock_contact=1`

  return (
    <Tile Light Icon={PropertyIcon} IconName="PropertyIcon">
      <div className="TileHeader">
        {hubMonoline ? (
          <p className="zero-margin">
            <Trans
              i18nKey="noHabPoliciesMessage"
              components={{
                // eslint-disable-next-line
                a1: <a className = "Link" href={tenant.contact_us_url}/>
              }} 
            />
          </p>
        ) : (
        <a href = { link } target = "_blank" rel = "noopener noreferrer" className = "primary-l1">
          <Trans i18nKey="needPropertyQuote" components={{ sm: <small className="uppercase subtle" />, p: <p className="accent-d2 uppercase font-p2 zero-margin" /> }} />
        </a>
        )}
      </div>
      {hubMonoline ? null : (
        <div className="TileBody">
          <a href={link} rel="noopener noreferrer" target="_blank" className="Link QuoteLink">
            <Trans
                i18nKey="quoteOnline"
                values={{ websiteUrl: label }}
            />
          </a>
        </div>
      )}
    </Tile>
  )
}

const GetAQuote = () => {
  const { tenant } = useContext(AppContext)
  const { contact, drivers, vehicles, locations, driversLoading, vehiclesLoading, locationsLoading, policies } = useContext(AccountContext)

  let show = contact.data_status === 'good' || contact.data_status === 'good_missing'
  let loaded = show && !driversLoading && !vehiclesLoading && !locationsLoading
  let inProvince = policies.some(p => p.province === 'BC' || p.province === 'MB' || p.province === 'SK')
  let showAuto = loaded && drivers.length < 1 && vehicles.length < 1 && !inProvince
  let showHab = loaded && locations.length < 1

  let postalCode = contact.postal_code ? contact.postal_code.replace(' ', '') : null
  let carrier = policies[0] ? policies[0].carrier_code : null
  return (
    <>
      {showAuto &&
        <Auto
          href={tenant.auto_quote_url}
          label={tenant.brochure_label}
          slug={tenant.slug}
          carrier={carrier}
          postal_code={postalCode}
          email={contact.email}
          phone={contact.home_phone ? contact.home_phone : contact.mobile_phone}
          contact_uuid={contact.contact_uuid}
        />
      }
      {showHab &&
        <Hab
          href={tenant.hab_quote_url}
          label={tenant.brochure_label}
          slug={tenant.slug}
          carrier={carrier}
          postal_code={postalCode}
          email={contact.email}
          phone={contact.home_phone ? contact.home_phone : contact.mobile_phone}
          contact_uuid={contact.contact_uuid}
        />
      }
    </>
  )

}

export default GetAQuote