import React from 'react'
import Tile from 'components/Tile'
import Link from 'components/Buttons/Link'
import { useTranslation } from 'react-i18next'
import './style.scss'

const ConfirmCard = ({ title, children, slug, id }) => {
  const { t } = useTranslation()
  return (
    <Tile OffWhite className="ConfirmTile">
      <div className="TileHeader">
        {title} {slug && <Link to={`../${id}/${slug}`} className="float-right">{t('edit')}</Link>}
      </div>
      <div className="TileBody ConfirmBody">
        {children}
      </div>
    </Tile>
  )
}

export default ConfirmCard