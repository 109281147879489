import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { FormContext } from 'utils/context'
import { provinceChoices } from 'utils/vars'
import { useTranslation } from 'react-i18next'

const AddressChangeConfirm = () => {
  
  let { data } = useContext(FormContext)
  const { t } = useTranslation()
  let province = provinceChoices.find(ch => ch[0] === data['province']) || ''
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("New Address")} slug="address-lookup" id={data.id}>
        <address className="uppercase">
          {data['unit_number']} {data['street_number']} {data['street_name']}<br />
          {data['municipality']}, {province[1]}
        </address>
      </ConfirmCard>
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default AddressChangeConfirm