import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TenantComponent from 'components/TenantComponent'
import Home from './Home'
import Login from './Login'
import Logout from './Logout'
import Signup from './Signup'
import Registered from './Registered'
import Contact from './Contact'
import Help from './Help'
import Omnichannel from './Omnichannel'
import ForgotPassword from './ForgotPassword'
import ForgotSent from './ForgotPassword/ForgotSent'
import ForgotConfirm from './ForgotPassword/ForgotConfirm'
import ResetSuccess from './ForgotPassword/ResetSuccess'
import Verify from './Verify'
import ResendActivation from './ResendActivation'
import ActivationResent from './ActivationResent'
import FourOhFour from 'components/FourOhFour'
import TranslationsTest from './TranslationsTest'
import { isLocal, isStage } from 'utils/helpers'
import Disclosures from 'account/Disclosures'

const Site = ({ tenant }) => {
  return (
    <main id="main" className="Site">
      <Switch>
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/registered" component={Registered} />
        <Route path="/contact" component={Contact} />
        <Route path="/help" component={Help} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/check-email" component={ForgotSent} />
        <Route path="/reset-success" component={ResetSuccess} />
        <Route path="/resend-activation" component={ResendActivation} />
        <Route path="/activation-resent" component={ActivationResent} />
        <Route path="/password-reset/:uid/:token" component={ForgotConfirm} />
        <Route path="/verify/:token" component={Verify} />
        <Route path="/disclosures" component={Disclosures} />
        {
          tenant.show_formio_page && 
          <Route path="/letsconnect" render={(props) => <Omnichannel {...props} dynamic={false} />} />
        }
        {
          tenant.show_formio_page && 
          <Route path="/form" render={(props) => <Omnichannel {...props} dynamic={true} />} />
        }
        {(isLocal || isStage) && <Route path="/i18n" component={TranslationsTest} />}
        <Route exact path="/">
          <TenantComponent element={<Home />} elementName="Home" />
        </Route>
        <FourOhFour />
      </Switch>
    </main>
  )
}

export default Site