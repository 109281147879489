import MultiPageForm from 'forms/MultiPageForm'
import Requirements from 'forms/fieldsets/general/Requirements'
import VehicleReplaceQuoteGetStarted from 'forms/fieldsets/vehicle/VehicleReplaceQuoteGetStarted'
import QuoteVehicleLookup from 'forms/fieldsets/vehicle/QuoteVehicleLookup'
import QuoteOwnerDriver from 'forms/fieldsets/vehicle/QuoteOwnerDriver'
import VehicleUsage from 'forms/fieldsets/vehicle/VehicleUsage'
import QuoteEligibility from 'forms/fieldsets/vehicle/QuoteEligibility'
import CoverageNew from 'forms/fieldsets/vehicle/CoverageNew'
import VehicleAddQuoteConfirm from 'forms/fieldsets/vehicle/VehicleAddQuoteConfirm'
import ChangedDetails from 'forms/fieldsets/general/ChangedDetails'
import { useTranslation } from 'react-i18next'
const VehicleReplaceQuote = () => {
  const { t } = useTranslation()
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="vehicle-replace-quote" />
      <VehicleReplaceQuoteGetStarted
        path="get-started"
        getStartedPoliciesLabel={t('Which policy would you like this quote to apply to?')}
      />
      <QuoteVehicleLookup path="vehicle-lookup" />
      <QuoteOwnerDriver path="owner-and-driver" />
      <VehicleUsage path="vehicle-usage" />
      <QuoteEligibility path="eligibility" />
      <CoverageNew path="coverage" />
      <ChangedDetails path="policy-changes" 
        helpCopy={t('When you add a vehicle you may find that your use of your existing vehicles changes as well. Please confirm or update your vehicle usage below.')}
    />
      <VehicleAddQuoteConfirm path="confirm" />
    </MultiPageForm>
  )
}

export default VehicleReplaceQuote