import { useState, useContext, useEffect } from 'react'
import { dedupe } from 'forms/utils/helpers'
import { FormContext } from 'utils/context'
import api from 'utils/requests'

const useVehicleLookup = clearParentFormError => {
  
  const { data, setFieldValue } = useContext(FormContext)

  const [viccYears, setViccYears] = useState([])
  const [viccMakes, setViccMakes] = useState([])
  const [viccModels, setViccModels] = useState([])

  const [viccMakesLoading, setViccMakesLoading] = useState(false)
  const [viccModelsLoading, setViccModelsLoading] = useState(false)

  const lookupViccMakes = val => {
    clearParentFormError()
    setFieldValue('make', -1)
    setFieldValue('model', -1)
    setViccMakesLoading(true)
    api.get(`/jasmine/vicc/year/${val}/make/`, { baseURL: '' })
      .then(res => {
        const makes = res.data.map(m =>
          [m.value, m.label]
        )
        setViccMakes(makes)
        setViccMakesLoading(false)
      })
  }

  const lookupViccModels = val => {
    clearParentFormError()
    setFieldValue('model', -1)
    setViccModelsLoading(true)
    api.get(`/jasmine/vicc/year/${data['year']}/make/${val}/model/`, { baseURL: '' })
      .then(res => {
        const deduped = dedupe(res.data)
        const models = deduped.map(m => [m.value, m.label])
        setViccModels(models)
        setViccModelsLoading(false)
      })
  }

  useEffect(() => {
    const fetchYears = () => {
      api.get(`/jasmine/vicc/year/`, { baseURL: '' })
        .then(res => {
          const years = res.data.map(y =>
            [y.value, y.value]
          )
          setViccYears(years)
        })
    }
    fetchYears()
  }, [])

  return {
    viccYears,
    viccMakes,
    viccModels,
    lookupViccMakes,
    lookupViccModels,
    viccMakesLoading,
    viccModelsLoading,
  }
}

export default useVehicleLookup