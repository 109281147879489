import './style.scss'
import { useTranslation } from 'react-i18next'
const LoadErrorModal = () => {
  const { t } = useTranslation()
  return (
    <div className='loadError'>
      <h1>{t('Something went wrong')}</h1>
      <p>{t('Please try again, or contact us if you continue to have problems.')}</p>
    </div>
  )
}

export default LoadErrorModal
